
import Logo from '../images/FidelitusTransaction.png'
import {IoMdCall} from 'react-icons/io'

import {BiLogoFacebook,BiLogoLinkedin} from 'react-icons/bi'
import {AiFillInstagram,AiFillYoutube,AiOutlineMail} from 'react-icons/ai'
import {IoLogoWhatsapp} from 'react-icons/io'
import {IoLocationOutline} from 'react-icons/io5'

import { address, email, fidelitus_gallery_website_url, fms_website_url, hrlabs_website_url, mobile, project_website_url } from '../constants/Appconstants'
import { useNavigate } from 'react-router-dom'


export default function Footer() {
  
  const navigate = useNavigate() 

 
  const social_media = [
    {'icon':BiLogoFacebook,name:'Facebook',id:1,path:'https://www.facebook.com/Fidelitus/'},
    {'icon':AiFillInstagram,name:'Instagram',id:2,path:'https://www.instagram.com/fidelitus.corp/'},
    {'icon':BiLogoLinkedin,name:'LinkedIn',id:3,path:'https://in.linkedin.com/company/fidelituscorp-property-services-private-ltd'},
    {'icon':AiFillYoutube,name:'YouTube',id:4,path:'https://youtu.be/y-3CAl8M9yA'},
    {'icon':IoLogoWhatsapp,name:'WhatsApp',id:5,path:'https://api.whatsapp.com/send?phone=919845062227&text=Hi%20I%20would%20like%20to%20connect%20with%20you'},
  ]

  const menu = [
    {name:'Home',path:'/',id:1},
    {name:'About ',path:'/about',id:2},
    {name:'Why us',path:'/why-us',id:7},
    // {name:'Our Services',path:'/services',id:3},
    {name:'Contact Us',path:'/contact',id:4},
    {name:'Our Blog',path:'/blogs',id:5},
    {name:'Careers',path:'/join_us',id:6},
  ]




  const other_services = [
    {
      id:1,
      name:'Projects',
      path:project_website_url,
      // path:'/whats-more/projects',
      new:true
  },
  {
      id:2,
      name:'HR Labs',
      path:hrlabs_website_url,
      // path:'/whats-more/hr-labs',
      new:true
  },
  {
      id:3,
      name:'Facility Management Services',
      path:fms_website_url,
      // path:'/whats-more/facility-management-services',
      new:true
  },
  {
      id:4,
      name:'Fidelitus Gallery',
      path:fidelitus_gallery_website_url,
      // path:'/whats-more/fidelitus-gallery',
      new:true
  },
  ]

  async function openPage1(v){
    window.open(v?.path,'_blank')
  }

  return (
    <>
    <div className="min-w-screen bg-[#1a1f25] py-10  w-full text-[#fff]">
       <div className='grid w-[85vw]  mx-auto   md:grid-cols-5'>


        <div className='md:border-r pr-4 border-slate-800'>
        <img src={Logo} alt="FidelitusHrLab"  className='w-[150px] py-[2px] object-stretch cursor-pointer' />

        <h6 className='text-[12px] 2xl:text-[15px] font_normal mt-2'>Fidelitus Real Estate Transaction Services</h6>
        <h6 className='text-[12px] 2xl:text-[15px] font_normal font-[500]'>Powered By  <span className='text-[#e67816] font-[600]'>Fidelitus</span> </h6>


        <div>
           <h6 className='text-[12px] font-[600]-500 mt-4'>HEAD OFFICE</h6>
           <div className='flex md:-ml-7 mt-2'>
            <IoLocationOutline color='#4190ac' size={50} />
            <h6 className='text-[12px] 2xl:text-[15px] font_normal opacity-70 cursor-pointer pl-3'>{address}</h6>
           </div>

           <div className='flex md:-ml-7 mt-2'>
           <IoMdCall color='#4190ac' size={15} />
            <h6 className='text-[12px] 2xl:text-[15px] font_normal opacity-70 cursor-pointer  pl-2'>{mobile}</h6>
            </div>

            <div className='flex md:-ml-7 mt-2.5'>
           <AiOutlineMail color='#4190ac' size={15}/>
            <h6 className='ml-1 text-[12px] 2xl:text-[15px] font_normal opacity-70 cursor-pointer  pl-2'>{email}</h6>
            </div>
        </div>

        </div>

        <div className='md:border-r mt-4 md:mt-0  md:px-4  border-slate-800'> 
            <h6 className='uppercase text-[12px] 2xl:text-[15px] font-[600] mb-2'>Quick Links</h6>
                {menu?.map((m)=>(
                  <h6 onClick={()=>navigate(m?.path)} key={m?.path} className='text-[12px] 2xl:text-[15px] font_normal py-1 opacity-70 hover:opacity-100  cursor-pointer'>{m?.name}</h6>
                ))}
                {/* <h6 className='text-[12px]  py-1  mt-2'>Download Our Fidelitus Real Estate Profile by clicking  <Link href={'https://fidecrmfiles.s3.amazonaws.com/FMS+PROFILE+29-7-2022.pdf'} target='_blank'><span className='text-[#000] font-[6  00] cursor-pointer hover:underline'> Download</span></Link>  Link.</h6> */}

        </div>

        <div className='md:border-r mt-4 md:mt-0  md:px-4 border-slate-800'> 
            <h6 className='uppercase text-[12px] 2xl:text-[15px] font-[600]'>Other Business</h6>
            <h6 className='text-[12px] 2xl:text-[15px] font_normal font-[300] my-2 leading-tight'>Rather than Transaction Services We also provides services on more domain..</h6>
           {other_services?.map((s,i)=>(
            <h6 onClick={()=>openPage1(s)} key={i} className='text-[12px] 2xl:text-[15px] font_normal py-1 opacity-70 hover:opacity-100 cursor-pointer'> * {s?.name}</h6>
           ))}

        </div>

        <div className='mt-4 md:mt-0  md:px-4 md:border-r border-slate-800'> 
            <h6 className='uppercase text-[12px] 2xl:text-[15px] font-[600] mb-1.5'>Follow Us</h6>
            {social_media?.map((s,i)=>(
            <a key={i} href={s?.path} target='_blank'>
            <h6  className='text-[12px] 2xl:text-[15px] font_normal py-1 opacity-70 hover:opacity-100 cursor-pointer'>{s?.name}</h6>
            </a>   
           ))}
        </div>


        <div className='mt-4 md:mt-0  md:px-4  border-gray-200'> 
            <h6 className='uppercase text-[12px] 2xl:text-[15px] font-[600]'>MORE LINKS</h6>
            {/* <h6 className='text-[11px] font_normal font-[300] my-2 leading-tight'>If your&apos;e found of art and craft please visit our gallery..</h6>

           <a href={'https://fidelitusgallery.com/'} target='_blank'>
            <h6 className='text-[12px] font_normal py-1 opacity-70 cursor-pointer hover:opacity-100'>{'Fidelitus Gallery'}</h6>
            </a> */}
            <h6 className='text-[11px] 2xl:text-[14px] font_normal font-[300] my-2 leading-tight'>If you're interested in bringing light to the future of students and the elderly, consider donating to the Shilpa Foundation. Every rupee supports their welfare.</h6>
            
            <a href={'https://shilpafoundation.org/'} target='_blank'>
            <h6 className='text-[12px] 2xl:text-[15px] font_normal py-1 opacity-70  cursor-pointer hover:opacity-100'>{'Shilpa Foundation'}</h6>
            </a>

        </div>



       </div>
    </div>
    <p className='text-[9px] 2xl:text-[12px] font_normal bg-[#242b32] text-center w-full py-2 text-gray-300'>© 2023 Fidelitus Real Estate Transaction Services. All Rights Reserved</p>
    </>
  )
}
