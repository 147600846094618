
import React,{ useEffect, useState } from 'react'
import Logo from '../images/FidelitusTransaction.png'
import {BiLogoFacebook,BiLogoLinkedin} from 'react-icons/bi'
import {AiFillInstagram,AiFillYoutube} from 'react-icons/ai'
import {IoMdTime,IoMdCall} from 'react-icons/io'
import {IoLogoWhatsapp} from 'react-icons/io'
import {IoCloseOutline,IoLocationOutline} from 'react-icons/io5'
import {TbMenu} from 'react-icons/tb'
import {AiOutlineStepForward} from 'react-icons/ai';
import { Drawer, Modal } from 'antd'
import { base_url, department, fidelitus_gallery_website_url, fms_website_url, hrlabs_website_url, mobile, project_website_url } from '../constants/Appconstants';
import {RiDoorOpenLine} from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom'
import { FaAngleDown } from "react-icons/fa";
import axios from 'axios'
import {message} from 'antd'
import ErrorComponent from '../constants/ErrorComponent'
import ScrollToTop from '../../pages/ScrollToTop'


export default function Navbar() {

  
  const {pathname} = useLocation();
  const navigate = useNavigate()
  const path = pathname

  const [visible,setvisible] = useState(false)
  const [modal,setmodal] = useState(false)
  const [step,setstep] = useState('')
  const [openDropdown, setOpenDropdown] = useState(null);

  const [data,setdata] = useState({name:'',mobile:'',email:'',requirment:new Date().toISOString().slice(0,10),department_website:''})
  const [error,seterror] = useState({name:'',mobile:'',email:'',requirment:'',department_website:''})



  useEffect(()=>{
   if(path === '/'){
    // router.push('/home')
   }
  },[path])
  

  const social_media = [
    {'icon':BiLogoFacebook,name:'Facebook',id:1,path:'https://fb.watch/s8mAKjofBY/ '},
    {'icon':AiFillInstagram,name:'Instagram',id:2,path:'https://www.instagram.com/fidelitus.corp/'},
    {'icon':BiLogoLinkedin,name:'LinkedIn',id:3,path:'https://www.linkedin.com/feed/update/urn:li:activity:7197574604696031233'},
    {'icon':AiFillYoutube,name:'YouTube',id:4,path:'https://youtu.be/lPSgcuw-fbI'},
    {'icon':IoLogoWhatsapp,name:'WhatsApp',id:5,path:'https://api.whatsapp.com/send?phone=919845062227&text=Hi%20I%20would%20like%20to%20connect%20with%20you'},
  ]

  const menu = [
    {name:'Home',path:'/',id:1,menu:[]},
    {name:'About ',path:'/about',id:2,menu:[]},
    // {name:'Why us',path:'/why-us',id:3,menu:[]},
    {name:'Services',path:'na',id:4,menu:[
      {
        id:1,
        name:'Commercial Leasing & Sales',
        path:'/services/commercial_leasing_and_sales'
      },
      {
        id:2,
        name:'Investment Advisory',
        path:'/services/investment_advisory'
      },
      {
        id:3,
        name:'Transaction Management',
        path:'/services/transaction_management'
      },
      {
        id:4,
        name:'Tenant Representation',
        path:'/services/tenant_representation'
      },
      {
        id:5,
        name:'Land Lease & Built-To-Suit Sales',
        path:'/services/land_lease_and_built_to_suit_sales'
      },
      {
        id:6,
        name:'Property Valuation & Advisory',
        path:'/services/property_valuation_and_advisory'
      },
      {
        id:7,
        name:'Corporate Real Estate Solutions',
        path:'/services/corperate_real_estate_solutions'
      }
    ]},
    {name:'Exclusive Project',path:'/exclusive-projects',id:9,menu:[]},
    {
        id:5,
        icon:'',
        name:"Fidelitus Group",
        path:'na',
        menu:[
            {
                id:1,
                name:'Projects',
                path:project_website_url,
                new:true
                // path:'/whats-more/projects'
            },
            {
                id:2,
                name:'HR Labs',
                path:hrlabs_website_url,
                new:true
                // path:'/whats-more/hr-labs'
            },
            {
                id:3,
                name:'Facility Management Services',
                path:fms_website_url,
                new:true
                // path:'/whats-more/facility-management-services'
            },
            {
                id:4,
                name:'Fidelitus Gallery',
                path:fidelitus_gallery_website_url,
                new:true
                // path:'/whats-more/fidelitus-gallery'
            },
        ]
    },
    {name:'Contact',path:'/contact',id:6,menu:[]},
    {name:'Blogs',path:'/blogs',id:7,menu:[]},
    {name:'Careers',path:'/join_us',id:8,menu:[]},
  ]

  async function openPage(v){
    navigate(v)
    setvisible(false)
  }

  async function openPage1(v){
    if(v?.new === true){
      window.open(v?.path,'_blank')
      setvisible(false)
    }else{
      navigate(v?.path)
      setvisible(false)
    }
  }

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  } 

  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.requirment){
      seterror({...error,requirment:'This Field is required *'})
    }else{
     
      const sendData = {...data}
      sendData['department_website'] = department
      sendData['requirment'] = `Date of Visit - ${data.requirment}`
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          setmodal(false)
          message.success('Your Visit Created Successfully')
          setdata({name:'',mobile:'',email:'',requirment:'',department_website:''})
          seterror({name:'',mobile:'',email:'',requirment:'',department_website:''})
        }
      }).catch((err)=>{      
      }) 
    }
  }

  // fixed  shadow-sm 
  return (
    <div className='focus:outline-none focus:ring-0 z-100'>
    <ScrollToTop />
    <Modal open={modal} closable={false} footer={false} width={350}>

      <div>
         <div>
           <h6 className='font-bold text-[16px]'>Like To Meet Us</h6>
           <h6 className='text-[13px] leading-[17px]'>We are always happy to assist you with any queries. Simply schedule a visit, and our experts will be ready to help! </h6>
         </div>


          <div className='mr-5 md:mt-2 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Name</h6>
            <input type='text' name="name" value={data?.name} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.name} />

          <div className='mr-5 md:mt-2 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Mobile</h6>
            <input type='text' name="mobile" value={data?.mobile} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.mobile} />

          <div className='mr-5 md:mt-2 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Email</h6>
            <input type='text' name="email" value={data?.email} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.email} />


          <div className='mt-2 md:mt-2 mr-5 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Date of Visit</h6>
            <input min={new Date().toISOString().slice(0,10)} type='date' name="requirment" value={data?.requirment} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.requirment} />



         <div className='flex items-center border-t pt-2 mt-4'>
           <h6 onClick={()=>setmodal(!modal)} className='text-[12.5px] cursor-pointer mr-2 font-bold border px-2 rounded py-1 text-center w-max'>Close</h6>
           <h6 onClick={submitForm} className='text-[12.5px] cursor-pointer font-bold border px-2 rounded py-1 text-center bg-[#027d9b] text-white w-max text-white'>Submit</h6>
         </div>

      </div>
      
    </Modal>
    <Drawer className='focus:outline-none focus:ring-0' open={visible} closable={false} mask={true} onClose={()=>setvisible(!visible)} maskClosable={true} width={280}>
      <div>
          <div className='flex justify-between items-center pb-2 border-b mb-5 border-slate-100'>
          <img onClick={()=>navigate('/')} src={Logo} alt="FidelitusHrLab" className='block w-[150px] h-[50px] py-[2px] py-[2px] object-stretch cursor-pointer' />
          <IoCloseOutline size={25} onClick={()=>setvisible(!visible)} />
          </div>
          
          {menu?.map((m,i)=>(
          <div>  
          <div key={i} onClick={()=>m?.path === 'na' ?  i == step ? setstep('') : setstep(i) : openPage(m?.path)} className='border-b my-3 mb-1 pb-1  flex items-center justify-between'>
              <h6 className={` text-[13px] ${m?.path === path ? 'text-[#4190ac]' : 'text-slate-900'} font_normal font-[500] `}>{m?.name}</h6>
              <AiOutlineStepForward color="#4190ac" />
          </div>
          {m?.menu?.length > 0 && step === i && 
          <div className='ml-1'>
            {m?.menu?.map((m1)=>(
              <h6 onClick={()=>openPage1(m1)} className='cursor-pointer py-1.5 border-b border-slate-100 duration-300 text-gray-500 hover:text-[#027d9b] font-[600] text-[13px] font_bold'>{m1?.name}</h6>
            ))}
          </div>}   
          </div>
          ))}
      </div>

    </Drawer>
 
    <div className="min-w-screen w-full   ">
      <div className="min-w-screen border-b  border-slate-100 w-full   ">
      <div className="min-w-screen border-b  border-slate-100 w-full    bg-gray-50">
        <div className='flex items-center w-[85vw] mx-auto justify-end md:justify-between bg-gray-50 h-[34px]'>
        <div className='hidden md:flex h-full items-center justify-between'>
          <a className='h-full' href={`https://www.google.com/maps?ll=12.92055,77.572551&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=5697749849754175908`} target='_blank'>
          <div className='flex h-full items-center px-2 border-l border-r'>
            <IoLocationOutline color='#4190ac' />
            <h6 className='ml-1 text-[10px] 2xl:text-[13px] font-[500] cursor-pointer text-slate-700'>Brigade Software Park, Banshankri</h6>
          </div>
          </a>

          <div className='flex h-full items-center px-2 border-r'>
            <IoMdTime color='#4190ac' />
            <h6 className='ml-1 text-[10px] 2xl:text-[13px] font-[500] cursor-pointer text-slate-700'>Mon - Sat 09:00 AM - 06:00 PM</h6>
          </div>

         <a className='h-full' href={`tel:${mobile}`} target='_blank'>
          <div className='flex h-full items-center px-2 border-r'>
            <IoMdCall color='#4190ac' />
            <h6 className='ml-1 text-[10px] 2xl:text-[13px] font-[500] cursor-pointer text-slate-700'>{mobile}</h6>
          </div>
          </a>
        </div>
        <div className='flex h-full items-center'>
            {social_media?.map((item,index)=>(
              <a key={item?.id}  className='h-full' href={item?.path} target='_blank'>
               <h6 className={`px-2 flex items-center h-full hover:bg-[#4190ac] hover:text-white border-r text-slate-400 ${index == 0 && 'border-l'} border-slate-100`}>
               <item.icon  className='cursor-pointer'  />
              </h6>
              </a>
            ))} 
        </div>
        </div>
      </div>
        <div className='hidden md:flex relative items-center w-[85vw] mx-auto justify-between md:justify-between h-[50px]'>
        <img onClick={()=>navigate('/')} src={Logo} alt="FidelitusHrLab" className='hidden md:block w-[145px] h-[49px] py-[2px] object-stretch cursor-pointer' />

        <div className='-ml-28 w-max hidden md:flex h-full items-center justify-between'>

        {menu?.map((m,i)=>(
           <div onMouseLeave={()=>setOpenDropdown(null)} onMouseOver={()=>m?.menu?.length > 0  ? setOpenDropdown(i) : setOpenDropdown(null)} className='group relative z-20'>
             <h6 onClick={()=>m?.path !== 'na' && navigate(m?.path)} key={m?.id} className={`peer font_bold text-[12px] py-[10px] font-[500] text-gray-800 cursor-pointer px-4  after:bg-[#4190ac] after:absolute after:h-[3px] after:w-0 after:bottom-[-1px] after:left-0  hover:after:w-full after:transition-all after:duration-300 flex items-center ${m?.path === path ? 'border-b-[3px] border-[#4190ac]' : ''} `}>{m?.name} {m?.menu?.length > 0 && <FaAngleDown className='ml-[5px]'/>}</h6>
             {m?.menu?.length > 0 &&
              <div className={`${openDropdown === (i) ? 'block' : 'hidden'} absolute top-[38px] ml-[10px] w-[230px] border bg-white`}>
                  {m?.menu?.map((m1)=>(
                  <h6 onClick={()=>{openPage1(m1);}} key={m?.id} className={`capitalize hover:text-[#027d9b] border-b border-slate-100 text-[12px] 2xl:text-[15px] py-2 font-[500] text-gray-800 cursor-pointer px-4 relative  font_bold `}>{m1?.name}</h6>
                  ))}
              </div>
              }
           </div>
          ))}




        </div>
        <h6 onClick={()=>setmodal(!modal)} className='hidden flex md:block cursor-pointer text-[12px] py-2 text-white uppercase px-2 absolute right-0 bg-[#4190ac]'><span className='flex items-center'><RiDoorOpenLine size={20} className='mr-1' /> --  Schedule A Visit</span></h6>

        
        <div className='flex h-full items-center'>
            
        </div>
        </div>
      </div>
     
      <div className='w-[85vw] mx-auto flex  items-center justify-between md:hidden py-2'>
      <img onClick={()=>navigate('/')} src={Logo} alt="FidelitusHrLab" className='w-[150px] h-[50px] py-[2px] -ml-4 object-cover cursor-pointer' />
      <TbMenu onClick={()=>setvisible(!visible)} className='text-slate-700' />
      </div>
     </div>
    </div>
  )
}
