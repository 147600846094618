import app_logo  from '../images/FidelitusTransaction.png'
import gallery_logo1 from '../images/gallery_logo.png'
import support_us from '../images/support_us.png'
import no_data_logo from '../images/no_data.png'


// service icon
import s1ico from '../images/service_icon/s1ico.png'
import s2ico from '../images/service_icon/s2ico.png'
import s3ico from '../images/service_icon/s3ico.png'
import s4ico from '../images/service_icon/s4ico.png'

// banners 
import banner1 from '../images/banner/b1.jpeg'
import banner2 from '../images/banner/b2.jpg'
import banner3 from '../images/banner/b3.jpg'
import banner4 from '../images/banner/b4.jpg'
import banner5 from '../images/banner/commercial_real_estate_bg.jpeg'
import banner6 from '../images/banner/resedential_bg.jpg'
import banner7 from '../images/banner/land_investment_bg.jpg'
import banner8 from '../images/banner/warehouse_bg.jpg'

// other business service icon 
import speedyProcess from '../images/other_business_step/SpeedyProcess.png'
import dedicatedExpert from '../images/other_business_step/Dedicatedexpert.png'
import dynamicDatabase from '../images/other_business_step/Dynamicdatabase.png'
import clearCommunication from '../images/other_business_step/ClearCommunication.png'
import resumeDatabase from '../images/other_business_step/80k.png'
import emergingIndividual from '../images/other_business_step/EmergingIndividuals.png'
import fastestGrowing from '../images/other_business_step/FastestGrowing.png'
import serviceOffered from '../images/other_business_step/ServicesOfferred.png'

// blog icon 
import blog1 from '../images/blogs_bg/blog1.jpeg'
import blog2 from '../images/blogs_bg/blog2.jpeg'
import blog3 from '../images/blogs_bg/blog3.jpeg'
import blog4 from '../images/blogs_bg/blog4.png'
import blog5 from '../images/blogs_bg/blog5.png'
import blog6 from '../images/blogs_bg/blog6.jpeg'
import blog7 from '../images/blogs_bg/blog7.png'
import blog8 from '../images/blogs_bg/blog8.jpeg'
import blog9 from '../images/blogs_bg/blog9.jpeg'
import blog10 from '../images/blogs_bg/blog10.jpeg'
import blog11 from '../images/blogs_bg/blog11.png'
import blog12 from '../images/blogs_bg/blog12.jpeg'
import blog13 from '../images/blogs_bg/blog13.jpeg'
import blog14 from '../images/blogs_bg/blog14.png'
import blog15 from '../images/blogs_bg/blog15.jpeg'
import blog16 from '../images/blogs_bg/blog16.jpeg'
import blog17 from '../images/blogs_bg/blog17.jpeg'
import blog18 from '../images/blogs_bg/blog18.jpeg'
import blog19 from '../images/blogs_bg/blog19.jpeg'
import blog20 from '../images/blogs_bg/blog20.jpeg'
import blog21 from '../images/blogs_bg/blog21.jpeg'
import blog22 from '../images/blogs_bg/blog22.jpg'
import blog23 from '../images/blogs_bg/blog23.jpeg'
import blog24 from '../images/blogs_bg/blog24.jpeg'
import blog24_1 from '../images/blogs_bg/blog24_1.jpeg'
import blog24_2 from '../images/blogs_bg/blog24_2.jpeg'
import blog24_3 from '../images/blogs_bg/blog24_3.jpeg'
import blog24_4 from '../images/blogs_bg/blog24_4.jpeg'
import blog24_5 from '../images/blogs_bg/blog24_5.jpeg'
import blog25 from '../images/blogs_bg/blog25.jpeg'


// clients 
import Image1 from '../images/clients/1.png'
import Image2 from '../images/clients/2.png'
import Image3 from '../images/clients/3.png'
import Image4 from '../images/clients/4.png'
import Image5 from '../images/clients/5.png'
import Image6 from '../images/clients/6.png'
import Image7 from '../images/clients/7.png'
import Image8 from '../images/clients/8.png'
import Image9 from '../images/clients/9.png'
import Image10 from '../images/clients/10.png'
import Image11 from '../images/clients/11.png'
import Image12 from '../images/clients/12.png'
import Image13 from '../images/clients/13.png'
import Image14 from '../images/clients/14.png'
import Image15 from '../images/clients/15.png'
import Image16 from '../images/clients/16.png'
import Image17 from '../images/clients/17.png'
import Image18 from '../images/clients/18.png'
import Image19 from '../images/clients/19.png'
import Image20 from '../images/clients/20.png'
import Image21 from '../images/clients/21.png'
import Image22 from '../images/clients/22.png'
import Image23 from '../images/clients/23.png'
import Image24 from '../images/clients/24.png'
import Image25 from '../images/clients/25.png'
import Image26 from '../images/clients/26.png'
import Image27 from '../images/clients/27.png'
import Image28 from '../images/clients/28.png'
import Image29 from '../images/clients/29.png'
import Image30 from '../images/clients/30.png'
import Image31 from '../images/clients/31.png'
import Image32 from '../images/clients/32.png'
import Image33 from '../images/clients/33.png'
import Image34 from '../images/clients/34.png'
import Image35 from '../images/clients/35.png'
import Image36 from '../images/clients/36.png'
import Image37 from '../images/clients/37.png'
import Image38 from '../images/clients/38.png'
import Image39 from '../images/clients/39.png'
import Image40 from '../images/clients/40.png'
import Image41 from '../images/clients/41.png'
import Image42 from '../images/clients/42.png'
import Image43 from '../images/clients/43.png'
import Image44 from '../images/clients/44.png'
import Image45 from '../images/clients/45.png'
import Image46 from '../images/clients/46.png'
import Image47 from '../images/clients/47.png'
import Image48 from '../images/clients/48.png'
import Image49 from '../images/clients/49.png'
import Image50 from '../images/clients/50.png'
import Image51 from '../images/clients/51.png'
import Image52 from '../images/clients/52.png'
import Image53 from '../images/clients/53.png'
import Image54 from '../images/clients/54.png'
import Image55 from '../images/clients/55.png'
import Image56 from '../images/clients/56.png'
import Image57 from '../images/clients/57.png'
import Image58 from '../images/clients/58.png'
import Image59 from '../images/clients/59.png'
import Image60 from '../images/clients/60.png'
import Image61 from '../images/clients/61.png'
import Image62 from '../images/clients/62.png'
import Image63 from '../images/clients/63.png'
import Image64 from '../images/clients/64.png'
import Image65 from '../images/clients/65.png'
import Image66 from '../images/clients/66.png'
import Image67 from '../images/clients/67.png'
import Image68 from '../images/clients/68.png'
import Image69 from '../images/clients/69.png'
import Image70 from '../images/clients/70.png'
import Image71 from '../images/clients/71.png'
import Image72 from '../images/clients/72.png'
import Image73 from '../images/clients/73.png'
import Image74 from '../images/clients/74.png'
import Image75 from '../images/clients/75.png'
import Image76 from '../images/clients/76.png'
import Image77 from '../images/clients/77.png'
import Image78 from '../images/clients/78.png'
import Image79 from '../images/clients/79.png'
import Image80 from '../images/clients/80.png'
import Image81 from '../images/clients/81.png'
import Image82 from '../images/clients/82.png'
import Image83 from '../images/clients/83.png'
import Image84 from '../images/clients/84.png'
import Image85 from '../images/clients/85.png'
import Image86 from '../images/clients/86.png'
import Image87 from '../images/clients/87.png'
import Image88 from '../images/clients/88.png'
import Image89 from '../images/clients/89.png'
import Image90 from '../images/clients/90.png'
import Image91 from '../images/clients/91.png'
import Image92 from '../images/clients/92.png'
import Image93 from '../images/clients/93.png'
import Image94 from '../images/clients/94.png'
import Image95 from '../images/clients/95.png'
import Image96 from '../images/clients/96.png'
import Image97 from '../images/clients/97.png'
import Image98 from '../images/clients/98.png'
import Image99 from '../images/clients/99.png'
import Image100 from '../images/clients/100.png'
import Image101 from '../images/clients/101.png'
import Image102 from '../images/clients/102.png'
import Image103 from '../images/clients/103.png'
import Image104 from '../images/clients/104.png'
import Image105 from '../images/clients/105.png'
import Image106 from '../images/clients/106.png'
import Image107 from '../images/clients/107.png'
import Image108 from '../images/clients/108.png'
import Image109 from '../images/clients/109.png'
import Image110 from '../images/clients/110.png'
import Image111 from '../images/clients/111.png'
import Image112 from '../images/clients/112.png'
import Image113 from '../images/clients/113.png'
import Image114 from '../images/clients/114.png'
import Image115 from '../images/clients/115.png'
import Image116 from '../images/clients/116.png'
import Image117 from '../images/clients/117.png'
import Image118 from '../images/clients/118.png'
import Image119 from '../images/clients/119.png'
import Image120 from '../images/clients/120.png'

// service section 1 
import ser_sect_11 from '../services_images/service1/tenant_representation.jpg' 
import ser_sect_12 from '../services_images/service1/landlord_representation.jpg' 
import ser_sect_13 from '../services_images/service1/commercial_lease.jpeg' 
import ser_sect_14 from '../services_images/service1/property_renewal.avif' 
import ser_sect_15 from '../services_images/service1/exclusive_marketing.jpg' 
import ser_sect_16 from '../services_images/service1/buit_to_suit.jpg' 


// service section 2 
import ser_sect_21 from '../services_images/service2/land_transaction.jpeg' 
import ser_sect_22 from '../services_images/service2/development_and_disposal.webp' 
import ser_sect_23 from '../services_images/service2/joint_venture.jpg' 
import ser_sect_24 from '../services_images/service2/land_info.webp' 
import ser_sect_25 from '../services_images/service2/commerical_property_portofolio.jpg' 
import ser_sect_26 from '../services_images/service2/pre_leased.jpeg' 


// service section 3 
import ser_sect_31 from '../services_images/service3/industrial_warehouse_lease_and_rent.jpg' 
import ser_sect_32 from '../services_images/service3/built_to_suit_development.jpg' 
import ser_sect_33 from '../services_images/service3/industrial_area.webp' 
import ser_sect_34 from '../services_images/service3/structuring_land_acquisation.jpeg' 
import ser_sect_35 from '../services_images/service3/logistic_space.jpg' 


// service section 4 
import ser_sect_41 from '../services_images/service4/priamary_secondary_sale.jpeg' 
import ser_sect_42 from '../services_images/service4/investment_sales.jpg' 
import ser_sect_43 from '../services_images/service4/pre-launch_sale.webp' 
import ser_sect_44 from '../services_images/service4/guarenteed_rental_sale.jpg' 
import ser_sect_45 from '../services_images/service4/promotional_marketing.jpg' 



// services icons 
import accurate_valuation from "../services_images/accurate_valuation.jpg"
import compliance_management from "../services_images/compliance_management.jpg"
import due_deliguence from "../services_images/due_deliguence.jpg"
import end_to_end_support from "../services_images/end_to_end_support.jpg"
import expert_negotiation from "../services_images/expert_negotiation.jpg"
import flex_space_valuation from "../services_images/flex_space_valuation.jpg"
import industrial_spaces from "../services_images/industrial_spaces.jpg"
import lease_negotiate from "../services_images/lease_negotiate.jpg"
import managed_space_advisory from "../services_images/managed_space_advisory.jpg"
import market_research from "../services_images/market_research.jpg"
import office_spaces from "../services_images/office_spaces.jpg"
import portofolio_optimization from "../services_images/portofolio_optimization.jpg"
import retail_spaces from "../services_images/retail_spaces.jpg"
import space_optimization from "../services_images/space_optimization.jpg"
import strategic_property_investment from "../services_images/strategic_property_investment.jpg"
import warehouse_space from "../services_images/warehouse_space.jpg"
import work_place_strategy from "../services_images/work_place_strategy.jpg"


// blog written by 
import achuth_gowda from '../images/teams/achuth_gowda3.png'
import achuth from '../images/teams/achuth_gowda2.png'
import subhas_rao from '../images/teams/Subash.jpeg'


// exclusive projects 
import villa_plots_banner from '../images/exclusive/villa_plots/banner1.jpg'
import kamadhenu_banner from '../images/exclusive/kamadhenu/banner1.jpg'
import tnr_drive_tech_park_banner from '../images/exclusive/tnr_devine/TNR-DIVINE.jpg'
import primae_land_option_for_sale_banner from '../images/exclusive/prime_land/Prime-Land-Fidelitus-corp-2.jpeg'
import industrial_banner from '../images/exclusive/industrial_land/industrial_banner.png'
import trifectaadatto from '../images/exclusive/trifecta_adatto/Trifecta-Office-Space-for-Rent-1.jpeg'
import land_for_sale from '../images/exclusive/land_for_sale/WhatsApp-Image-2022-02-10-at-10.00.01-AM-1.jpeg'
import built_to_suit from '../images/exclusive/built_to_suit/WhatsApp-Image-2021-05-28-at-11.28.55-AM-1.jpeg'
import primeco_tower from '../images/exclusive/primeco_towers/500001300568_18124.jpg'
import ajmera_nucleus from '../images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE-3.png'
import ozone_chambers from '../images/exclusive/ozone_chambers/OZONE-CHAMBERS.png'
import qube_software_park from '../images/exclusive/cube_software/QUBE-SOFTWARE-PARK-1.png'
import banglore_life_science_park from '../images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-1-1.jpeg'
import primeco_union_city from '../images/exclusive/primeco_union/Picture26.jpg'
import shilpa_ananaya from '../images/exclusive/shilpa_ananaya/Shilpa-Ananya-1.jpeg'
import primeco_land_option from '../images/exclusive/prime_land_option/Prime-Land-FCPL-1.jpg'
import nmr_green_nest from '../images/exclusive/nmr_tech_park/2.jpg'
import gurukrupa from '../images/exclusive/gurukrupa/Gurukrupa-Building.png'

// succeedd 
import end from '../images/succeed/end.png'
import map from '../images/succeed/map.png'
import shortlist from '../images/succeed/shortlist.png'
import understand from '../images/succeed/understand.png'
import tasklist from '../images/succeed/task-list.png'
import readystock from '../images/succeed/ready-stock.png'
import contractnegotiation from '../images/succeed/contract-negotiation.png'

export const supportUs = support_us
export const appLogo = app_logo
export const achuthGowda = achuth_gowda
export const mobile = '+91 9845062227'
export const email = 'info@fidelituscorp.com'
export const address = 'Fidelitus Corp, Brigade Software Park, No. 42, Ground Floor,B Block, 27th Cross,Banashankari 2nd Stage, Bangalore – 560070, Karnataka.'
export const department = 'Fidelitus Transactions'
export const noDataLogo = no_data_logo

export const email_regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');


export const servicesArr = [
    {   
        id:1,
        label:'Commercial Interior/ Industrial Design and Architectural Services',
        image:s1ico,
        path:'/services/commercial-interior-design-and-architecture'
    },
    {
        id:2,
        label:'MEP / HVAC and Networking Services',
        image:s2ico,
        path:'/services/mep-hvac-and-networking'
    },
    {
        id:3,
        label:'Project Evaluation and Estiamtion',
        image:s3ico,
        path:'/services/project-evaluation-and-estimation'
    },
    {
        id:4,
        label:'Turnkey Project Execution and General Contracting Services',
        image:s4ico,
        path:'services/turnkey-project-execution-and-general-contracting'
    }
]

export const otherService1Arr = [
    {
        id:1,
        image:resumeDatabase,
        heading:'Talent Acquisition & Management',
        description:'Permanent & Temporary Recruitment solutions for all kinds of industries'
    },
    {
        id:2,
        image:emergingIndividual,
        heading:'Learning & Development',
        description:'We provide a range of Learning & Developing programs at Institutional and Corporate levels'
    },
    {
        id:3,
        image:fastestGrowing,
        heading:'Organization Transformation',
        description:'Let us be your extended HR team to transform your business portfolio for the better'
    },
    {
        id:4,
        image:serviceOffered,
        heading:'Startup Labs',
        description:'Want to launch a successful business? Let us build your core system'
    }
]

export const otherServiceArr = [
    {
        id:1,
        image:speedyProcess,
        heading:'Speedy Process',
        description:'With our vast experience in this industry, we assure timely service'
    },
    {
        id:3,
        image:dedicatedExpert,
        heading:'Dedicated expert',
        description:'An expert manager will be guiding you in every step for your requirements'
    },
    {
        id:3,
        image:dynamicDatabase,
        heading:'Dynamic database',
        description:'Your needs are met with the highest standards with our unmatched database'
    },
    {
        id:4,
        image:clearCommunication,
        heading:'Clear Communication',
        description:'The whole process will go smoothly, as we believe in crystal clear communication'
    }
]

export const blogsArr = [
    {   
        id:1,
        img:blog1,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"4 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"01-01-2024",
        heading:'CHEERS TO 2023 OF SUCCESS AND ONWARDS TO GREATER HEIGHTS IN 2024!',
        short_description:'As we look back on our remarkable journey of 2023, I would like to extend my heartfelt gratitude to our employee’s so-called great assets of',
        published:"",
        data:`
           <p id="font_description">As we look back on our remarkable journey of 2023, I would like to extend my heartfelt gratitude to our employee’s so-called great assets of my life, our valued clients, and supportive family members who have been with us every step of the way. Together, we have achieved great heights in the commercial real estate industry and made a lasting mark.</p>

           <p id="font_description">We set ambitious goals and make bold plans to achieve them. But through it all, we must remember to enjoy the journey towards achieving those goals.</p>

           <p id="font_description">By meeting like-minded and best friend Raffy Espiritu in 2023. I am pleased to announce that the <b id="bolder">Fidelitus Corp</b> and Impec Group have formed a strategic partnership to offer our services by establishing ourselves in Santa Clara, CA- United States.</p>

           <p id="font_description">Fidelitus Corp treasures our partnership with Impec Group. We look forward in building on this alliance in 2024. It should be noted that both firms are strategically positioned to service their respective clients.</p>
          
           <p id="font_description">Over the past year, Fidelitus Corp, with the help of our outstanding team members, has successfully concluded more than 70+ leasing transactions in the sector of office space- warehouse, education, healthcare & industrial plants through our exceptional  <b id="bolder">Fidelitus Transaction Services</b>. Their dedication and expertise have been instrumental in securing positive outcomes for our valued clients.</p>
          
           <p id="font_description">Moreover, our <b id="bolder">Fidelitus Projects</b> division has delivered an impressive 1.1 million square feet of turnkey design-build industrial infrastructure and commercial interior projects across India. The talent and creativity of our architects & engineers have transformed spaces and created environments that inspire success.</p>
          
           <p id="font_description">A big shout out to our hardworking <b id="bolder">Fidelitus Facility Management Services</b> team for deputing over 2100+ manpower, ensuring smooth operations and impeccable maintenance across various facilities. Their commitment to excellence plays a vital role in providing seamless experiences for our clients.</p>
          
           <p id="font_description">Let’s not forget the exceptional work of <b id="bolder">Fidelitus HR Labs</b>, our spectacular talent acquisition team that has successfully recruited over 200+ candidates in the IT/ITES-Fintech-Data Science industry. Their impeccable eye for talent has helped us build a strong and dynamic workforce.</p>
         
           <p id="font_description">I want to express my heartfelt thanks to the brilliant and talented artists of the <b id="bolder">Fidelitus Gallery</b>. Through their dedication and passion for art, they have collaborated with the team ‘Works of Art’ in three cities, resulting in an outstanding start. Their efforts have showcased the beauty and creativity of various artists to a wider audience, creating a vibrant art community.</p>
          
           <p id="font_description">A special shout out to our exceptional Marketing and Digital Media team, whose efforts have propelled Fidelitus Corp to new heights. Through their innovative strategies and hard work, they have curated more than 15+ business events, connecting the Fidelitus family with industry leaders and creating growth opportunities. Their tireless efforts have been instrumental in our continued success.</p>
           
           <p id="font_description">I am also incredibly proud of our <b id="bolder">Shilpa Foundation</b>, which has elevated itself to become a renowned NGO in south India. Their contributions to the community, such as the installation of smart bus stops for the city corporation, have made a significant innovative impact on the urban infrastructure of the country. Additionally, their dedication to providing education for over 150 children exemplifies our commitment to giving back to society and empowering the next generation.           </p>
         
           <p id="font_description">Let us not forget the exceptional work of our IT team, who have transformed Fidelitus Corp into a technology-driven organization. Their expertise and innovative solutions have streamlined our operations and enhanced our ability to deliver unparalleled services to our clients.</p>
        
           <p id="font_description">I would also like to extend my gratitude to the Finance, Operations, and Backend employees who have provided outstanding support throughout this incredible journey. Your dedication, hard work, and attention to detail have been instrumental in our growth and success.</p>
         
           <p id="font_description">Easy to talk about, not so easy to do, yes. The weight of assumptions and obligations is much of the time weighty yet that is where assurance and mental strength come in. Thus, as you step into another year, step into another outlook, one that permits you to propel yourself as well as delayed down, gives you the strength to deal with a test directly and the insight to request help when you want it, and trains you to make a stride back and glance through a more extensive focal point to find individuals and enthusiasm that give you pleasure and a feeling of inspiration.</p>
         
           <p id="font_description">As we look ahead to 2024, I am filled with anticipation and excitement for the bigger move that awaits us. With a focus on innovation, integrity, and excellence, we will continue to provide unparalleled services and solutions in the commercial real estate industry. Together, we will conquer new frontiers and reach even greater heights.</p>
         
           <p id="font_description">I am happy to announce that Fidelitus Corp has been growing by leaps and bounds, expanding into four groups of companies. This journey would not have been possible without the trust and support of our clients, employees, and their families.</p>
           
           <p id="font_description">On the cusp of another year, my message to every one of the youthful, driven experts at Fidelitus, and across the sales force is to make a goal to accomplish self-satisfaction. Track down the focal point of your joy – it for the most part lies in the mixture of your work, leisure activities, demonstrations of philanthropy, and an unshakable emotionally supportive network. Search for the companions who lift you higher, administrators who see the potential that is as yet covered up, and accomplices who champion you even on your haziest days</p>
         
           <p id="font_description">Looking ahead, we are excited for the bigger move that awaits us in 2024. With a focus on innovation, integrity, and excellence, we strive to provide unparalleled services and solutions in the commercial real estate industry.</p>
         
           <p id="font_description">Once again, I express my deepest gratitude to everyone who has been a part of Fidelitus Corp’s journey. Together, we have achieved remarkable milestones, and I am confident that our future endeavors will be even more remarkable.</p>
          
           <p id="font_description">Cheers to 2023 of success and onwards to greater heights in 2024! 🥂</p>

        `
    },
    {   
        id:2,
        img:blog2,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"06-08-2023",
        conclusion:"In conclusion, the rise of AI is significantly reshaping data centers and, by extension, the commercial property industry. By understanding these changes, businesses and investors can leverage the opportunities presented in this evolving landscape. As technology, property, and business intersect in new ways, the future looks bright for those ready to embrace the wave of this exciting evolution.",
        heading:'Data Centers of the Future: The Role of AI in Shaping Data Infrastructure',
        short_description:'As we look back on our remarkable journey of 2023, I would like to extend my heartfelt gratitude to our employee’s so-called great assets of',
        published:"💡The advent of artificial intelligence (AI) is shaking up the commercial property industry. Data centers, once static repositories of information, are now evolving into dynamic, AI-driven hubs. This paradigm shift has far-reaching implications for businesses and investors alike.",
        data:`
           <h6 id="font_heading">Introduction to the Changing Landscape of Data Centers</h6>
           <p id="font_description">Data centers have transformed significantly over the years. Initially used for data storage and networking, they are now expected to support advanced AI applications that require massive computing power, vast storage capacities, and quick data transmission rates. This transformation from conventional data centers to AI powered hubs is influencing their roles, designs, and operations.</p>

           <h6 id="font_heading">Understanding AI’s Infrastructure Demands</h6>
           <p id="font_description">AI applications pose unique and demanding infrastructure requirements that necessitate a rethinking of traditional data center designs.</p>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Computing Hardware:</b>The sophisticated computations involved in AI applications require specialized, powerful processors. GPUs and TPUs, specifically designed for AI workloads, have revolutionized the types of hardware used within data centers. These are critical for tasks like training complex neural networks or processing massive datasets.</li>
                <li id="font_description"><b id="bolder">High-speed Connectivity:</b>The vast amounts of data utilized by AI models, especially in real-time applications, necessitate ultra-fast data transmission speeds. High-bandwidth network connectivity within and between data centers is vital to prevent bottlenecks and ensure the seamless operation of AI applications.</li>
                <li id="font_description"><b id="bolder">Power Supply and Cooling:</b>AI computations are power-intensive and produce significant heat, demanding robust power supplies and efficient cooling systems. The higher energy demands compared to traditional IT workloads have led to a focus on more efficient power supplies, energy-efficient server designs, and advanced cooling solutions.</li>
                <li id="font_description"><b id="bolder">Cloud Infrastructure and Data Storage:</b>The large volumes of data used in AI applications call for high-capacity storage systems and a robust cloud infrastructure. The ability to store and quickly retrieve data is crucial to training AI models and executing AI tasks.</li>
           </ul>

           <p id="font_heading">Growth of Colocation, Hyperscale, and Edge Data Centers.</p>
           <p id="font_description">The demanding needs of AI have led to a rise in different types of data centers, each catering to specific aspects of AI infrastructure:</p>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Colocation Data Centers:</b>These facilities allow businesses to rent space for their servers and other hardware. By sharing infrastructure and services, businesses can benefit from cost savings, increased security, and operational efficiency. The global colocation data center market is set to grow substantially in the coming years, with many businesses seeking to outsource their data center needs to cut costs and improve scalability.</li>
                <li id="font_description"><b id="bolder">Hyperscale Data Centers:</b>These mega facilities are designed to provide extensive computing power and vast storage capacities, making them perfect for the high demands of AI. Tech giants like Amazon, Microsoft, and Google continue to invest heavily in these facilities to support their cloud and AI operations. Hyperscale data centers are expected to dominate the market, given their scalability, efficiency, and ability to handle massive workloads.</li>
                <li id="font_description"><b id="bolder">Edge Data Centers:</b>These small, geographically distributed data centers bring processing power closer to the source of data generation, reducing latency and improving speed—critical factors for many AI applications. As the Internet of Things (IoT) and AI applications proliferate, the edge data center market is set to grow at a phenomenal rate.</li>
            </ul>

            <p id="font_heading">Impact of AI on Data Center Locations</p>
            <p id="font_description">The energy and resource requirements of AI are influencing the location of new data centers. Factors that affect these decisions include:</p>
            <ul id="list-section">
                 <li id="font_description"><b id="bolder">Energy Prices and Availability:</b>The high energy demands of AI applications make regions with low energy costs or abundant renewable energy attractive for data center locations. For instance, regions like Scandinavia, which have lower energy costs and an abundance of renewable energy sources, have become popular locations for <b id="bolder>data centers</b>.</li>
                 <li id="font_description"><b id="bolder">Land Costs:</b>Given their size, data centers require vast tracts of land. Thus, land prices are a crucial factor in site selection. Regions where large parcels of land are available at affordable prices have seen a surge in data center developments.</li>
                 <li id="font_description"><b id="bolder">Regulations:</b>Local regulations regarding energy consumption, data privacy, and environmental impact significantly affect data center location decisions. Areas with favorable regulatory climates, like certain states in the U.S. or countries in the European Union, are often preferred.</li>
             </ul>

             <p id="font_heading">New Design Requirements for AI Data Centers</p>
             <p id="font_description">As the demand for AI grows, data centers are evolving to accommodate the unique needs of AI applications. For instance, Meta, formerly known as Facebook, paused its data center expansion plans to reconsider infrastructure designs to better support AI workloads.</p>
             <ul id="list-section">
                  <li id="font_description"><b id="bolder">Advanced Cooling Solutions:</b>High performance processors for AI applications generate significant heat, requiring innovative cooling solutions. Advanced liquid cooling, which uses liquid rather than air to cool servers, is becoming more commonplace due to its efficiency and ability to handle the heat produced by intense AI computations.</li>
                  <li id="font_description"><b id="bolder">Scalability:</b>AI workloads are expected to increase, requiring data centers to be highly scalable. This means infrastructure components, such as server racks, storage systems, and networking equipment, must be designed to allow easy expansion.</li>
              </ul>


              <p id="font_heading">The Role of Commercial Property Service Providers</p>
              <p id="font_description">In this shifting landscape, commercial property service providers like <b id="bolder">Fidelitus Corp</b> are pivotal. We assist businesses in finding suitable locations for data centers, navigate the complex regulatory landscape, and help understand the new infrastructure demands of AI. For investors, these service providers offer insights into potential returns from investments in data centers, aiding in risk assessment and decision-making.</p>
              <p id="font_description"><b id="bolder">Looking Ahead: Future Trends for AI and Data Centers</b> Several trends could further shape the future of AI and data centers:</p>
            
              <ul id="list-section">
                   <li id="font_description"><b id="bolder">Continued Growth of Hyperscale and Edge Data Centers: </b>The proliferation of AI applications will continue to drive demand for hyperscale and edge data centers, providing more computing power and reducing latency.</li>
                   <li id="font_description"><b id="bolder">Emergence of AI-Optimized Hardware and Infrastructure:</b>The need for faster, more efficient AI computations will spur further innovations in AI-optimized processors, storage systems, and networking technology.</li>
                   <li id="font_description"><b id="bolder">Stricter Energy and Data Regulations:</b>Growing environmental concerns and the increasing importance of data privacy are likely to result in more stringent regulations, which could significantly affect data center operations and locations.</li>
                   <li id="font_description"><b id="bolder">Increased Use of Renewable Energy:</b>To meet high power demands and reduce environmental impacts, data centers will likely shift towards renewable energy sources.</li>
                   <li id="font_description"><b id="bolder">Advanced Cooling Techniques: </b>The heat generated by AI computations will push the development of more efficient cooling techniques, possibly leading to widespread adoption of techniques like immersion cooling.</li>
               </ul>



           <p id="font_description">Fidelitus Corp treasures our partnership with Impec Group. We look forward in building on this alliance in 2024. It should be noted that both firms are strategically positioned to service their respective clients.</p>
          
           <p id="font_description">Over the past year, Fidelitus Corp, with the help of our outstanding team members, has successfully concluded more than 70+ leasing transactions in the sector of office space- warehouse, education, healthcare & industrial plants through our exceptional  <b id="bolder">Fidelitus Transaction Services</b>. Their dedication and expertise have been instrumental in securing positive outcomes for our valued clients.</p>
          
           <p id="font_description">Moreover, our <b id="bolder">Fidelitus Projects</b> division has delivered an impressive 1.1 million square feet of turnkey design-build industrial infrastructure and commercial interior projects across India. The talent and creativity of our architects & engineers have transformed spaces and created environments that inspire success.</p>
          
           <p id="font_description">A big shout out to our hardworking <b id="bolder">Fidelitus Facility Management Services</b> team for deputing over 2100+ manpower, ensuring smooth operations and impeccable maintenance across various facilities. Their commitment to excellence plays a vital role in providing seamless experiences for our clients.</p>
          
           <p id="font_description">Let’s not forget the exceptional work of <b id="bolder">Fidelitus HR Labs</b>, our spectacular talent acquisition team that has successfully recruited over 200+ candidates in the IT/ITES-Fintech-Data Science industry. Their impeccable eye for talent has helped us build a strong and dynamic workforce.</p>
         
           <p id="font_description">I want to express my heartfelt thanks to the brilliant and talented artists of the <b id="bolder">Fidelitus Gallery</b>. Through their dedication and passion for art, they have collaborated with the team ‘Works of Art’ in three cities, resulting in an outstanding start. Their efforts have showcased the beauty and creativity of various artists to a wider audience, creating a vibrant art community.</p>
          
           <p id="font_description">A special shout out to our exceptional Marketing and Digital Media team, whose efforts have propelled Fidelitus Corp to new heights. Through their innovative strategies and hard work, they have curated more than 15+ business events, connecting the Fidelitus family with industry leaders and creating growth opportunities. Their tireless efforts have been instrumental in our continued success.</p>
           
           <p id="font_description">I am also incredibly proud of our <b id="bolder">Shilpa Foundation</b>, which has elevated itself to become a renowned NGO in south India. Their contributions to the community, such as the installation of smart bus stops for the city corporation, have made a significant innovative impact on the urban infrastructure of the country. Additionally, their dedication to providing education for over 150 children exemplifies our commitment to giving back to society and empowering the next generation.           </p>
         
           <p id="font_description">Let us not forget the exceptional work of our IT team, who have transformed Fidelitus Corp into a technology-driven organization. Their expertise and innovative solutions have streamlined our operations and enhanced our ability to deliver unparalleled services to our clients.</p>
        
           <p id="font_description">I would also like to extend my gratitude to the Finance, Operations, and Backend employees who have provided outstanding support throughout this incredible journey. Your dedication, hard work, and attention to detail have been instrumental in our growth and success.</p>
         
           <p id="font_description">Easy to talk about, not so easy to do, yes. The weight of assumptions and obligations is much of the time weighty yet that is where assurance and mental strength come in. Thus, as you step into another year, step into another outlook, one that permits you to propel yourself as well as delayed down, gives you the strength to deal with a test directly and the insight to request help when you want it, and trains you to make a stride back and glance through a more extensive focal point to find individuals and enthusiasm that give you pleasure and a feeling of inspiration.</p>
         
           <p id="font_description">As we look ahead to 2024, I am filled with anticipation and excitement for the bigger move that awaits us. With a focus on innovation, integrity, and excellence, we will continue to provide unparalleled services and solutions in the commercial real estate industry. Together, we will conquer new frontiers and reach even greater heights.</p>
         
           <p id="font_description">I am happy to announce that Fidelitus Corp has been growing by leaps and bounds, expanding into four groups of companies. This journey would not have been possible without the trust and support of our clients, employees, and their families.</p>
           
           <p id="font_description">On the cusp of another year, my message to every one of the youthful, driven experts at Fidelitus, and across the sales force is to make a goal to accomplish self-satisfaction. Track down the focal point of your joy – it for the most part lies in the mixture of your work, leisure activities, demonstrations of philanthropy, and an unshakable emotionally supportive network. Search for the companions who lift you higher, administrators who see the potential that is as yet covered up, and accomplices who champion you even on your haziest days</p>
         
           <p id="font_description">Looking ahead, we are excited for the bigger move that awaits us in 2024. With a focus on innovation, integrity, and excellence, we strive to provide unparalleled services and solutions in the commercial real estate industry.</p>
         
           <p id="font_description">Once again, I express my deepest gratitude to everyone who has been a part of Fidelitus Corp’s journey. Together, we have achieved remarkable milestones, and I am confident that our future endeavors will be even more remarkable.</p>
          
           <p id="font_description">Cheers to 2023 of success and onwards to greater heights in 2024! 🥂</p>

        `
    },
    {   
        id:3,
        img:blog3,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"7 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"18-08-2023",
        conclusion:"Optimizing energy efficiency is no longer a luxury but a necessity in today’s sustainable property management landscape. Fidelitus Corp, a leading commercial property services provider, takes a proactive approach to drive energy efficiency and sustainability. By conducting comprehensive energy audits, implementing energy-efficient measures, integrating renewable energy sources, leveraging building automation systems, promoting tenant engagement, and embracing continuous monitoring, Fidelitus Corp sets a benchmark for sustainable property management. By adopting these key strategies, property owners and managers can reduce environmental impact, achieve significant cost savings, and create a positive and sustainable future for their properties and the planet.",
        heading:'Optimizing Energy Efficiency: Key Strategies for Sustainable Property Management',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency is not only crucial for reducing carbon footprints but also for achieving substantial cost savings and enhancing brand reputation. This article dives into the key strategies to drive sustainable property management and maximize energy efficiency.",
        data:`
           <h6 id="font_heading">1. The Significance of Energy Efficiency in Property Management</h6>
           <p id="font_description">Commercial properties are major contributors to carbon emissions, making energy efficiency a critical focus for sustainable property management. By implementing energy-efficient practices, property managers can:</p>

           <ul id="list-section">
                <li id="font_description">Reduce environmental impact and contribute to global efforts in combating climate change.</li>
                <li id="font_description">Achieve substantial cost savings by minimizing energy consumption and lowering utility bills.</li>
                <li id="font_description">Enhance brand reputation by showcasing a commitment to sustainability and attracting environmentally conscious tenants and investors.</li>
           </ul>

           <p id="font_heading">2. Conducting Comprehensive Energy Audits</p>
           <p id="font_description">To identify energy-saving opportunities and create a roadmap for energy efficiency
           improvements, conducting thorough energy audits is essential. Fidelitus Corp offers
           comprehensive energy audit services that involve:</p>
           <ul id="list-section">
                <li id="font_description">Gathering detailed data on energy consumption, including electricity, heating, cooling, and water usage.</li>
                <li id="font_description">Analyzing the data to identify inefficiencies and areas for improvement.</li>
                <li id="font_description">Conducting on-site inspections to assess equipment, insulation, and building envelope.</li>
                <li id="font_description">Providing detailed reports with recommendations for energy-efficient measures
                tailored to each property.</li>
            </ul>

            <p id="font_heading">3. Implementing Energy-Efficient Measures</p>
            <p id="font_description">Fidelitus Corp specializes in implementing a range of energy-efficient measures to optimize property performance. Some key strategies include:</p>
            <ul id="list-section">
                 <li id="font_description"><b id="bolder">Lighting Upgrades: </b>Replacing traditional lighting with energy-efficient LED systems, incorporating motion sensors and daylight harvesting for optimal lighting control.</li>
                 <li id="font_description"><b id="bolder">Installing programmable thermostats, regular maintenance, and retro-commissioning to improve efficiency and occupant comfort.</li>
             </ul>

             <p id="font_heading">4. Integrating Renewable Energy Sources</p>
             <p id="font_description">Fidelitus Corp recognizes the significance of renewable energy integration to achieve
             sustainable property management. They provide expertise in:</p>
             <ul id="list-section">
                  <li id="font_description"><b id="bolder">Solar Energy:</b>Assessing the feasibility of solar panel installations, including rooftop and ground-mounted systems, to generate clean energy and reduce reliance on the</li>
                  <li id="font_description"><b id="bolder">Wind Energy:</b>Evaluating wind resource potential and feasibility for harnessing wind power through turbines in suitable locations.</li>
                  <li id="font_description"><b id="bolder">Battery Storage Solutions:</b>Implementing energy storage systems to store excess renewable energy for later use  and enhance overall energy efficiency.</li>
              </ul>


              <p id="font_heading">5. Leveraging Building Automation Systems</p>
              <p id="font_description">Building Automation Systems (BAS) play a crucial role in optimizing energy management. Fidelitus Corp leverages BAS to:</p>
            
              <ul id="list-section">
                   <li id="font_description"><b id="bolder">Monitor Real-time Energy Consumption: </b>Collecting data on energy usage and performance indicators to identify anomalies and optimize energy efficiency.</li>
                   <li id="font_description"><b id="bolder">Automated Controls:</b>Utilizing programmable systems to regulate HVAC, lighting, and other building systems based on occupancy schedules and demand.</li>
                   <li id="font_description"><b id="bolder">Data Analytics:</b>Employing advanced analytics tools to analyze energy data, identify trends, and make informed decisions for further optimization.</li>
               </ul>

               <p id="font_heading">6. Promoting Tenant Engagement and Education</p>
               <p id="font_description">Engaging tenants in energy conservation practices is vital for sustainable property management. Fidelitus Corp employs various strategies to promote tenant engagement:</p>
             
               <ul id="list-section">
                    <li id="font_description"><b id="bolder">Tenant Education: </b>Organizing workshops, seminars, and educational materials to raise awareness about energy-efficient practices and encourage behavior changes.</li>
                    <li id="font_description"><b id="bolder">Incentive Programs:</b> Implementing rewards programs or competitions to motivate tenants to reduce energy consumption and contribute to sustainability goals.</li>
                    <li id="font_description"><b id="bolder">Transparent Communication:</b>Establishing open lines of communication to keep tenants informed about sustainability initiatives, energy-saving tips, and progress updates.</li>
                </ul>

                <p id="font_heading">Monitoring, Measurement, and Benchmarking</p>
                <p id="font_description">Continuous monitoring, measurement, and benchmarking are crucial for tracking energy performance and identifying areas for improvement. Fidelitus Corp utilizes:</p>
              
                <ul id="list-section">
                     <li id="font_description"><b id="bolder">Energy Management Software: </b>Implementing advanced software tools to track real-time energy usage, analyze data, and generate comprehensive reports.</li>
                     <li id="font_description"><b id="bolder">Key Performance Indicators (KPIs): </b>Defining specific KPIs, such as energy intensity, greenhouse gas emissions, and energy cost per square foot, to measure and compare energy efficiency across properties.</li>
                     <li id="font_description"><b id="bolder">Industry Standards and Certifications: </b>Adhering to recognized standards, such as LEED (Leadership in Energy and Environmental Design), to benchmark energy performance and validate sustainability achievements.</li>
                 </ul>
 


          
        `
    },
    {   
        id:4,
        img:blog4,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"7 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"06-06-2023",
        conclusion:"Real estate development in India presents a significant opportunity for investors, with high returns on investment and a growing demand for real estate in the country. However, investors need to be aware of the challenges and risks involved, such as regulatory environment, land title disputes, and market fluctuations. By conducting thorough due diligence, having a long-term investment strategy in place, and keeping up-to-date with the latest trends and developments in the Indian real estate market, investors can capitalize on the opportunities presented by the growing demand for real estate in India.</br> As the Indian economy continues to grow and develop, the real estate sector is expected to play a key role in supporting this growth. With the right investment strategy and approach, investors can be well-positioned to take advantage of the opportunities presented by the Indian real estate market.",
        heading:'The Growing Demand for Real Estate Development in India: Perspectives for Investors',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡India is witnessing a significant increase in demand for real estate development, especially in tier 2 and 3 cities. This demand is driven by factors such as the pandemic-induced preference for larger homes, growth of the e-commerce sector, and the government’s focus on infrastructure development. As a result, the real estate sector in India is expected to continue to grow in the coming years, making it an attractive investment opportunity for domestic and foreign investors. In this article, we will explore the perspective of investors in real estate development in India. We will discuss the opportunities and challenges faced by investors, as well as the trends and developments in the real estate market in India.",
        data:`
           <h6 id="font_heading">Opportunities for Investors:</h6>
           <h6 id="font_heading">1. High Returns on Investment</h6>
           <p id="font_description">Investors in real estate development in India are likely to see high returns on their investments due to the growing demand for real estate in the country. The increase in demand is primarily driven by factors such as population growth, urbanization, and the rise of the middle class. Additionally, the Indian government has implemented various policies and incentives to encourage real estate development, such as the Real Estate (Regulation and Development) Act, 2016, and the Pradhan Mantri Awas Yojana (PMAY) scheme.</p>

           <h6 id="font_heading">2. Availability of Financing Options</h6>
           <p id="font_description">Investors can access a variety of financing options to fund their real estate development projects in India, including bank loans, private equity, and foreign direct investment. In recent years, the Indian government has also taken several steps to ease foreign investment restrictions in real estate development, making it easier for foreign investors to invest in the sector.</p>


           <h6 id="font_heading">3. Growth in Tier 2 and 3 Cities</h6>
           <p id="font_description">The growth in demand for real estate development in India is not limited to metropolitan cities. Tier 2 and 3 cities in India are also experiencing a surge in demand for real estate due to factors such as increased economic activity, infrastructure development, and rising incomes. Investors can take advantage of this trend by investing in real estate projects in these cities, which often offer higher returns on investment compared to metropolitan cities. </p>


           <h6 id="font_heading">Rising to the Challenge: How Real Estate Investors in India Can Turn Obstacles into Opportunities”</h6>
         
           <ul id="list-section">
                <li id="font_description"><b id="bolder">1. Regulatory Environment:</b>While the regulatory environment in India can be challenging, it is also becoming more streamlined and transparent. The government has implemented various policies and initiatives to make the real estate sector more investor friendly, such as the Real Estate (Regulation and Development) Act, 2016, and the Digital India initiative. Additionally, the government has made significant efforts to simplify the approval process for real estate projects, which canreduce delays and make the process more efficient.</li>
                <li id="font_description"><b id="bolder">2. Land Title Disputes:</b>While land title disputes can be a significant challenge for investors, there are also opportunities to find undervalued properties and negotiate favourable deals. By conducting thorough due diligence and partnering with reputable local developers and legal experts, investors can mitigate the risks associated with land title disputes and acquire high-quality properties at lower prices.</li>
                <li id="font_description"><b id="bolder">3. Market Fluctuations:</b>Market fluctuations are a natural part of any investment, and real estate in India is no exception. However, these fluctuations can also present opportunities for investors to acquire assets at lower prices and generate higher returns over the long-term. By staying up-to-date with the latest market trends and developments, investors can identify opportunities to enter or exit the market at the right time, maximizing their returns.</li>
           </ul>

           <h6 id="font_heading">Trends and Developments in the Indian Real Estate Market:</h6>

           <ul id="list-section">
                <li id="font_description"><b id="bolder">1. Technology Adoption:</b>The Indian real estate market is embracing technology, with the use of tools such as virtual reality, artificial intelligence, and blockchain. These technologies are being used to improve the efficiency and transparency of real estate transactions, as well as to enhance the customer experience.</li>
                <li id="font_description"><b id="bolder">2. Sustainable Development:</b>Sustainable development is becoming increasingly important in the Indian real estate market, with
                developers focusing on green building practices and environmentally friendly materials. The Indian government has also implemented various policies and incentives to encourage sustainable development, such as the Energy Conservation Building Code (ECBC).</li>
                <li id="font_description"><b id="bolder">3. Co-Living and Co-Working Spaces:</b>Co-living and co-working spaces are gaining popularity in India, particularly among millennials and young professionals. These spaces offer affordable and flexible accommodation and work options, as well as a sense of community and networking opportunities. Investors can take advantage of this trend by investing in real estate projects that cater to the needs of this demographic. </li>
            </ul>

         
          
        `
    },
    {   
        id:5,
        img:blog5,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"4 min Read",
        category:"Land Transaction / Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"08-05-2023",
        conclusion:"In conclusion, investing in commercial land in India can provide several benefits, such as higher rental yields, long-term appreciation, tax benefits, greater control over investment, potential for value-added investments, stable cash flow, and diversification of portfolio. However, before making any investment decisions, investors must conduct thorough research and due diligence to identify the right investment opportunities. It is crucial to evaluate factors such as location, demand, supply, and lease agreements before investing in commercial properties.  ",
        heading:'The Benefits of Investing in Commercial Land in India',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡As the Indian economy continues to grow, the real estate market has been witnessing a steady rise in demand for commercial land investments. Commercial land investment offers several advantages, such as higher rental yields, long-term appreciation, tax benefits, and stable cash flow, making it an attractive investment option for investors. In this article, we will explore the various benefits of investing in commercial land in India and how to fully leverage these advantages. ",
        data:`
           <p id="font_description"><b id="bolder">According to Pramod GP, Director – Transaction Services at Fidelitus Corp</b>, "Commercial land investments offer a stable income stream and potential for long-term appreciation. With the right strategy, investors can benefit from higher rental yields, tax benefits, and greater control over their investments.”</p>

         
           <h6 id="font_heading">Benefits of investing in commercial land in India</h6>
         
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Higher Rental Yields:</b> Investing in commercial land can provide higher rental yields than residential property investments. Commercial properties such as office spaces, retail spaces, and warehouses tend to generate higher rental income due to their higher occupancy rates and longer lease periods. This longer lease period translates to a more reliable and stable income stream for the property owner. Additionally, commercial tenants tend to be more reliable and financially stable than residential tenants, further reducing the risk of rental defaults. This reliability results in lower tenant turnover rates and the potential for longer leases, which translates to more stable rental income.</li>
                <li id="font_description"><b id="bolder">Long-term Appreciation: </b> Investing in commercial land can also offer long-term appreciation, especially in prime locations such as central business districts or industrial areas. As the demand for commercial properties grows, the value of the property increases, providing significant capital gains for the property owner. Commercial land investments tend to hold their value better than residential properties, making them a more stable investment option. This stability is due to the higher entry barriers for commercial properties, including higher capital requirements and more stringent zoning laws. The demand for commercial properties also tends to be less cyclical than residential properties, making them less vulnerable to market volatility.</li>
                <li id="font_description"><b id="bolder">Tax Benefits: </b> Commercial land investments offer various tax benefits, such as deduction of mortgage interest, property taxes, and depreciation expenses. Additionally, commercial property owners can claim deductions for expenses such as maintenance, repairs, and upgrades, reducing their overall tax liability. Investors can also use tax-deferred exchanges (also known as a 1031 exchange) to defer paying capital gains taxes when selling one commercial property and purchasing another. This tax benefit allows investors to reinvest the proceeds from the sale of one commercial property into another commercial property, without paying capital gains taxes.</li>
                <li id="font_description"><b id="bolder">Greater Control over Investment: </b> Investing in commercial land provides greater control over the investment compared to other forms of real estate investment, such as REITs or mutual funds. Commercial property owners have the freedom to make decisions about the property, such as leasing, maintenance, and upgrades, based on their individual goals and risk appetite. Additionally, commercial property owners can customize their investment strategies to meet their individual needs. For instance, they can focus on acquiring properties with long-term leases or invest in value-added properties to increase their overall returns.</li>
                <li id="font_description"><b id="bolder">Potential for Value-added Investments: </b> Investing in commercial land also provides opportunities for value-added investments, such as renovation or redevelopment projects. By upgrading the property, commercial property owners can increase the property’s value, attract higher-quality tenants, and generate higher rental income. Value-added investments require a higher level of expertise and experience, but they can offer significant returns for investors. For instance, a commercial property owner can purchase an older property in a prime location, renovate it, and attract high-quality tenants who are willing to pay higher rent. This approach not only increases the value of the property but also generates a higher rental income for the property owner.</li>
                <li id="font_description"><b id="bolder">Stable Cash Flow: </b> Commercial land investments provide stable cash flow due to their longer lease periods and higher occupancy rates. Additionally, commercial tenants tend to pay their rent on time, providing a consistent income stream to the property owner. Unlike residential properties, commercial properties have a lower vacancy rate, which ensures a more stable and predictable income stream. Additionally, commercial tenants are often more financially stable, making them less likely to default on their rent payments.</li>
                <li id="font_description"><b id="bolder">Diversification of Portfolio: </b> Investing in commercial land provides an opportunity for portfolio diversification, especially for investors with a significant investment in residential properties. Commercial land investments can provide a hedge against market volatility, as they tend to be less affected by economic downturns compared to residential properties. Additionally, commercial properties offer diversification across different sectors of the economy. For instance, investing in commercial land in retail and office sectors can provide exposure to different aspects of the economy, such as consumer spending and business growth. </li>
           </ul>

           <h6 id="font_description"><b id="bolder">As Pramod GP, Director – Transaction Services at Fidelitus Corp suggests</b>, commercial land investments can offer stable income streams and long-term appreciation potential, but it requires the right investment strategy to fully leverage the benefits. By understanding the potential advantages of investing in commercial land, investors can make informed decisions and capitalize on the growing Indian real estate market.</h6>

           
          
        `
    },
    {   
        id:6,
        img:blog6,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"4 min Read",
        category:"Land Transaction / Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"06-09-2023",
        conclusion:"Flexibility in commercial real estate leasing is essential for businesses to navigate uncertainties effectively. Long-term leases provide stability, cost savings, and the ability to plan for the future. Lease restructuring offers businesses the flexibility to adapt their lease agreements to changing market conditions, optimizing their space utilization and financial commitments. Fidelitus Corp, with its expertise in lease restructuring, market knowledge, and commitment to client satisfaction, serves as a trusted partner for businesses in uncertain situations. By embracing long-term leases and lease restructuring, companies can adapt to changing market conditions, scale their operations accordingly, and secure their position in the competitive business landscape. The future of commercial real estate will see the continued rise of flexible leasing options, incorporating technological advancements and sustainable practices. With the right strategies and trusted partners like Fidelitus Corp, businesses can navigate uncertainties effectively and thrive in the ever-evolving market. ",
        heading:'Flexibility in Commercial Real Estate Leasing: Navigating Uncertainties with Long-Term Lease and Lease Restructuring',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡In today’s dynamic business landscape, companies and organizations face unprecedented uncertainties. The ability to adapt swiftly and efficiently is crucial for survival and growth. One area that demands particular attention is commercial real estate leasing. Traditional long term leases and lease restructuring offer feasible solutions that cater to the unique requirements of businesses during uncertain times. This article explores the importance of flexibility in commercial real estate leasing, focusing on long -term lease and lease restructuring options. We will also highlight the role of Fidelitus Corp, a leading commercial property service provider, in mitigating losses and facilitating sustainable business practices. ",
        data:`

         
           <h6 id="font_heading">The Importance of Flexibility in Commercial Real Estate Leasing During Uncertain Times: </h6>
           <p id="font_description">During times of uncertainty, businesses require flexibility to respond to changing market conditions and unforeseen circumstances. Long-term lease and lease restructuring options provide the necessary adaptability to navigate uncertainties effectively. They empower companies to scale their operations, optimize space utilization, and align their real estate needs with evolving market dynamics. Flexibility in commercial real estate leasing fosters resilience, mitigates risks, and ensures long-term sustainability.</p>
         
           <h6 id="font_heading">Long-Term Lease: Stability and Planning for the Future: </h6>
           <p id="font_description">Long-term leases, spanning several years, offer stability and the ability to plan for the future. They are particularly suitable for established businesses with predictable space requirements and long-term growth plans. Advantages of long-term leases include:</p>
         

           <h6 id="font_heading">Stability and Secure Operations:</h6>
           <ul id="list-section">
                <li id="font_description">Long-term leases provide businesses with a stable base of operations, allowing them to establish a long-term presence in the market.</li>
                <li id="font_description">This stability helps build customer trust and loyalty.</li>
           </ul>

           <h6 id="font_heading">Cost Efficiency:</h6>
           <ul id="list-section">
                <li id="font_description">Long-term leases often come with lower rental rates compared to short-term arrangements.</li>
                <li id="font_description">Businesses can benefit from cost savings and predictable expenses, especially when locked into a favorable lease agreement.</li>
           </ul>

           <h6 id="font_heading">Customization and Branding:</h6>
           <ul id="list-section">
                <li id="font_description">Long-term leases provide the opportunity for businesses to customize and brand their space.</li>
                <li id="font_description">This fosters a sense of identity, enhances brand recognition, and creates a professional environment tailored to specific needs.</li>
           </ul>

           <h6 id="font_heading">Lease Restructuring: Adapting Lease Agreements to Uncertain Times: </h6>
           <p id="font_description"Lease restructuring is an effective solution for businesses facing uncertainties and needing to adapt their existing lease agreements. This process involves renegotiating lease terms with landlords to accommodate changing circumstances. Benefits of lease restructuring include:</p>
         

           <h6 id="font_heading">Flexibility in Financial Commitments:</h6>
           <ul id="list-section">
                <li id="font_description">Lease restructuring allows businesses to modify lease duration, rent payments, or space configuration to align with evolving market conditions.</li>
                <li id="font_description">This flexibility ensures that businesses can optimize their financial commitments and remain competitive.</li>
           </ul>

           <h6 id="font_heading">Space Utilization Optimization:</h6>
           <ul id="list-section">
                <li id="font_description">Through lease restructuring, businesses can optimize their space utilization.</li>
                <li id="font_description">This may involve downsizing or expanding space based on changing needs, ensuring efficient operations and cost savings.</li>
           </ul>


           <h6 id="font_heading">Adaptability to Market Dynamics:</h6>
           <ul id="list-section">
                <li id="font_description">Lease restructuring enables businesses to respond to shifting market dynamics, such as changes in industry trends or customer demands.</li>
                <li id="font_description">It provides the necessary flexibility to realign operations and seize new opportunities.</li>
           </ul>


           <h6 id="font_heading">Fidelitus Corp: A Trusted Partner in Navigating Uncertainties:</h6>
           <p id="font_description">Fidelitus Corp, a leading commercial property service provider, plays a vital role in helping companies and organizations navigate uncertainties in commercial real estate leasing. With its extensive experience and market knowledge, Fidelitus Corp acts as a trusted partner to mitigate losses and ensure business continuity. How Fidelitus Corp can help companies in uncertain situations:</p>
           
           <h6 id="font_heading">Thorough Market Analysis:</h6>
           <ul id="list-section">
                <li id="font_description">Fidelitus Corp conducts in-depth market analysis to assess current leasing conditions, trends, and opportunities.</li>
                <li id="font_description">This analysis helps businesses make informed decisions and navigate uncertainties effectively.</li>
           </ul>

           <h6 id="font_heading">Lease Restructuring Expertise:</h6>
           <ul id="list-section">
                <li id="font_description">Fidelitus Corp possesses extensive expertise in lease restructuring.</li>
                <li id="font_description">The company guides businesses through the process, negotiating with landlords to modify lease agreements and align them with current needs.</li>
           </ul>

           <h6 id="font_heading">Relationship Management:</h6>
           <ul id="list-section">
                <li id="font_description">Fidelitus Corp maintains strong relationships with landlords and clients.</li>
                <li id="font_description">This enables them to facilitate successful lease restructuring negotiations and find mutually beneficial solutions.</li>
           </ul>


           <h6 id="font_heading">Sourcing New Tenants:</h6>
           <ul id="list-section">
                <li id="font_description">In uncertain situations, Fidelitus Corp leverages its network and market understanding to source new tenants for commercial premises.</li>
                <li id="font_description">This ensures continuity for businesses while minimizing losses for landlords.</li>
           </ul>

           <h6 id="font_heading">Coordinating and Negotiating:</h6>
           <ul id="list-section">
                <li id="font_description">Fidelitus Corp assists with coordinating and negotiating with the lessee and landlord to terminate the current lease agreement during the lock-in period.</li>
                <li id="font_description">They work towards arranging a fresh agreement with a new client, creating a win-win situation for all parties involved.</li>
           </ul>

           <h6 id="font_heading">Tailored Solutions:</h6>
           <ul id="list-section">
                <li id="font_description">Fidelitus Corp understands that each business has unique needs and challenges.</li>
                <li id="font_description">They provide personalized solutions, tailored to the specific requirements of their clients.</li>
           </ul>

           <h6 id="font_heading">Mitigating Losses and Ensuring Business Continuity:</h6>
           <ul id="list-section">
                <li id="font_description">Fidelitus Corp’s expertise in lease restructuring and market understanding mitigates losses for businesses facing uncertainties.</li>
                <li id="font_description">By facilitating successful lease modifications, Fidelitus Corp ensures business continuity and sustainability.</li>
           </ul>

           <h6 id="font_heading">How Flexible Leasing Can Help Businesses Adapt to Changing Market Conditions:</h6>
           <p id="font_description">Flexible leasing options, including long-term leases and lease restructuring, offer businesses the ability to adapt swiftly to changing market conditions. By embracing flexibility, businesses can:</p>
           

           <h6 id="font_heading">Optimize Costs:</h6>
           <ul id="list-section">
                <li id="font_description">Long-term leases enable businesses to secure favorable rental rates and plan for predictable expenses, optimizing their cost structure.</li>
                <li id="font_description">Lease restructuring allows for adjustments in lease terms, reducing financial burdens during uncertain times.</li>
           </ul>

           <h6 id="font_heading">Scale Operations:</h6>
           <ul id="list-section">
                <li id="font_description">Flexible leasing options provide businesses with the agility to scale their operations up or down based on market demands.</li>
                <li id="font_description">Companies can easily expand or contract their leased space as needed, minimizing disruptions and maximizing efficiency.</li>
           </ul>

           <h6 id="font_heading">Seize Opportunities:</h6>
           <ul id="list-section">
                <li id="font_description">In a dynamic market, opportunities arise unexpectedly.</li>
                <li id="font_description">Flexible leasing options empower businesses to seize these opportunities swiftly, adjusting their lease agreements to accommodate new ventures or expansions.</li>
           </ul>

           <h6 id="font_heading">The Evolving Trends in Flexible Leasing and Predictions for the Future: </h6>
           <p id="font_description">The commercial real estate industry is witnessing a significant shift towards flexible leasing options. As businesses prioritize agility and adaptability, the demand for long-term leases and lease restructuring is expected to continue to grow. The future of commercial real estate leasing is likely to be shaped by the following trends:</p>
           
           <h6 id="font_heading">Technological Integration:</h6>
           <ul id="list-section">
                <li id="font_description">The integration of technology in commercial real estate leasing will increase, with advancements such as smart building systems, virtual reality tours, and data-driven analytics.</li>
                <li id="font_description">These technologies will enhance the leasing experience, enabling businesses to make more informed decisions and optimize space utilization.</li>
           </ul>

           <h6 id="font_heading">Sustainability and Environmental Considerations:</h6>
           <ul id="list-section">
                <li id="font_description">Businesses are increasingly prioritizing sustainability and environmental practices.</li>
                <li id="font_description">Commercial real estate leasing will reflect this shift, with companies seeking spaces that align with their green initiatives, emphasizing energy efficiency and eco-friendly practices.</li>
           </ul>
          
        `
    },
    {   
        id:7,
        img:blog7,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"3 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"21-02-2023",
        conclusion:"The commercial real estate market in Bengaluru is constantly evolving, and businesses need to stay up-to-date on the latest trends to make the best decisions for their needs. By understanding the emerging trends and potential challenges, businesses can take advantage of the opportunities in the market and make the right decisions for their businesses. Keeping an eye out for trends such as the increasing demand for smart buildings, proptech, sustainability, corporate housing, and mixed-use developments can help businesses stay ahead of the competition. Additionally, businesses should be aware of potential challenges such as rising land costs and the lack of infrastructure in some areas. By understanding the trends and challenges, businesses can make the best decisions for their needs and take advantage of the opportunities in the market. Understanding the challenges and opportunities in the Bengaluru commercial real estate market is essential for businesses to make the right decisions. Companies should keep an eye out for trends such as the increasing demand for smart buildings, proptech, sustainability, and corporate housing. Additionally, businesses should be aware of the potential challenges such as rising land costs and the lack of infrastructure in some areas. By understanding the trends and challenges, businesses can make the best decisions for their needs and take advantage of the opportunities in the market.",
        heading:'Bengaluru’s Commercial Real Estate Trends',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡The commercial real estate market in Bengaluru is constantly changing and evolving. In order to stay ahead of the competition and make the best decisions for their businesses, it is essential for real estate professionals to stay abreast of the latest trends. This article will outline the top 10 commercial real estate trends to watch out for in Bengaluru in 2023.",
        data:`

         
           <h6 id="font_heading">Top 10 Commercial Real Estate Trends to Watch Out for in Bengaluru in 2023</h6>
         
           <h6 id="font_heading">1. Growing Demand for Smart Buildings</h6>
           <p id="font_description">Smart buildings are becoming increasingly popular in Bengaluru thanks to their energy efficiency and cost savings. Smart buildings use cutting-edge technology to monitor and control energy usage, allowing owners to reduce energy costs and increase efficiency. Smart buildings can also provide tenants with a higher quality of life, as they offer features such as automated lighting, temperature control, and security systems.</p>
         
           <h6 id="font_heading">2. Emergence of Proptech</h6>
           <p id="font_description">Proptech, or property technology, is revolutionizing the real estate industry. Proptech companies use technology to streamline the buying, selling, and renting process, making it easier and faster for buyers, sellers, and tenants to find the perfect property. Proptech companies are also making it easier to manage properties, as they offer features such as automated rent payments, tenant screening, and maintenance tracking.</p>
         
           <h6 id="font_heading">3. Increased Focus on Sustainability</h6>
           <p id="font_description">Sustainability is becoming an increasingly important factor in the commercial real estate market. Companies are looking for ways to reduce their environmental impact, and this is driving demand for green buildings. Green buildings use renewable energy sources, implement water-saving measures, and use eco-friendly materials. Green buildings can also provide tenants with a healthier living environment, as they are designed to improve air quality and reduce noise pollution.</p>
         
           <h6 id="font_heading">4. Emergence of Co-Working Spaces</h6>
           <p id="font_description">Co-working spaces are becoming increasingly popular in Bengaluru. These shared workspaces provide businesses with flexible and affordable office space, allowing entrepreneurs to focus on growing their business without worrying about the Bengaluru’s Commercial Real Estate Trends – A Blog 2 cost of renting office space. <b id="bolder"> Co-working spaces </b> can also offer businesses access to a wider range of professionals and resources, as they are often located in business hubs.</p>
         
           <h6 id="font_heading">5. Growing Demand for Data Centres</h6>
           <p id="font_description">The demand for data centres is growing in Bengaluru as businesses look for ways to store and manage their data more efficiently. Data centers provide businesses with secure, reliable, and cost-effective storage solutions. Data centers are also becoming increasingly popular as businesses look for ways to protect their data from cyber attacks and other threats.</p>
         
           <h6 id="font_heading">6. Increased Use of Technology</h6>
           <p id="font_description">Technology is playing an increasingly important role in the commercial real estate market. From virtual reality to artificial intelligence, technology is being used to make the buying, selling, and renting process easier and more efficient. Technology is also being used to automate tasks such as property management, tenant screening, and rent collection.</p>
         
           <h6 id="font_heading">7. Growing Popularity of Modular Construction</h6>
           <p id="font_description">Modular construction is becoming increasingly popular in Bengaluru as businesses look for ways to reduce construction costs and shorten the timeline. Modular construction uses prefabricated components that are assembled on-site, allowing construction to be completed faster and more efficiently. Modular construction also offers businesses the ability to customize their buildings to meet their specific needs.</p>
         
           <h6 id="font_heading">8. Growing Demand for Amenities</h6>
           <p id="font_description">Amenities such as gyms, cafes, and co-working spaces are becoming increasingly important in Bengaluru as businesses look for ways to attract and retain tenants. These amenities can help businesses stand out from their competitors and improve tenant satisfaction. Amenities are also becoming increasingly important as businesses look for ways to provide their employees with a better work-life balance.</p>
         
           <h6 id="font_heading">9. Increasing Popularity of Corporate Housing</h6>
           <p id="font_description">Corporate housing is becoming increasingly popular in Bengaluru as companies look for ways to provide employees with comfortable and affordable housing. Corporate housing offers businesses the opportunity to provide their employees with high-quality housing without the hassle of renting and managing multiple apartments. Corporate housing can also provide businesses with an additional source of income, as they can rent out unused units to tenants.</p>
         
           <h6 id="font_heading">10. Growing Interest in Mixed-Use Developments</h6>
           <p id="font_description">Mixed-use developments are becoming increasingly popular in Bengaluru. These developments combine residential, commercial, and retail space, allowing businesses to benefit from the increased foot traffic. Mixed-use developments can also provide businesses with a more diverse tenant base, as they allow businesses to target multiple types of customers.</p>
         

           <h6 id="font_heading">💡 Potential Challenges</h6>
           <p id="font_description">While there are many exciting trends in the commercial real estate market in Bengaluru, there are also potential challenges that businesses should be aware of. The rising cost of land and property taxes can make it difficult for businesses to find affordable properties, and the increasing demand for skilled labor can make it difficult to find qualified workers. Additionally, the lack of infrastructure in some areas can make it difficult for businesses to access the services and amenities they need.</p>
         
           <h6 id="font_heading">💡 Opportunities</h6>
           <p id="font_description">Despite the challenges, there are also opportunities for businesses to take advantage of in the commercial real estate market in Bengaluru. Businesses can benefit from the city’s growing economy, as well as the increasing demand for quality office space. Additionally, Bengaluru’s vibrant culture and high quality of life can make it an attractive destination for businesses looking to attract and retain top talent.</p>
         
        `
    },
    {   
        id:8,
        img:blog8,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"22-06-2023",
        conclusion:"💡 Conclusion As businesses navigate the future of commercial retail space, it is crucial to stay informed about the latest trends and insights. Embracing omnichannel retailing, creating experiential environments, incorporating sustainable practices, leveraging data and analytics, adopting flexible store designs, and integrating AI and automation are key trends shaping the future of commercial retail space. By understanding and implementing these trends, businesses can enhance the customer experience, drive sales, and stay ahead of the competition in an increasingly dynamic and competitive market. </br>💡 By staying attuned to consumer preferences, harnessing the power of technology, and creating unique and engaging retail experiences, businesses can position themselves for success in the evolving retail landscape. It is important to continually adapt and innovate, keeping an eye  on emerging trends and customer demands. </br> 💡 The future of commercial retail space lies in embracing change, leveraging data-driven insights, and providing exceptional customer experiences. By staying agile and proactive, businesses can thrive and secure their place in the exciting and ever-evolving world of commercial retail",
        heading:'The Future of Commercial Retail Space: Trends and Insights for Businesses',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡In today’s rapidly evolving business landscape, understanding the trends and insights shaping the future of commercial retail space is crucial for businesses to stay competitive. The rise of e-commerce, changing consumer behaviors, and technological advancements have significantly impacted the retail industry. In this article, we will explore key trends and insights that businesses should consider when navigating the future of commercial retail space.",
        data:`

         
           <h6 id="font_heading">Trend 1: Embracing Omnichannel Retailing</h6>
           <ul id="list-section">
                <li id="font_description">With the increasing popularity of online shopping, businesses need to adopt an omnichannel retail strategy that seamlessly integrates online and offline experiences.</li>
                <li id="font_description">A survey conducted by <b id="bolder">Fidelitus Corp</b> found that 82% of consumers research products online before making a purchase, highlighting the importance of a strong online presence for retailers.</li>
                <li id="font_description">Integrating technologies like augmented reality (AR) and virtual reality (VR) into the retail experience can provide customers with immersive experiences and bridge the gap between online and offline shopping.</li>
           </ul>

           <h6 id="font_heading">Trend 2: Creating Experiential Retail Environments</h6>
           <ul id="list-section">
                <li id="font_description">To attract and retain customers, businesses are focusing on creating experiential retail environments that go beyond traditional brick and-mortar stores.</li>
                <li id="font_description">Incorporating interactive elements, such as pop-up installations, live demonstrations, and interactive displays, can enhance the shopping experience and drive foot traffic.</li>
                <li id="font_description">Data from Fidelitus Corp reveals that businesses that offer unique in-store experiences see an average increase in sales by 20% compared to traditional retail formats.</li>
           </ul>

           <h6 id="font_heading">Trend 3: Incorporating Sustainable Practices</h6>
           <ul id="list-section">
                <li id="font_description">With growing concerns about climate change and environmental sustainability,
                businesses are increasingly incorporating sustainable practices into their retail spaces.</li>
                <li id="font_description">Using eco-friendly materials, implementing energy-efficient lighting systems, and adopting recycling programs are some ways businesses can showcase their commitment to sustainability.</li>
                <li id="font_description">According to a survey by Fidelitus Corp, 64% of consumers consider sustainability as a factor when making purchasing decisions, indicating the importance of ecofriendly practices for businesses.</li>
           </ul>

           <h6 id="font_heading">Trend 4: Leveraging Data and Analytics</h6>
           <ul id="list-section">
                <li id="font_description">The availability of data and advanced analytics tools provides businesses with valuable insights into consumer behavior and preferences.</li>
                <li id="font_description">By leveraging data, retailers can personalize the shopping experience, optimize inventory management, and make data-driven decisions regarding store layouts and product placements.</li>
                <li id="font_description">Fidelitus Corp’s research indicates that businesses that effectively use data and analytics experience a 15% increase in conversion rates and a 20% reduction in operational costs.</li>
           </ul>

           <h6 id="font_heading">Trend 5: Flexibility and Adaptive Spaces</h6>
           <ul id="list-section">
                <li id="font_description">The future of <b id="bolder">commercial retail space</b> lies in flexibility and adaptability. Businesses need to create spaces that can quickly adapt to changing consumer demands and market trends.</li>
                <li id="font_description">Modular store designs and flexible layouts allow retailers to rearrange their spaces based on seasonal trends, promotional campaigns, or evolving product assortments.</li>
                <li id="font_description">Fidelitus Corp’s survey found that businesses that implement flexible store designs experience a 25% increase in customer satisfaction and a 10% decrease in store renovation costs.</li>
           </ul>

           <h6 id="font_heading">Trend 6: Integration of Artificial Intelligence (AI) and Automation</h6>
           <ul id="list-section">
                <li id="font_description">AI and automation technologies are revolutionizing the retail industry by streamlining processes and enhancing efficiency.</li>
                <li id="font_description">Chatbots and virtual assistants can provide personalized customer support, while automated inventory management systems can optimize stock levels and reduce out-of-stock situations.</li>
                <li id="font_description">Fidelitus Corp’s data shows that businesses that implement AI and automation solutions witness a 30% improvement in customer service response times and a 15% reduction in inventory carrying costs.</li>
           </ul>

           <h6 id="font_heading">Trend 7: Integration of Office Spaces in Mall and Retail Buildings</h6>
           <h6 id="font_description">In line with the sustainability strategy and the evolving needs of businesses, a recent trend in India’s commercial retail space involves leasing and accommodating office spaces within mall and retail buildings. This innovative approach entails dedicating a few top floors of these buildings for office purposes, complete with separate entrances and dedicated spaces.</h6>

           <h6 id="font_heading">Benefits of Office Spaces in Mall and Retail Buildings</h6>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Optimal Space Utilization:</b>Integrating office spaces in mall and retail buildings enables property owners to maximize the usage of available space. By repurposing the top floors, which were traditionally used for storage or utility purposes, these areas can now be transformed into revenue-generating office spaces.</li>
                <li id="font_description"><b id="bolder">Enhanced Convenience: </b>Having office spaces within the same premises as retail establishments offers convenience to employees and visitors. It eliminates the need for commuting between separate locations, saving time and reducing transportation costs.</li>
                <li id="font_description"><b id="bolder">Synergy and Collaboration:</b>The coexistence of office spaces and retail establishments fosters collaboration and synergy between different businesses. It allows for potential partnerships and cross-promotion, benefiting both the retailers and the office occupants.</li>
                <li id="font_description"><b id="bolder">Increased Footfall:</b>Integrating office spaces in malls can boost footfall and overall visitor numbers. Professionals working in these offices may take advantage of the retail offerings during breaks or after work, contributing to increased sales and customer engagement.</li>
           </ul>

           <h6 id="font_heading">Driving Sustainability and Revenue Generation</h6>
           <h6 id="font_description">Integrating office spaces in mall and retail buildings aligns with sustainability goals while providing additional revenue streams. Here’s how this trend contributes to sustainable practices:</h6>

           <ul id="list-section">
                <li id="font_description"><b id="bolder">Reduced Carbon Footprint:</b>By having <b id="bolder">office spaces</b> within retail buildings, professionals can minimize their commuting distances, leading to a reduction in carbon emissions. This aligns with the broader sustainability efforts to combat climate change.</li>
                <li id="font_description"><b id="bolder">Energy Efficiency: </b>Mall and retail buildings often incorporate energy- efficient practices, such as optimized HVAC systems, lighting, and water conservation measures. Extending these practices to the integrated office spaces ensures sustainability and cost savings.</li>
                <li id="font_description"><b id="bolder">Revenue Diversification:</b>Leasing office spaces within mall and retail buildings diversifies revenue streams for property owners. Instead of relying solely on retail rentals, income from office space leases adds stability and resilience to the overall financial performance.</li>
            </ul>


        `
    },
    {   
        id:9,
        img:blog9,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"22-06-2023",
        conclusion:"💡 Investing in the booming warehousing and logistics sector in India presents exciting opportunities for investors. The rapid growth of e-commerce, coupled with infrastructure development and government reforms, has created a favorable business environment. By capitalizing on the demand for modern warehousing facilities, cold chain logistics, and technology integration, investors can unlock the sector’s potential and reap significant returns on investment. However, it is crucial to navigate challenges such as land acquisition, regulatory compliance, and competition. By staying informed, collaborating with industry experts, and leveraging technology, investors can position themselves for success in this thriving sector.",
        heading:'Unlocking Potential: Investing in the Booming Warehousing and Logistics Sector in India',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡 The warehousing and logistics sector in India has experienced remarkable growth in recent years, largely driven by the rapid expansion of e-commerce and the need for efficient supply chain management. This article explores the evolving landscape of warehousing and logistics in India, the key factors driving its growth, and the investment opportunities it presents for savvy investors.",
        data:`

         
           <h6 id="font_heading">The Rise of E-commerce and its Impact on Warehousing:</h6>
           <h6 id="font_description">In the digital age, e-commerce has become an integral part of consumers’ lives, driving the demand for seamless order fulfillment and faster deliveries. As a result, the warehousing and logistics sector has witnessed a significant transformation. E-commerce players require
           strategically located <b id="bolder">warehouses</b> and fulfillment centers to ensure quick order processing and last-mile delivery. This surge in demand has opened up lucrative opportunities for investors to capitalize on the growing need for modern warehousing facilities in key locations across India.</h6>
        

           <h6 id="font_heading">Infrastructure Development and Connectivity:</h6>
           <h6 id="font_description">The development of robust infrastructure is crucial for the growth of the warehousing and logistics sector. Over the years, the Indian government has made substantial investments in transportation networks, highways, and logistics parks, resulting in improved connectivity between major cities, ports, and manufacturing hubs. This has significantly reduced transportation costs and delivery time, making India an attractive destination for warehousing and logistics investments. The introduction of dedicated freight corridors and integrated logistics parks has further enhanced connectivity, opening up new avenues for efficient supply chain management. </h6>
        
           <h6 id="font_heading">Government Initiatives and Reforms:</h6>
           <h6 id="font_description">The Indian government has implemented several initiatives and reforms to promote ease of doing business and attract investments in the warehousing and logistics sector. The implementation of the Goods and Services Tax (GST) has streamlined the taxation system, reducing complexity and ensuring uniformity across states. This has resulted in a more efficient supply chain and simplified logistics operations. The introduction of the National Logistics Policy has further emphasized the government’s commitment to improving logistics infrastructure and creating a favorable business environment for investors.</h6>
        
           <h6 id="font_heading">Investment Opportunities in Warehousing and Logistics:</h6>
           <h6 id="font_description">The booming <b >warehousing and logistics</b> sector in India offers a plethora of investment opportunities. Some key avenues include:</h6>
        
         
           <ul id="list-section">
                <li id="font_description"><b>Developing Warehousing Parks: </b>Investing in the development of ware housing parks equipped with modern amenities and advanced technologies presents significant potential for high returns. These parks cater to the storage and distribution needs of various industries, including e-commerce, FMCG, and automotive sectors.</li>
                <li id="font_description"><b id="bolder">Cold Chain Logistics:</b> India’s rising middle class and increasing focus on quality and safety have created a surge in demand for cold chain logistics. Investing in temperature controlled storage facilities and refrigerated transportation can be a lucrative opportunity, particularly for perishable goods like food and pharmaceuticals.</li>
                <li id="font_description"><b id="bolder">Collaborating with Logistics Service Providers:</b> Partnering with established logistics service providers can be a strategic investment option. These collaborations offer access to an existing network, expertise in supply chain management, and the ability to provide end-to-end logistics solutions.</li>
                <li id="font_description"><b id="bolder">Technology and Innovation in Supply Chain Management:</b> The integration of technology and innovation has revolutionized supply chain management in the warehousing and logistics sector. Automation, robotics, data analytics, and AI-powered systems have enhanced operational efficiency, reduced costs, and improved inventory management. Investing in technology-based solutions can provide a competitive edge, optimize warehouse operations, and drive productivity and profitability.</li>
           </ul>



           <h6 id="font_heading">Key Challenges and Risk Mitigation:</h6>
           <h6 id="font_description">While the warehousing and logistics sector in India presents significant opportunities, investors must be aware of and address certain challenges:</h6>
           <ul id="list-section">
                <li id="font_description"><b>Land Acquisition: </b>Securing suitable land for warehousing facilities can be a complex process due to various factors such as land availability, legal requirements, and local regulations. Conducting thorough due diligence and partnering with experienced real estate developers and service providers like <b id="bolder"> Fidelitus Corp </b> can help mitigate these challenges.</li>
                <li id="font_description"><b>Regulatory Hurdles:</b>Navigating the regulatory landscape in India requires careful understanding and compliance with various laws, permits, and licenses. Engaging legal and regulatory experts can help investors ensure compliance and minimize risks associated with regulatory hurdles.</li>
                <li id="font_description"><b>Competition: </b>The warehousing and logistics sector in India is witnessing increasing competition. To stay ahead, investors need to differentiate themselves through innovative solutions, efficient operations, and superior customer service.</li>
           </ul>

           <h6 id="font_heading">Trend 3: Incorporating Sustainable Practices</h6>
           <ul id="list-section">
                <li id="font_description">With growing concerns about climate change and environmental sustainability,
                businesses are increasingly incorporating sustainable practices into their retail spaces.</li>
                <li id="font_description">Using eco-friendly materials, implementing energy-efficient lighting systems, and adopting recycling programs are some ways businesses can showcase their commitment to sustainability.</li>
                <li id="font_description">According to a survey by Fidelitus Corp, 64% of consumers consider sustainability as a factor when making purchasing decisions, indicating the importance of ecofriendly practices for businesses.</li>
           </ul>
        `
    },
    {   
        id:10,
        img:blog10,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"06-07-2023",
        conclusion:"💡 In conclusion, the rise of AI is significantly reshaping data centers and, by extension, the commercial property industry. By understanding these changes, businesses and investors can leverage the opportunities presented in this evolving landscape. As technology, property, and business intersect in new ways, the future looks bright for those ready to embrace the wave of this exciting evolution.",
        heading:'Data Centers of the Future: The Role of AI in Shaping Data Infrastructure',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡 The advent of artificial intelligence (AI) is shaking up the commercial property industry. Data centers, once static repositories of information, are now evolving into dynamic, AI-driven hubs. This paradigm shift has far-reaching implications for businesses and investors alike.",
        data:`

         
           <h6 id="font_heading">Introduction to the Changing Landscape of Data Centers</h6>
           <h6 id="font_description">Data centers have transformed significantly over the years. Initially used for data storage and networking, they are now expected to support advanced AI applications that require massive computing power, vast storage capacities, and quick data transmission rates. This transformation from conventional data centers to AI powered hubs is influencing their roles, designs, and operations.</h6>
        

           <h6 id="font_heading">Understanding AI’s Infrastructure Demands</h6>
           <h6 id="font_description">AI applications pose unique and demanding infrastructure requirements that necessitate a rethinking of traditional data center designs.</h6>
        
          
           <ul id="list-section">
                <li id="font_description"><b>Computing Hardware: </b>The sophisticated computations involved in AI applications require specialized, powerful processors. GPUs and TPUs, specifically designed for AI workloads, have revolutionized the types of hardware used within data centers. These are critical for tasks like training complex neural networks or processing massive datasets.</li>
                <li id="font_description"><b id="bolder">High-speed Connectivity: </b> The vast amounts of data utilized by AI models, especially in real-time applications, necessitate ultra-fast data transmission speeds. High-bandwidth network connectivity within and between data centers is vital to prevent bottlenecks and ensure the seamless operation of AI applications.</li>
                <li id="font_description"><b id="bolder">Power Supply and Cooling: </b> AI computations are power-intensive and produce significant heat, demanding robust power supplies and efficient cooling systems. The higher energy demands compared to traditional IT workloads have led to a focus on more efficient power supplies, energy-efficient server designs, and advanced cooling solutions.</li>
                <li id="font_description"><b id="bolder">Cloud Infrastructure and Data Storage:</b> The large volumes of data used in AI applications call for high-capacity storage systems and a robust cloud infrastructure. The ability to store and quickly retrieve data is crucial to training AI models and executing AI tasks.</li>
           </ul>



           <h6 id="font_heading">Growth of Colocation, Hyperscale, and Edge Data Centers</h6>
           <h6 id="font_description">The demanding needs of AI have led to a rise in different types of data centers, each catering to specific aspects of AI infrastructure:</h6>
           <ul id="list-section">
                <li id="font_description"><b>Colocation Data Centers:  </b>These facilities allow businesses to rent space for their servers and other hardware. By sharing infrastructure and services, businesses can benefit from cost savings, increased security, and operational efficiency. The global colocation data center market is set to grow substantially in the coming years, with many businesses seeking to outsource their data center needs to cut costs and improve scalability.</li>
                <li id="font_description"><b>Hyperscale Data Centers: </b>These mega facilities are designed to provide extensive computing power and vast storage capacities, making them perfect for the high demands of AI. Tech giants like Amazon, Microsoft, and Google continue to invest heavily in these facilities to support their cloud and AI operations. Hyperscale data centers are expected to dominate the market, given their scalability, efficiency, and ability to handle massive workloads.</li>
                <li id="font_description"><b>Edge Data Centers: </b>These small, geographically distributed data centers bring processing power closer to the source of data generation, reducing latency and improving speed—critical factors for many AI applications. As the Internet of Things (IoT) and AI applications proliferate, the edge data center market is set to grow at a phenomenal rate.</li>
           </ul>

           <h6 id="font_heading">Trend 3: Incorporating Sustainable Practices</h6>
           <ul id="list-section">
                <li id="font_description">With growing concerns about climate change and environmental sustainability,
                businesses are increasingly incorporating sustainable practices into their retail spaces.</li>
                <li id="font_description">Using eco-friendly materials, implementing energy-efficient lighting systems, and adopting recycling programs are some ways businesses can showcase their commitment to sustainability.</li>
                <li id="font_description">According to a survey by Fidelitus Corp, 64% of consumers consider sustainability as a factor when making purchasing decisions, indicating the importance of ecofriendly practices for businesses.</li>
           </ul>

           <h6 id="font_heading">Impact of AI on Data Center Locations</h6>
           <h6 id="font_description">The energy and resource requirements of AI are influencing the location of new data centers. Factors that affect these decisions include:</h6>
           <ul id="list-section">
                <li id="font_description"><b>Energy Prices and Availability:  </b>The high energy demands of AI applications make regions with low energy costs or abundant renewable energy attractive for data center locations. For instance, regions like Scandinavia, which have lower energy costs and an abundance of renewable energy sources, have become popular locations for <b id="bolder">data centers</b>.</li>
                <li id="font_description"><b>Land Costs: </b>Given their size, data centers require vast tracts of land. Thus, land prices are a crucial factor in site selection. Regions where large parcels of land are available at affordable prices have seen a surge in data center developments.</li>
                <li id="font_description"><b>Regulations: </b>Local regulations regarding energy consumption, data privacy, and environmental impact significantly affect data center location decisions. Areas with favorable regulatory climates, like certain states in the U.S. or countries in the European Union, are often preferred.</li>
           </ul>

           <h6 id="font_heading">New Design Requirements for AI Data Centers</h6>
           <h6 id="font_description">As the demand for AI grows, data centers are evolving to accommodate the unique needs of AI applications. For instance, Meta, formerly known as Facebook, paused its data center expansion plans to reconsider infrastructure designs to better support AI workloads.</h6>
           <ul id="list-section">
                <li id="font_description"><b>Advanced Cooling Solutions:  </b>High performance processors for AI applications generate significant heat, requiring innovative cooling solutions. Advanced liquid cooling, which uses liquid rather than air to cool servers, is becoming more commonplace due to its efficiency and ability to handle the heat produced by intense AI computations.</li>
                <li id="font_description"><b>Scalability: </b>AI workloads are expected to increase, requiring data centers to be highly scalable. This means infrastructure components, such as server racks, storage systems, and networking equipment, must be designed to allow easy expansion.</li>
           </ul>

           <h6 id="font_heading">The Role of Commercial Property Service Providers</h6>
           <h6 id="font_description">In this shifting landscape, commercial property service providers like <b id="bolder">Fidelitus Corp</b> are pivotal. We assist businesses in finding suitable locations for data centers, navigate the complex regulatory landscape, and help understand the new infrastructure demands of AI. For investors, these service providers offer insights into potential returns from investments in data centers, aiding in risk assessment and decision-making.</h6>
           <h6 id="font_description"><b id="bolder">Looking Ahead: Future Trends for AI and Data Centers</b> Several trends could further shape the future of AI and data centers:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b>Continued Growth of Hyperscale and Edge Data Centers:  </b>The proliferation of AI applications will continue to drive demand for hyperscale and edge data centers, providing more computing power and reducing latency.</li>
                <li id="font_description"><b>Emergence of AI-Optimized Hardware and Infrastructure: </b>he need for faster, more efficient AI computations will spur further innovations in AI-optimized processors, storage systems, and networking technology.</li>
                <li id="font_description"><b>Stricter Energy and Data Regulations: </b>Growing environmental concerns and the increasing importance of data privacy are likely to result in more stringent regulations, which could significantly affect data center operations and locations.</li>
                <li id="font_description"><b>Increased Use of Renewable Energy: </b>To meet high power demands and reduce environmental impacts, data centers will likely shift towards renewable energy sources.</li>
                <li id="font_description"><b>Advanced Cooling Techniques: </b>The heat generated by AI computations will push the development of more efficient cooling techniques, possibly leading to widespread adoption of techniques like immersion cooling.</li>
           </ul>


        `
    },
    {   
        id:11,
        img:blog11,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"12-05-2023",
        conclusion:"💡 The e-commerce industry in India is growing at an unprecedented rate, and this is driving demand for industrial warehousing space. With more consumers turning to online shopping, e-commerce companies require large, efficient warehouses to manage their inventory and fulfill their orders. This presents a significant opportunity for industrial warehousing providers to develop specialized facilities and cater to the unique needs of the e-commerce industry. </br> At <b id='bolder'>Fidelitus Corp<b>, we recognize the unique challenges and opportunities presented by the e-commerce industry and are committed to providing specialized industrial warehousing solutions that meet the needs of our clients. We believe that the growth of the e-commerce industry in India represents a significant opportunity for industrial warehousing providers, and we are excited to be at the forefront of this dynamic and rapidly evolving industry. </br> As the industrial warehousing sector in India continues to grow, there is an increasing need for sustainable practices and innovative solutions. By investing in technologies such as automation and robotics, and adopting sustainable practices, industrial warehousing providers can not only meet the needs of the e-commerce industry but also contribute to a more sustainable and efficient supply chain.",
        heading:'The Booming E-Commerce Industry and Its Impact on Industrial Warehousing Demand in India',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡 The e-commerce industry in India has grown exponentially in the past few years, and the trend is set to continue. With more and more consumers turning to online shopping, the demand for industrial warehousing has seen a corresponding increase. In this article, we will explore the ways in which e-commerce is driving industrial warehousing demand in India and the unique challenges and opportunities it presents.",
        data:`

         
           <h6 id="font_heading">The Rise of E-Commerce in India</h6>
           <h6 id="font_description">India has been experiencing rapid growth in e-commerce over the past decade. According to a report by the Internet and Mobile Association of India (IAMAI), the number of online shoppers in India is expected to reach 350 million by the end of 2025. The COVID-19 pandemic has also accelerated the shift to online shopping, with many consumers avoiding physical stores altogether.</h6>
           <h6 id="font_description">This growth in e-commerce has resulted in a corresponding increase in demand for industrial warehousing. As online retailers seek to streamline their operations and improve their delivery times, they require large, efficient warehouses to store and manage their inventory.</h6>
        
           <h6 id="font_heading">Efficient Last-Mile Delivery Solutions</h6>
           <h6 id="font_description">One of the major challenges faced by the <b id="bolder">e-commerce industry</b> is the need for efficient last-mile delivery solutions. This is particularly challenging in India, where the country’s vast geography and complex road networks can make delivery times unpredictable. To address this issue, e-commerce companies are investing in last-mile delivery solutions such as fulfillment centers and delivery hubs that are strategically located near urban centers.</h6>
           <h6 id="font_description">These solutions require additional industrial warehousing space to accommodate the increased volume of inventory and orders. This has created a unique opportunity for industrial warehousing providers to develop specialized facilities that cater specifically to the e commerce industry.</h6>
        
           <h6 id="font_heading">Unique Storage Requirements of E-Commerce Inventory</h6>
           <h6 id="font_description">E-commerce inventory is different from traditional retail inventory in that it requires specialized storage and handling. Many ecommerce products are smaller and lighter, which means they can be packed more densely into warehouses. This requires specialized helving and racking systems that can accommodate the unique dimensions of e-commerce inventory.</h6>
           <h6 id="font_description">Additionally, e-commerce inventory is often subject to rapid turnover, with products entering and leaving the warehouse at a much faster rate than in traditional retail. This requires warehouses to have efficient inventory management systems in place to ensure that orders are fulfilled accurately and on time.</h6>
        
           <h6 id="font_description"><b id="bolder">Fidelitus Corp Founder & Managing Director - Achuth Gowda </b> on the Importance of E-Commerce in Driving Industrial Warehousing Demand in India:</h6>
           <h6 id="font_description">"The e-commerce industry is driving demand for industrial warehousing space in India, as companies seek to expand their operations and improve their last-mile delivery capabilities. At Fidelitus Corp, we recognize the unique challenges and opportunities presented by the ecommerce industry, and we are committed to providing specialized industrial warehousing solutions that cater specifically to the needs of e commerce clients.</h6>
           <h6 id="font_description">We believe that the growth of the e-commerce industry in India represents a significant opportunity for industrial warehousing providers, and we are excited to be at the forefront of this dynamic and rapidly evolving industry."</h6>
           
           <h6 id="font_heading">Sustainable Warehousing Practices</h6>
           <h6 id="font_description">As the industrial warehousing sector in India continues to grow, there is an increasing need for sustainable practices. This includes measures such as energy efficient lighting, water saving systems, and the use of renewable energy sources. These initiatives not only reduce costs and improve efficiency but also help to reduce the carbon footprint of the warehousing industry.</h6>

           <h6 id="font_heading">Innovative Warehousing Solutions</h6>
           <h6 id="font_description">The rapid growth of e commerce in India has also led to the development of innovative warehousing solutions. One example is the use of automation and robotics in warehouse operations. These technologies can help to improve efficiency, accuracy, and safety while reducing costs and minimizing the need for human intervention.</h6>
           <h6 id="font_description">Another example is the use of smart tracking technology, which enables real-time monitoring of inventory and delivery routes. This helps to optimize warehouse operations and streamline supply chains, reducing delivery times and improving customer satisfaction.</h6>

           <h6 id="font_heading">Challenges and Opportunities for Industrial Warehousing Providers</h6>
           <h6 id="font_description">The growth of the e-commerce industry in India presents both challenges and opportunities for industrial warehousing providers. On the one hand, there is significant demand for <b id="bolder">industrial warehousing space</b>, which provides a unique opportunity for providers to expand their operations and capture market share.</h6>
           <h6 id="font_description">On the other hand, the e-commerce industry requires specialized facilities and expertise, which may be outside the realm of traditional industrial warehousing providers. This requires providers to invest in specialized facilities, technologies, and workforce training to meet the unique needs of the e-commerce industry.</h6>


           
        `
    },
    {   
        id:12,
        img:blog12,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"15-09-2023",
        conclusion:"💡 Mastering commercial real estate leasing requires a comprehensive understanding of the process’s various aspects. By working with a trusted commercial property services provider like Fidelitus Corp, property owners and tenants can benefit from expertise, insights, and support to navigate the complexities of leasing transactions. From understanding client requirements to evaluating market rates, negotiating favorable terms, conducting due diligence, and crafting comprehensive lease agreements, Fidelitus Corp offers a holistic approach to commercial realestate leasing. By leveraging their guidance and knowledge, clients can make well-informed decisions, negotiate favorable lease terms, and ensure compliance with regulations. With Fidelitus Corp as your trusted partner, you can confidently navigate the leasing process and unlock the full potential of your commercial real estate investments.",
        heading:'Mastering Commercial Real Estate Leasing: A Comprehensive Guide for Property Owners and Tenants',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡 Commercial real estate leasing is a complex process that requires a deep understanding of various aspects. Whether you are a property owner seeking tenants or a prospective tenant in search of the perfect space, having a solid grasp of leasing agreements, negotiation strategies, market evaluation, lease renewal tactics, and due diligence is crucial for success. In this comprehensive guide, we will delve into each facet of commercial real estate leasing, equipping you with the knowledge and insights needed to make informed decisions and maximize the value of your commercial property investments.",
        data:`

         
           <h6 id="font_heading">Working with a Trusted Commercial Property Services Provider Partnering with a reputable commercial property services provider like <b id="bolder" >Fidelitus Corp</b> offers numerous advantages throughout the leasing process. Let’s explore the key benefits:</h6>
           
           <h6 id="font_heading">Stability and Secure Operations:</h6>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Expertise and Industry Knowledge:</b> Fidelitus Corp brings decades of experience in the commercial property services industry. Their team of professionals possesses in-depth knowledge of market trends, legal requirements, and industry best practices. With their guidance, clients receive expert advice and guidance throughout the leasing process.</li>
                <li id="font_description"><b id="bolder">Access to a Broad Network: </b>Fidelitus Corp has a vast network of property owners, tenants, and industry contacts. This network provides valuable opportunities for connecting property owners with potential tenants and helps tenants find the ideal commercial space that meets their requirements.</li>
                <li id="font_description"><b id="bolder">Streamlined Processes:</b> Fidelitus Corp streamlines the leasing process by handling essential tasks such as lease document preparation, property marketing, tenant screening, and lease administration. Their efficient processes save time and reduce administrative burden for clients.</li>
                <li id="font_description"><b id="bolder">Mitigating Risks:</b> With Fidelitus Corp’s assistance, clients can navigate potential risks and pitfalls associated with leasing transactions. Their due diligence practices, comprehensive property inspections, and adherence to legal and regulatory requirements ensure a secure and compliant leasing process.</li>
                <li id="font_description"><b id="bolder">Personalized Solutions: </b> Fidelitus Corp understands that every client’s leasing needs are unique. They offer personalized solutions tailored to each client’s specific requirements, goals, and budget. Their collaborative approach ensures that clients receive customized strategies and recommendations.</li>
           </ul>

           <h6 id="font_heading">Understanding the Client’s Requirements</h6>
           <h6 id="font_description">A crucial step in the leasing process is understanding the client’s specific requirements. Fidelitus Corp takes the time to listen and understand the client’s needs to shortlist properties that align with their preferences. Factors such as cost, convenience, amenities, and suitability are thoroughly evaluated, providing clients with multiple options to review before making a decision.</h6>
          
           <h6 id="font_heading">Evaluating Market Rates </h6>
           <h6 id="font_description">Accurate market rate evaluation is essential for fair lease agreements. To help you make informed decisions, consider the following:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Market Research:</b> Gather and analyze market data, including comparable property rents, vacancy rates, and recent transactions. This research will enable you to determine competitive rental rates and make appropriate pricing decisions.</li>
                <li id="font_description"><b id="bolder">Factors Influencing Commercial Property Values: </b>Location, market trends, proximity to amenities, building condition, and infrastructure play a significant role in assessing property value. Understanding these factors will help you accurately evaluate market rates.</li>
                <li id="font_description"><b id="bolder">Using Market Data to Negotiate:</b> Leverage your market research findings during negotiation to strengthen your position and secure fair lease terms.</li>
           </ul>


           <h6 id="font_heading">Negotiating Favorable Terms </h6>
           <h6 id="font_description">Successful lease negotiations require careful planning and effective communication. Consider the following strategies:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Pre-Negotiation Preparation:</b> Define your needs, conduct market research, and set negotiation goals before entering discussions. This preparation will enable you to present your requirements effectively and negotiate from a position of strength.</li>
                <li id="font_description"><b id="bolder">Effective Negotiation Techniques: </b>Explore various negotiation strategies, such as anchoring, concessions, trade-offs, and win-win solutions. Employing these techniques can lead to mutually beneficial outcomes.</li>
                <li id="font_description"><b id="bolder">Lease Concessions and Financial Considerations: </b> Understand common lease concessions and financial factors involved, such as rental rates, security deposits, and lease warranties. Carefully consider these aspects to ensure a favorable lease agreement.</li>
           </ul>


           <h6 id="font_heading">The Importance of Due Diligence </h6>
           <h6 id="font_description">Conducting thorough due diligence is vital to identify potential risks and obligations associated with the lease. Consider the following:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Property Inspections and Assessments:</b> Comprehensive property inspections help identify any physical defects, code violations, or maintenance issues that may impact tenant occupancy. Assess the property’s location, transportation accessibility, compliance with construction by-laws, and overall suitability.</li>
                <li id="font_description"><b id="bolder">Compliance with Regulations: </b>Verify compliance with building codes, zoning regulations, ADA accessibility requirements, and environmental standards. Non-compliance can pose risks and affect the viability of the lease.</li>
                <li id="font_description"><b id="bolder">Financial Stability and Reputation: </b> Research the financial stability and reputation of the landlord or tenant to ensure a stable and trustworthy leasing relationship.</li>
           </ul>

           <h6 id="font_heading">Understanding Lease Agreements </h6>
           <h6 id="font_description">Lease agreements form the foundation of any commercial real estate lease. Pay attention to the following key aspects:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Tenure of the Lease:</b> Capture the intended duration of the tenant’s occupancy in the lease agreement.</li>
                <li id="font_description"><b id="bolder">Rentals Agreed: </b> Clearly outline the agreed-upon rental rates and any associated escalations.</li>
                <li id="font_description"><b id="bolder">Security Deposit: </b> Specify the interest-free refundable security deposit to be paid by the tenant, including conditions for refund and deductions.</li>
                <li id="font_description"><b id="bolder">Lock-in Period: </b> Include a clause that binds both parties to their responsibilities for a specific period, often with more significance for the tenant.</li>
                <li id="font_description"><b id="bolder">Notice Period: </b> Define the agreed-upon timeframe for vacating and returning the premises.</li>
                <li id="font_description"><b id="bolder">Other Clauses: </b> Address additional clauses covering force majeure events, severability, waiver, attornment, dispute resolution, and other pertinent details.</li>
           </ul>

           <h6 id="font_description">Clear and concise language in lease agreements is vital to prevent misunderstandings and potential disputes.</h6>
           
           <h6 id="font_heading"> Lease Renewal Strategies  </h6>
           <h6 id="font_description">Lease renewal provides an opportunity to reassess lease terms and adapt to changing needs. Consider the following strategies:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Timely Renewal Discussions:</b>  Initiate lease renewal discussions well in advance of the lease expiration date to allow ample time for negotiation and planning.</li>
                <li id="font_description"><b id="bolder">Assessing Market Conditions: </b>Evaluate market conditions, rental trends, and demand-supply dynamics to gauge the feasibility of lease renegotiation.</li>
                <li id="font_description"><b id="bolder">Enhancing Property Appeal: </b> Identify potential property upgrades, modifications, or tenant amenities that can improve leasing prospects during the renewal process.</li>
           </ul>

           
        `
    },
    {   
        id:13,
        img:blog13,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"15-09-2023",
        conclusion:"",
        heading:'Leveling Up Your Hiring Game: How Gamification is Transforming Talent Evaluation',
        short_description:'In today’s rapidly changing world, the need for sustainable practices in property management has become paramount. Optimizing energy efficiency',
        published:"💡 Imagine a top executive vying for a coveted position at a cutting-edge company. Instead of enduring the customary grilling of interviews and mundane assessments, they find themselves in a virtual realm, solving puzzles, collaborating with virtual teammates, and navigating immersive scenarios. Welcome to the world of gamified assessments, where hiring is a thrilling adventure.",
        data:`

         
           <h6 id="font_heading">The Power of Play:</h6>
           <h6 id="font_description">In a world where video games have captured the hearts of millions, it’s no surprise that playfulness is infiltrating the job market. Gamification, the art of applying game elements to non-game contexts, has entered the arena of talent evaluation. It’s changing the rules and redefining how organizations identify and onboard top talent.</h6>
           
           <h6 id="font_heading">Why Gamify Assessments?</h6>
           <h6 id="font_description">Think back to the last time you attended a traditional job interview, enduring hours of answering questions and solving hypothetical problems. It’s often a one-sided experience, leaving candidates feeling like they’re on a stage under a spotlight. Gamified. assessments flip the script. They engage candidates, transforming a tedious process into an exciting journey.</h6>
           <h6 id="font_description">Gamification doesn’t just make the hiring process more enjoyable; it also offers several advantages. It measures a candidate’s skills and abilities in a real-world context, revealing their problem-solving prowess, adaptability, and creativity. Moreover, it creates memorable candidate experiences, ensuring your organization stands out.</h6>
           

           <h6 id="font_heading">To reiterate, Here are some strong reasons for gamified assessments:</h6>
           <ul id="list-section">
                <li id="font_description">Gamified assessments provide a more engaging and immersive experience for candidates, which can help attract top talent to your organization.</li>
                <li id="font_description">They can help identify candidates who may not have the traditional qualifications or experience but possess the skills and traits needed for the job.</li>
                <li id="font_description">Gamified assessments can provide a more objective and unbiased evaluation of candidates compared to traditional assessments, as they focus on performance in real-world scenarios rather than subjective opinions.</li>
                <li id="font_description">Gamified assessments can provide valuable insights into a candidate’s soft skills, such as problem- solving, teamwork, and creativity, which are often difficult to assess in traditional assessments.</li>
                <li id="font_description">They create a positive candidate experience, which can enhance your brand reputation and improve your chances of attracting and retaining top talent.</li>
                <li id="font_description">Gamified assessments allow for customization and personalization, providing a
                tailored experience for each candidate and catering to their strengths and
                weaknesses.</li>
           </ul>

           <h6 id="font_heading">Game On: How It Works:</h6>
           <h6 id="font_description">But how does this playful approach to talent evaluation actually work? Gamified assessments employ elements such as points, levels, badges, and leaderboards to create a game-like experience. Candidates are presented with challenges that mirror the actual tasks they’ll face on the job. Whether it’s coding challenges for software developers or strategic decision-making for managers, the assessments immerse candidates in scenarios that truly matter.</h6>
           <h6 id="font_description">Imagine a software developer facing a coding challenge where they have to debug a virtual application. As they work through the problem, they earn points for solving issues and completing tasks. The system records their decisions, speed, and accuracy, providing valuable insights into their problem-solving skills.</h6>
          
           <h6 id="font_heading">Real-World Success Stories: </h6>
           <h6 id="font_description">Some of the world’s leading organizations have already embraced gamified assessments with resounding success. For instance, Formapost, a French postal service, was grappling with high employee turnover rates, especially among new hires. Approximately 25% of new employees left the company, signaling potential issues in the hiring process. To address this challenge, Formapost launched the “Jeu Facteur Academy.</h6>
           <h6 id="font_description">“The “Jeu Facteur Academy” allowed potential candidates to experience a virtual week in the life of a new postal carrier. This gamified experience included waking up early in the morning, learning about the job, and understanding the ethical aspects of the role. It served as a way to manage candidates’ expectations and provide a realistic preview of the job. The result? Increased retention and a more engaged workforce.</h6>
           <h6 id="font_description">Similarly the renowned hotel chain created a game called “My Marriott Hotel,” which allows potential candidates to experience running a virtual hotel. This game helps identify high-potential candidates for roles within the company. It provides a unique and engaging way to assess candidates’ skills and aptitude for hospitality roles.</h6>
          
           <h6 id="font_heading">Building a Gamified Assessment: Tips and Strategies:</h6>
           <h6 id="font_description">For HR professionals interested in adopting gamification, here are some tips and strategies:</h6>

           <ul id="list-section">
                <li id="font_description"><b id="bolder">Align with Job Requirements: </b> Ensure that game elements in assessments align with the specific job requirements and skills you’re looking for. Tailor challenges to reflect real-world scenarios.</li>
                <li id="font_description"><b id="bolder">Fairness and Inclusivity: </b>Design assessments that are fair and inclusive to all candidates. Avoid biases and ensure that the game does not favor certain demographics or backgrounds.</li>
                <li id="font_description"><b id="bolder">Feedback and Transparency:</b>Provide candidates with feedback on their performance within the game. Transparency helps them understand where they excelled and where they can improve.</li>
           </ul>

           <h6 id="font_heading">Beyond the Score: What Gamified Assessments Reveal:</h6>
           <h6 id="font_description">Traditional assessments often provide a one-dimensional view of a candidate’s abilities. They rely heavily on qualifications and question-and-answer sessions. In contrast, gamified assessments reveal a multidimensional picture. They showcase not only a candidate’s skills but also their ability to think on their feet, collaborate, and adapt—an essential set of skills in today’s rapidly changing work environment. For instance, a candidate who excels in a gamified assessment for a managerial position may not only demonstrate excellent decision-making skills but also their ability to motivate and lead a team during a challenging virtual project. These insights are invaluable in making well-informed hiring decisions. </h6>

           <h6 id="font_heading">Challenges and Considerations: </h6>
           <h6 id="font_description">While the benefits of gamification in assessments are evident, it’s not without challenges. Implementing gamified assessments requires a robust technological infrastructure. Additionally, there’s the risk of candidates trying to ‘game’ the system, focusing on winning rather than showcasing their true skills. However, these challenges can be mitigated through careful planning and the use of appropriate assessment tools.</h6>
          
           <h6 id="font_heading">The Future of Talent Evaluation: </h6>
           <h6 id="font_description">The world of gamified assessments continues to evolve. Virtual reality assessments are on the horizon, offering even more immersive experiences. Artificial intelligence is making assessments smarter, providing real-time feedback and adaptability to candidates’ responses. These innovations promise to take talent evaluation to the next level.</h6>
          
           <h6 id="font_heading">Conclusion: Level Up Your Hiring Process: </h6>

           <h6 id="font_description">In the world of talent acquisition, gamification is more than just a trend; it’s a revolution. It transforms the hiring process from a tedious chore into an exciting adventure. It engages candidates, provides valuable insights, and creates memorable experiences.</h6>
           <h6 id="font_description">So, as you embark on your hiring journey, consider incorporating gamification into your assessments. It’s not just about finding the right candidate; it’s about finding the right candidate while making the process enjoyable and insightful for everyone involved. Level up your hiring game, and watch your organization thrive.</h6>

           <h6 id="font_heading">Real-world examples of gamified assessments include: </h6>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Pymetrics:</b> A platform that uses neuroscience games to evaluate cognitive and emotional traits, helping companies match candidates to jobs.</li>
                <li id="font_description"><b id="bolder">Knack:</b> A suite of games that measure cognitive, personality, and social traits to identify high-potential candidates.</li>
                <li id="font_description"><b id="bolder">Arctic Shores:</b>A game-based assessment platform that measures cognitive abilities, personality traits, and job-related skills to predict job performance.</li>
                <li id="font_description"><b id="bolder">Virgin Media:</b> The company created a gamified assessment that challenged candidates to navigate a virtual maze, testing their problem-solving and strategic thinking skills.</li>
                <li id="font_description"><b id="bolder">Marriott:</b>The hotel chain created a game called “My Marriott Hotel,” which allows potential candidates to experience running a virtual hotel, helping to identify high potential candidates for roles in the company.</li>
           </ul>


          
           
        `
    },
    {   
        id:14,
        img:blog14,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"11 min Read",
        category:"Office Space",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"15-09-2023",
        conclusion:"",
        heading:'An Analysis of the North KIADB Aerospace Park: The Ideal Workspace Solution for Your Business',
        short_description:'Over the past decade, Bangalore has emerged as a leading business hub in India, attracting businesses from around the world.',
        published:"",
        data:`

         
           <h6 id="font_heading">The Rise of the North KIADB Aerospace Park</h6>
           <h6 id="font_description">Over the past decade, Bangalore has emerged as a leading business hub in India, attracting businesses from around the world. One of the key factors driving this growth is the <b id="bolder">North KIADB Aerospace Park</b>, a hub for businesses across a wide range of industries. With its strategic location, international quality amenities, and sustainable workspace design, the North KIADB Aerospace Park has become the ideal workspace solution for businesses looking to elevate their workspace experience.</h6>
           
           <h6 id="font_heading">A Strategic Location for Business Growth</h6>
           <h6 id="font_description">Located in the heart of Bangalore, the North KIADB Aerospace Park provides businesses with strategic location advantages. With easy access to major transport hubs such as the Bangalore International Airport (BIAL) and major highways, businesses can connect with clients and employees from around the world. Additionally, the park is located near major business districts such as Whitefield, Manyata Tech Park, and Electronic City, making it the perfect choice for businesses looking to expand their reach.</h6>
           
           <h6 id="font_heading">Sustainable Workspace Design</h6>
           <h6 id="font_description">The North KIADB Aerospace Park is committed to sustainability, with a focus on eco-friendly building features and efficient use of space. The park offers a smart building design, rainwater harvesting, waste segregation, and solar power, making it a sustainable workspace option for modern businesses. Additionally, the park features more open and green spaces, promoting a healthy and productive work environment.</h6>
           
           <h6 id="font_heading">Customizable Workspaces for Your Business Needs</h6>
           <h6 id="font_description">At the North KIADB Aerospace Park, businesses can create the perfect environment to meet their unique needs. With customizable workspaces, businesses can choose from a variety of available spaces, including office space, industrial space, commercial space, and warehouses. This allows businesses to optimize their workspace and create the ideal environment for their team.</h6>
           
           <h6 id="font_heading">Access to International Quality Amenities</h6>
           <h6 id="font_description">The North KIADB Aerospace Park offers access to international quality amenities, including high-speed internet, conference rooms, ample parking, and more. These amenities provide everything businesses need to thrive in a modern workspace environment. The park also features a dedicated lounge area, gym, cafeteria, and rooftop garden, providing employees with the opportunity to relax and recharge during the workday.</h6>
           
           <h6 id="font_heading">A Hub for Innovation and Collaboration</h6>
           <h6 id="font_description">The North KIADB Aerospace Park is a hub for innovation and collaboration, providing businesses with the opportunity to network and connect with other like-minded professionals. With access to a community of diverse industries, businesses can find inspiration and opportunities to collaborate and grow.</h6>
           
           <h6 id="font_heading">Nearby Business Hubs</h6>
           <h6 id="font_description">In addition to its strategic location, the North KIADB Aerospace Park is also located near major business hubs such as Whitefield, Manyata Tech Park, Electronic City, and the Bangalore International Airport (BIAL). These nearby areas offer businesses additional opportunities for growth and networking.</h6>
           
           <h6 id="font_heading">The Ideal Workspace Solution</h6>
           <h6 id="font_description">In summary, the North KIADB Aerospace Park provides businesses with the ideal workspace solution, offering a strategic location for business growth, sustainable workspace design, customizable workspaces, access to international quality amenities, and a hub for innovation and collaboration. Whether businesses need office space, industrial space, <b id="bolder">commercial space</b>, or warehouses, the North KIADB Aerospace Park has everything they need to thrive in a modern workspace environment.</h6>
           
           
        `
    },
    {   
        id:15,
        img:blog15,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"11 min Read",
        category:"Office Space",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"15-09-2023",
        conclusion:"Fidelitus Corp’s success story in advising Dassault Systems to set up their office space in Bengaluru is a testament to their expertise in the property consulting industry. With their end-to-end property services and a team of experts, Fidelitus Corp is committed to accommodating an end-to-end service to their patrons, which allows them to focus on their core businesses. As Bengaluru continues to boom as a prime location for multinational companies to set up their offices, Fidelitus Corp’s transaction experts are committed to providing valuable advice to their clients to help them make the right decision.",
        heading:'Fidelitus Corp’s Advises Dassault Systems to Set Up Office Space in Bengaluru',
        short_description:'Fidelitus Corp is a well-known property consulting firm based in Bengaluru that provides end-to-end property services to its clients. ',
        published:"Fidelitus Corp is a well-known property consulting firm based in Bengaluru that provides end-to-end property services to its clients. Our services include identifying the right commercial/residential spaces, designing and conceptualizing the place, facility management, and finding the right human resource. Recently, the company’s transaction experts advised Dassault Systems, a leading French-based aerospace organization, to set up their office space in the major hub MM Pramukh of Bengaluru South. In this article, we will discuss how Fidelitus Corp helped Dassault Systems find the right office space in Bengaluru.",
        data:`

         
           <h6 id="font_heading">Bengaluru’s Booming Office Space Market:</h6>
           <h6 id="font_description">Bengaluru is becoming one of the most sought-after locations for multinational companies to set up their offices. With the increase in demand for MNCs to set up their global in-house centers (GICs) in India, Fidelitus Corp’s transaction experts are advising them to target the right areas showing trends of booming. The work from home culture of these companies is diminishing, which has increased the lookout for office spaces implying that the responsibility for their growth is shared with the valuable advice of our transaction experts.</h6>
           
           <h6 id="font_heading">Dassault Systems’ Search for Office Space:</h6>
           <h6 id="font_description">When Dassault Systems was looking to expand its operations in Bengaluru, Fidelitus Corp’s transaction experts took into consideration various aspects that could facilitate their growth and helped them find the most suitable location in the heart of the city. After catering to many clients in the same industry, our experts knew that nothing less than a premium property packed with top-notch amenities would be best suited for our client and advised them to choose Pramuk MM Commercial complex at Jayanagar.</h6>
           
           <h6 id="font_heading">The Transaction Process:</h6>
           <h6 id="font_description">The transaction process involved advising Dassault Systems to acquire the right office space that would cater to their specific requirements. Fidelitus Corp’s transaction experts helped them find a premium property packed with top-notch amenities that would suit their needs. The process also involved advising them on the legal and regulatory aspects of acquiring a property in India. Fidelitus Corp’s experts worked with the legal and regulatory teams to ensure that the transaction was smooth and hassle-free.</h6>
           
           <h6 id="font_heading">The Outcome:</h6>
           <h6 id="font_description">The outcome of the transaction was a success. Dassault Systems was able to find the right office space that catered to their specific needs. They were able to set up their operations in Bengaluru without any hassle, and the transaction was completed in a timely and efficient manner. Fidelitus Corp’s transaction experts were able to provide valuable advice to Dassault Systems, which helped them make the right decision in choosing the right office space in Bengaluru.</h6>
           
          
        `
    },
    {   
        id:16,
        img:blog16,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Project Management",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"12-09-2023",
        conclusion:"The design-build approach has emerged as a powerful and versatile project delivery method in the world of commercial property services. Its ability to streamline communication, control costs, expedite project delivery, and enhance quality control makes it an attractive choice for a wide range of projects. By fostering collaboration, flexibility, and innovation, design-build provides a framework for achieving commercial project success. </br> However, it’s essential to evaluate your project’s unique needs and objectives to determine whether the design-build approach is the right fit. By doing so, you can harness the many advantages of this integrated method and pave the way for a successful and efficient commercial project that meets or exceeds your expectations. At Fidelitus, our two decades of experience have shown us the concrete benefits of this approach time and again.",
        heading:'The Advantages of a Design-Build Approach in Commercial Projects',
        short_description:'The Advantages of a Design-Build Approach in Commercial Projects ',
        published:"",
        data:`

         
           <h6 id="font_heading">The Advantages of a Design-Build Approach in Commercial Projects</h6>
           <h6 id="font_description">In the world of commercial property services, success often hinges on making the right choices from the outset. One of those critical decisions involves choosing the project delivery method that best suits your objectives. Among the many options available, the design-build approach has gained significant traction in recent years for commercial projects. This integrated method, where the design and construction phases are handled by a single entity, offers a range of compelling advantages. In this article, we will explore why the design build approach is gaining popularity and how it can benefit commercial projects of all sizes.</h6>
           
           <h6 id="font_heading">The Evolution of Project Delivery</h6>
           <h6 id="font_description">Before we delve into the advantages of the design build approach, it’s essential to understand its origins and how it compares to traditional methods. Traditionally, commercial construction projects followed a design-bid-build model. Here’s a brief overview of both methods:</h6>
           
           <h6 id="font_heading">Design-Bid-Build (DBB)</h6>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Sequential Process: </b> Under DBB, the project unfolds in a sequential manner. It begins with the design phase, followed by bidding for construction contracts, and finally, the construction phase.</li>
                <li id="font_description"><b id="bolder">Competitive Bidding: </b> The construction phase involves selecting a contractor through competitive bidding, often based on the lowest bid.</li>
                <li id="font_description"><b id="bolder">Limited Collaboration: </b> There’s minimal collaboration between the designer and the builder during the design phase.</li>
           </ul>

           <h6 id="font_heading">Design-Build (DB)</h6>
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Integrated Approach: </b> In the design-build approach, a single entity is responsible for both the design and construction aspects of the project.</li>
                <li id="font_description"><b id="bolder">Collaboration: </b> Collaboration is encouraged between designers and builders throughout the project, leading to a more cohesive and efficient process.</li>
                <li id="font_description"><b id="bolder">Streamlined Communication: </b> Communication flows more smoothly, as there’s no need to coordinate between separate design and construction teams. Now that we have a clear understanding of the design-build approach, let’s explore its advantages in depth.</li>
           </ul>

           <h6 id="font_heading">Advantages of a Design-Build Approach</h6>
           <h6 id="font_heading">Streamlined Communication and Collaboration</h6>
           <h6 id="font_description">One of the standout benefits of the design-build approach is the seamless communication and collaboration it fosters. With all project stakeholders operating under a single umbrella, there is a natural synergy between the design and construction teams. This collaboration results in:</h6>
          
           <ul id="list-section">
                <li id="font_description"><b id="bolder">Faster Decision-Making: </b> Quick resolution of design issues and construction challenges.</li>
                <li id="font_description"><b id="bolder">Design Integration: </b> The ability to integrate construction feedback into the design phase, enhancing constructability.</li>
                <li id="font_description"><b id="bolder">Cost Control: </b> Continuous communication helps in controlling costs and avoiding unexpected expenses.</li>
                <li id="font_description"><b id="bolder">Timely Completion: </b> Streamlined communication often leads to faster project completion.</li>
            </ul>

            <h6 id="font_heading">Cost Efficiency</h6>
            <h6 id="font_heading">Cost control is a critical consideration for any commercial project. Design-build provides several avenues for cost savings:</h6>

            <ul id="list-section">
                <li id="font_description"><b id="bolder">Early Budgeting: </b> Since the builder is involved from the early stages, there is greater accuracy in budget estimation.</li>
                <li id="font_description"><b id="bolder">Reduced Change Orders: </b> Fewer design-related changes during construction result in fewer change orders, reducing costs.</li>
                <li id="font_description"><b id="bolder">Value Engineering: </b> The integrated team can explore value engineering options to optimize costs without compromising quality.</li>
                <li id="font_description"><b id="bolder">Competitive Bidding: </b> In the design-bid-build model, competitive  bidding can sometimes lead to under-qualified contractors, which can result in cost overruns. Design-build mitigates this risk.</li>
            </ul>

            <h6 id="font_heading"> Faster Project Delivery</h6>
            <h6 id="font_heading">Commercial projects often come with tight schedules. The design-build approach can significantly expedite project delivery:</h6>

            <ul id="list-section">
                <li id="font_description"><b id="bolder">Single-Point Responsibility: </b> The single entity accountable for design and construction reduces delays due to disputes or finger-pointing.</li>
                <li id="font_description"><b id="bolder">Parallel Workstreams: </b> Design and construction phases can overlap, saving time compared to the sequential nature of DBB.</li>
                <li id="font_description"><b id="bolder">Early Procurement: </b> arly procurement of materials and subcontractors can prevent supply chain delays.</li>
            </ul>

            <h6 id="font_heading"> Enhanced Quality Control</h6>
            <h6 id="font_heading">Quality is paramount in commercial projects, and design-build offers advantages in this regard:</h6>

            <ul id="list-section">
                <li id="font_description"><b id="bolder">Clear Accountability: </b> The design-builder is responsible for the project’s overall quality, reducing the risk of miscommunication or misunderstandings.</li>
                <li id="font_description"><b id="bolder">Efficient Problem Resolution: </b> Any quality issues that arise can be addressed swiftly and efficiently by the integrated team.</li>
                <li id="font_description"><b id="bolder">Consistency: </b> The design-builder ensures that the design intent is maintained throughout the construction phase.</li>
            </ul>

            <h6 id="font_heading"> Flexibility and Innovation</h6>
            <h6 id="font_heading">Design-build encourages innovation and adaptability, allowing for:</h6>

            <ul id="list-section">
                <li id="font_description"><b id="bolder">Design Evolution: </b> The flexibility to adjust designs during construction to meet changing needs or requirements.</li>
                <li id="font_description"><b id="bolder">Incorporation of New Technologies: </b>  The integrated team can readily incorporate new technologies and sustainable practices.</li>
                <li id="font_description"><b id="bolder">Client Input: </b> Clients have a more active role in shaping the project’s outcome.</li>
            </ul>

            <h6 id="font_heading"> Is Design-Build Right for Your Commercial Project?</h6>
            <h6 id="font_heading">While the advantages of the design-build approach are evident, it’s essential to evaluate whether it aligns with your specific project goals and requirements. Consider the following factors when making your decision:</h6>

            <ul id="list-section">
                <li id="font_description"><b id="bolder">Project Size and Complexity: </b> Design-build is well-suited for a wide range of commercial projects, from small renovations to large-scale developments. However, for exceptionally complex projects, it’s crucial to ensure that the design-builder has the necessary expertise and resources.</li>
                <li id="font_description"><b id="bolder">Project Timeline: </b> If time is of the essence, design build can be a game-changer. It excels in meeting tight deadlines, making it an ideal choice for projects with time-sensitive components.</li>
                <li id="font_description"><b id="bolder">Client Involvement: </b> Design-build encourages client involvement throughout the process. If you value active participation in decision-making and design evolution, this approach is likely a good fit.</li>
                <li id="font_description"><b id="bolder">Budget Constraints: </b> For projects with tight budget constraints, design-build offers early budgeting and cost control benefits that can help keep expenses in check.</li>
                <li id="font_description"><b id="bolder">Quality Expectations: </b> If maintaining stringent quality standards is a top priority, the clear accountability and enhanced quality control of design-build may be appealing.</li>
            </ul>
          
          
        `
    },
    {   
        id:17,
        img:blog17,
        written_by:null,
        designation:null,
        written_image:'',
        read:"3 min Read",
        category:"Office Space",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"19-04-2023",
        conclusion:"The design-build approach has emerged as a powerful and versatile project delivery method in the world of commercial property services. Its ability to streamline communication, control costs, expedite project delivery, and enhance quality control makes it an attractive choice for a wide range of projects. By fostering collaboration, flexibility, and innovation, design-build provides a framework for achieving commercial project success. </br> However, it’s essential to evaluate your project’s unique needs and objectives to determine whether the design-build approach is the right fit. By doing so, you can harness the many advantages of this integrated method and pave the way for a successful and efficient commercial project that meets or exceeds your expectations. At Fidelitus, our two decades of experience have shown us the concrete benefits of this approach time and again.",
        heading:'Bengaluru – The hub of MNC space',
        short_description:'Transaction experts of Fidelitus Corp recently advised a leading French-based aerospace organization, to set up their office space in the major hub of Bengaluru and completed a transaction of 1,74,436 sq. ft. which also gave a great start to the new financial year for us.        ',
        published:"",
        data:`
         
           <h6 id="font_description">Transaction experts of Fidelitus Corp recently advised a leading French-based aerospace organization, to set up their office space in the major hub of Bengaluru and completed a transaction of 1,74,436 sq. ft. which also gave a great start to the new financial year for us.</h6>
           <h6 id="font_description">With the increase in demand for MNCs to set up their global in-house centres (GICs) in India, our transaction experts are advising them to target the right areas showing trends of burgeoning. The work from home culture of these companies is diminishing, which has increased the lookout for office spaces implying that the responsibility for their growth is shared with the valuable advice of our transaction experts.</h6>
           <h6 id="font_description">Bengaluru is now booming to become one of the most sought-after locations by Multinational Companies to set up their offices. When Dassault was looking to expand here, our transaction experts took into consideration various aspects which could facilitate their growth and helped them find the most suitable location in the heart of the city. After catering to many clients in the same industry, our experts knew that nothing less than a premium property packed with top-notch amenities would be best suited for our client and advised them accordingly.</h6>
           <h6 id="font_description">“We strongly support the Athmanirbhar Bharat initiative and will continue to advise MNCs to set up their office spaces here, making use of the impeccable resources which we have to offer and also contributing to our economy” – Achuth Gowda, Founder & Managing Director – Fidelitus Corp.</h6>
        
           
          
        `
    },
    {   
        id:18,
        img:blog18,
        written_by:"Subash Chandra Rao",
        designation:'AVP - Corporate Communications',
        written_image:subhas_rao,
        read:"9 min Read",
        category:"Project Management",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"16-03-2023",
        conclusion:"NMR Green Nest is a future-ready, sustainable office space in Bangalore that provides businesses with an eco-friendly and productive work environment. The building’s sustainable design, flexible floor plans, and world-class amenities make it an ideal location for businesses of all sizes. If you are interested in learning more about NMR Green Nest",
        heading:'NMR Green Nest: A Future-Ready, Sustainable Office Space in Bengaluru',
        short_description:'The Advantages of a Design-Build Approach in Commercial Projects ',
        published:"<b id='bolder'>NMR Green Nest</b> is a premium office space in Bangalore’s Aerospace Park, designed to meet the highest sustainability standards. The building has been awarded the prestigious Gold Certification by the Indian Green Building Council (IGBC), an accolade that confirms its commitment to green design and reducing its carbon footprint.",
        data:`

         
           <h6 id="font_heading">Designed with a Focus on Sustainability</h6>
           <h6 id="font_description">NMR Green Nest is designed to reduce its environmental impact and promote sustainable living. The building uses eco-friendly materials, energy-efficient lighting and air conditioning systems, and water management systems that conserve water. The building’s design ensures maximum daylight and fresh air circulation, reducing the need for artificial lighting and HVAC systems. The result is a workspace that is both eco-friendly and conducive to productivity and well-being.</h6>
           
           <h6 id="font_heading">Flexible Floor Plans and World-Class Amenities</h6>
           <h6 id="font_description">NMR Green Nest offers flexible floor plans that can be customized to meet the specific requirements of each business. The building is equipped with state-of-the-art amenities and facilities, including a landscaped terrace, amphitheater, gym, and more. The landscaped terrace is an oasis in the city, providing a serene environment for relaxation and rejuvenation. The amphitheater is perfect for hosting events, seminars, or presentations, while the gym allows tenants to stay active and maintain a healthy work-life balance.</h6>
           
           <h6 id="font_heading">Strategic Location and Access to Transport</h6>
           <h6 id="font_description"><b id="bolder">NMR Green Nest</b> is strategically located in Aerospace Park, which is home to some of the world’s leading aerospace and technology companies. The location provides easy access to the airport, the city center, and other key business hubs in Bangalore. The building is well-connected by road, rail, and air, ensuring seamless connectivity for businesses with international clients.</h6>
           
           <h6 id="font_heading">International Quality Standards</h6>
           <h6 id="font_description">NMR Green Nest is designed to meet international quality standards, providing tenants with a world-class work environment. The building’s state-of-the-art technology and smart design ensure maximum productivity and comfort. The flexible floor plans allow businesses to adapt to changing requirements, while the sustainable design ensures that they operate in an eco-friendly workspace.</h6>       
          
        `
    },
    {   
        id:19,
        img:blog19,
        written_by:null,
        designation:null,
        written_image:null,
        read:"5 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"19-06-2021",
        conclusion:"",
        heading:'We Saw The Opportunity, We Believed, And We Conquered',
        short_description:'In which category do you come under?',
        published:"",
        data:`

         
           <h6 id="font_description">In which category do you come under?</h6>
           <h6 id="font_description">Jack of all, Master of none OR Jack of none, Master of one.</h6>
           <h6 id="font_description">Fidelitus Corp prefers being “The Master Of All”!</h6>
           <h6 id="font_description">It’s often a general notion that a Real Estate company cannot excel in all sectors. Our transaction experts are here to prove this wrong by ensuring that we provide our offerings with high expertise across various sectors. They have crossed all boundaries and successfully completed major transactions in sectors like healthcare, education, residential, commercial, etc. </h6>
           <h6 id="font_description">After completing a major transaction of 3 Acres for a nation-building organization at RR Nagar, Bangalore, we are happy to have expanded across various sectors in the industry as our Transaction Assistant Managers, Anusha RM and Rajath MR completed a major transaction of built to suit long-term lease of 4 Acres for Vidyashilp School at Kanakpura Road, Bangalore. With high competition in the industry, we were able to gauge the requirements of our clients and cater to their needs specifically.</h6>
           <h6 id="font_description">With an increasing trend in the market especially in the healthcare sector which is a niche, many hospitals, health care systems, and medical practitioners opt for leasing out highly equipped and economical spaces. We identified this need in the market and were able to correctly tap their requirements and close major transactions in this sector as well one of which is a renowned nation-building organisation in Bangalore, Karnataka. The transaction experts of Fidelitus Corp, Nikhil Rangwani and Akash Patil, were able to ensure that there were no problems in the whole process of the transaction when it came to arranging for meetings in the lockdown period and were able to manage and complete the transaction efficiently as per the timeline preferred by our client.</h6>
           <h6 id="font_description">We never believe in putting all our eggs in one basket. We will continue to venture into as many sectors as possible and study the market in-depth to be able to provide exactly what they’re looking for. Since our experts are able to complete a major transaction every time we have explored a new sector, it encourages our real estate experts to continue to do the same.</h6>
           <h6 id="font_description">The real estate industry is vast in nature and of course, needs a lot of study and comparisons in order to master it. By continuously striving for excellence we will definitely be able to achieve what the ordinary won’t.</h6>
           
          
        `
    },
    {   
        id:20,
        img:blog20,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"3 min Read",
        category:"Case Study",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"28-05-2021",
        conclusion:"",
        heading:'IT’S TIME TO “ASSESS THE ASSETS”',
        short_description:'The times across the globe have thrown in a challenge to the human race. The challenge is so vivid and complex, that only those who are competitive and think out of the box can face it and overcome it.',
        published:"",
        data:`

         
           <h6 id="font_description">The times across the globe have thrown in a challenge to the human race. The challenge is so vivid and complex, that only those who are competitive and think out of the box can face it and overcome it.</h6>
           <h6 id="font_description">It is at this juncture that the test of the best comes into play. Beyond thought every individual or a corporate should plan and work towards ensuring that the changing trends evolved are utilized, company assets are the pivotal strength to be cautiously handled to ensure that the optimum mileage is obtained. Builders will have a rethought on the current plan of development to be changed to the most common need from all sectors to ensure optimum returns on investments.</h6>
           <h6 id="font_description">The current trend of logistics spanning out across borders has catapulted their requirements for warehouses and other activities.</h6>
           <h6 id="font_description">The dynamics of the marketplace have seen enormous changes on a day-to-day basis, which has made the real estate market to be more challenging at the same time an opportunistic playground. Even the most mammoth players alike have put on their thinking caps to evolve various strategies for this changing market. Even after all this, it is not sure that this trend is going to stay for a while.</h6>
           <h6 id="font_description">The pandemic has given the real test of strength to all :</h6>
           <h6 id="font_description">· The corporates and the like have opportunities to maximize their asset strength.</h6>
           <h6 id="font_description">· The builder's fraternity will have to relook at their plans.</h6>
           <h6 id="font_description">· Investment focus has got many options to capitalize on the trends cropping up.</h6>
           <h6 id="font_description">· Reconsolidation of office space strengthens the organization in utilizing the opportunities available.</h6>
           <h6 id="font_description">· Multiple opportunities of space availability in varying sizes have also contributed to many options for reconsolidation of commercial spaces.</h6>
           <h6 id="font_description">· Changing commercial space needs are not a challenge when you have many options.</h6>
           <h6 id="font_description">· Productivity may have increased, with increased stress and frustration for the human resources to endure it. Hence this poses up yet another area of concern to be addressed.</h6>
           <h6 id="font_description">· The act of work-life balance for a human resource with the extending arm of the organizations can ensure a better mileage for corporate strategies.</h6>
           <h6 id="font_description">· Evolving with dedicated covid care centres, conducting specialized vaccine drives are a boon to the human resources of an organization, wherein the support from management reflects empathy for all.</h6>
           <h6 id="font_description">· Utilizing the investment opportunities, keeping in mind the future ahead with the right mix of balanced growth can yield enormous results in the days to come.</h6>
           <h6 id="font_description">· Opt for the right partners who can render the best recommendations and solutions should be the need of the day.</h6>
           <h6 id="font_description">All these will make us look at the statistics of the media around the globe. Different views and different results from various non-comparable situations have come out to emphasize the need for personal interactions, work-life balance, unlimited opportunities for the youth, working from home or nearby satellite spaces closer to home, different possibilities to work, will only make the office space to be reviewed with future in mind. However all factors of growth of the economy will also have to be kept in mind for the all-round development of the society, motivating the fresh younger generations stepping into the work culture will also matter a lot, thereby the industry and the fabric of the business entities are to be safeguarded as a whole.</h6>
           <h6 id="font_description">We as an organization are armed with the right mix of options to cater to the needs of the corporates and all sectors of the business community, extending the best support, beyond expectations. I am confident and sure that in the days ahead of us, we can look to chance upon different opportunities with multitudes of options to play with.</h6>
           
          
        `
    },
    {   
        id:21,
        img:blog21,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"3 min Read",
        category:"Case Study",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"16-07-2021",
        conclusion:"",
        heading:'Remarkable Thumbs Up Is The Sign Of Our Closure',
        short_description:'If you are a Real Estate expert, this question is for you.',
        published:"",
        data:`

         
           <h6 id="font_description">When was the last time you closed a transaction which did not involve the meeting of the client and the landlord in person?</h6>
           <h6 id="font_description">Probably never.</h6>
           <h6 id="font_description">Our answer would be the same until recently, when we closed a major transaction of 1 Acre for Stelis at Bommasandra, Bangalore, which did not involve a single client meeting. Yes, you read it right.</h6>
           <h6 id="font_description">We are well aware that transactions involve multiple meetings and negotiations between the landlord and client before they can be closed. It is important to have these meetings since it gives us an opportunity to understand in depth what the client and landlord expect and how we can deliver it to them.</h6>
           <h6 id="font_description">But in this case, there was no face-to-face meeting involved in the whole process and our transaction experts were able to provide what both the parties were looking for by correctly gauging what they need and provide the same by being highly time-efficient. The PO was handed over to us even before the registration could be completed which is a rare situation we as real estate experts witness. They established their commitment to us by handing over a contract to the Fidelitus Project Team as well since they were impressed with the quality of service delivered to them by the Fidelitus Transactions Experts. We are extremely happy to have established such good relations with our clients and look forward to working on more projects together.</h6>
           <h6 id="font_description">This only goes on to prove the trust and confidence our clients hold in us. We are extremely grateful for the opportunity our clients provide us to serve them. We were able to deliver and exceed the expectations of our client within the given timeline, and this was possible only through the expertise gained over years of serving in the real estate industry.</h6>
           <h6 id="font_description">The results are meant to follow when the focus is on working hard with diligence.</h6>
          
          
        `
    },
    {   
        id:22,
        img:blog22,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"3 min Read",
        category:"Case Study",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"02-01-2023",
        conclusion:"",
        heading:'Unfold The Zeal with Fidelitus-2023!!',
        short_description:'2022 is indeed a promising year. We are stepping into the new year of 2023 with new aspirations. We are ready to welcome the new year with new joy, new hope, and new vigor.',
        published:"",
        data:`

         
           <h6 id="font_description">We are entering 2023 even stronger by integrating the life lessons taught by the pandemic in the past years.</h6>
           <h6 id="font_description">The year 2022 has taught us many challenges and solutions even though we have overcome the challenges experienced during the pandemic and returned stronger.</h6>
           <h6 id="font_description">It is indeed a miracle that we have recovered from the pandemic of 2021 and become stronger in 2022. It is great that we have grown like warriors who can face any difficulties when the year 2022 is concluding. Currently, all the departments of our Fidelitus Corp organization are working well and we have found the confidence and determination to achieve double the planned growth in the past two years.</h6>
           <h6 id="font_description">It is very happy that our Fidelitus Corp is also growing year by year and has achieved lots of milestones. . Our Fidelitus family has also been able to spread smiles on everyone’s faces with more cheerfulness and vigor. With the motto ‘We are with you for a better tomorrow’, this year too we have been able to conduct business and made more people a part of the Fidelitus organization. In this regard, each of our business verticals is working positively towards more achievements with great enthusiasm.</h6>
           <h6 id="font_description">It is a matter of pride that our organization has been recognized at the global level in the current year. Our Fidelitus Corp has also been recognized at par with the national and international level companies in Bengaluru Tech Summit which was Asia’s biggest business expo. It was also represented at First Circle Business Expo, Invest Karnataka, and FMG Global Meet.</h6>
           <h6 id="font_description">Fidelitus Corp which was operating in Hyderabad, Cochin, and Chennai joined hands with CIRIL – Commercial Office, Investment, Retail, <span id="bolder"> Industrial & Logistics </span> Services is now operating in all the cities and now is capable of operating in 24 cities of the country.</h6>
           <h6 id="font_description">Not only this, but Fidelitus Corp is also ready to work at the international level and some of the international projects will soon be released for you.</h6>
           <h6 id="font_description">As our Shilpa Foundation has been carrying out social work continuously for seven to eight years, we are very proud to say that this year also we have commendably extended our social responsibility very well. In the year 2022, Shilpa Foundation has succeeded in providing education, health, and food to hundreds of children. We are satisfied to give our best help throughout the year who were in need. Shilpa Foundation participated in Sansadara Shaale Abhiyan and joined hands in the development of many government schools in Bengaluru. Also, to create environmental awareness among the people, we have organized tree-planting programs and awareness programs among the children. We have also organized health camps for school children and helped treatment for thousands of children.</h6>
           <h6 id="font_description">We have lost many well-known artists this year. The artists who faced hardships in the year 2020-21 tried to bounce back in the year 2022. Meanwhile, our Fidelitus Gallery organized many programs to support art and artists. The Fidelitus Gallery which completed 8 years successfully organized many exhibitions by the artists. On special occasions, one of the important artists of our gallery Kotegadde S Ravi who conducted speed paintings brought a good name to us.</h6>
           <h6 id="font_description">Even in the year 2022, we feel proud to say that we have lit up the lives of thousands of people, not directly, but indirectly.</h6>
           <h6 id="font_description">Our Fidelitus Corp Human Resources and Facility Management team has helped many people get jobs and return to a comfortable life. This is very significant work we have done this year.</h6>
           <h6 id="font_description">The corporate real estate sector is recovering amid several challenges and the team at our company has achieved greater heights. It is successfully finalized many transactions. In addition, our turn-key design-build team is also continuously working on several projects and delivering projects. Works are being done by our team in several places including KSCA, and ISRO in collaboration with the government. From this year we are making a mark in the construction sector as well.Overall in the year 2022,</h6>
           <h6 id="font_description">I must remember the personalities who supported and cooperated with me in my personal and business life as a backbone, mentor, and benefactors. I offer my obeisance’s to the spiritual leaders including Paramapujya Avadhootha Sri Vinay Guruji, Paramapujya Jagadguru Sri Sri Sri Nirmalananda Swamiji, Sri Sri Prakashnath Swamiji, Sri Sri Swasa Guru Vachanananda Swamiji who are always leading us spiritually through good words and guidance. Similarly, I extend my heartfelt thanks to the dignitaries Sri Dr. Ashwathnarayan, Sri Tejaswi Surya, Sri HD Devegowda, Sri HD Kumaraswamy, etc., and others.</h6>
           <h6 id="font_description">Above all, my loving family who have supported me every step of the way. I am indeed nothing without the support of my little family. Amidst the pressures of daily business life, time spent with family was rare this year. Even so, I am eternally indebted to my beloved family, including my wife, beloved children, and parents, who have stood by me and supported me in everything.</h6>
           <h6 id="font_description">If all these are memories of the passing year, big plans are in mind for the new year, and our team is geared up to achieve more. Coming soon with big projects. We desire that you bless us as earlier.</h6>
           <h6 id="font_description">We need to make even more lifestyle changes in the year 2023, to live even more healthily, happily, and lavishly. May your plans be big and may you have the drive, passion, and ability to achieve them. May your life be filled with peace and prosperity.</h6>
           <h6 id="font_heading">Unfold The Zeal with Fidelitus!!</h6>
           <h6 id="font_heading">Happy New Year 2023!</h6>
           <h6 id="font_description">Best Regards </h6>
           <h6 id="font_heading">Achuth Gowda, MRICS, Founder & MD – Fidelitus CORP</h6>
          
          
        `
    },
    {   
        id:23,
        img:blog23,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"3 min Read",
        category:"Award",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"07-04-2021",
        conclusion:"",
        heading:'Indian Achievers’ Forum Award 2020-21',
        short_description:'I am extremely grateful to be honoured with the Indian Achievers’ Award 2020-21 in recognition of outstanding professional achievement and contribution in Nation Building. IAF (Indian Achievers Forum), being India’s most coveted award, is another feather in the cap for Fidelitus and goes on to prove our competency in the field.',
        published:"",
        data:`

         
           <h6 id="font_description">I am extremely grateful to be honoured with the Indian Achievers’ Award 2020-21 in recognition of outstanding professional achievement and contribution in Nation Building. IAF (Indian Achievers Forum), being India’s most coveted award, is another feather in the cap for Fidelitus and goes on to prove our competency in the field.</h6>
           <h6 id="font_description">It is a well-known fact that smooth seas never make good sailors. It has not always been easy to stay on top. We have faced many hurdles which required us to strive together as a team and move ahead. A company becomes successful not only because of all its achievements but also because of how the situation was handled when there was a chance for things to go downhill. With proper support and coordination from the team, Fidelitus was able to overcome all the hurdles and come back stronger than ever in the industry. Success will follow hard work, we never stopped believing in our capabilities and stood together as a team to ensure that even the strongest of a turbulent did not disrupt us.</h6>
           <h6 id="font_description">This award is an encouragement for us to dream bigger and gives us the motivation to reach what seems to be impossible. Fidelitus has much more to achieve and this is only the beginning.</h6>
          
        `
    },
    {   
        id:24,
        img:blog24,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"3 min Read",
        category:"Achievment",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"29-03-2021",
        conclusion:"",
        heading:'Great things take time – We can & We Will!!',
        short_description:'The past one year has been extremely tough for many, but I am glad to have been through this since it made me realize the true potential of my organization and the heights we can reach. The Pandemic has in fact changed the way we think and work as an organization for the better.',
        published:"",
        data:`

         
           <h6 id="font_description">The past one year has been extremely tough for many, but I am glad to have been through this since it made me realize the true potential of my organization and the heights we can reach. The Pandemic has in fact changed the way we think and work as an organization for the better.</h6>
           <h6 id="font_description">We started the year 2020 at our new premises Brigade Software Park with a new zeal and had the complete plan ready for the coming year. Little did we know that it was just the calm before the storm. In March 2020, the global pandemic made its appearance and turned the world upside down.</h6>
           <h6 id="font_description">At the peak of our business, the Government announced Lockdown 1.0, followed by lockdown 2.0, lockdown 3.0 for 21 days and so on. The biggest challenge for us was to overcome the downfall in the real estate market which makes up the core of our business.  We, as Fidelitus had to immediately come up with other alternatives to keep the company going. This is when we started our fumigation and sterilization services and were able to pick it up well with the increasing demand due to the pandemic. The Government recognized us and gave permission to fumigate Government Schools and SSLC exam centers. We also provided disinfectant tunnels for police stations in Karnataka. In no time, we were fumigating, corporates, the major Government Buildings and Public sector unions and slowly extended this to residentials as well.</h6>
           
           <img alt=${blog24_1} src=${blog24_1} id="img_section" />
           <h6 id="font_description">During these times, we got a major break in the Real estate vertical when we leased out giant e-commerce organisation. When the IT and E-Commerce market was dropping down with the work from home culture in place, it became tough to keep the numbers growing. This is when we explored into a new market, logistics with major players in the industry as our clients post-lockdown. This was our new ray of hope during the completing of the second quarter.</h6>
           <h6 id="font_description">When we had to deliver the projects to clients in the stream of German based electronics manufacturers,  Wipro’s domestic IT Business – Leader in India’s MPS Market , parent firm of JSW’s,  we faced a shortage of resources from materials to manpower. Even in this critical situation, my project team ensured that there were no shortcomings in the delivery of all the projects and ensured that they were completed on time. We saw a drop in manpower in the Facility Management team as well and to overcome this, we got into tender participation to PSUs and Government bodies. This gave a boost to our manpower which helped us target residential spaces as well.</h6>
           <h6 id="font_description">Post lockdown, for a noble cause we organized India’s first ever masterpiece sculpture exhibition Malenadina Mukhagalu with 50+ chief guests including Bengaluru South MP and My friend Tejasvi Surya, Director of Sri Jayadeva Institute of Cardiovascular Sciences and Research – Dr. C N Manjunath and many along a period of 21 days who were honored with art from the Fidelitus Art Gallery, to boost the confidence of the artists, encourage their art and show that our Art Gallery is up and running.</h6>
           
           <img alt=${blog24_2} src=${blog24_2} id="img_section" />
           <h6 id="font_description">The downsizing of companies became a major threat to our Recruitment vertical. This is when our initiative with MP Tejasvi Surya, Sansad Udyog Mitra though Shilpa Foundation came into place during the last quarter. A job drive is organized on the last Sunday of every month which has helped 1000+ candidates land a job from the entry level to senior level positions.</h6>
          
           <img alt=${blog24_3} src=${blog24_3} id="img_section" />
           <h6 id="font_description">As we believe in giving back to the society, Shilpa Foundation provided smart class equipment to Government schools, basic essentials to the covid warriors for risking their lives for the well-being of others. As a step towards a greener environment, a sapling plantation program, Think Green was organized.</h6>
          
           <img alt=${blog24_4} src=${blog24_4} id="img_section" />
           <h6 id="font_description">Everything was running smoothly in the organization, with all the verticals performing their best. This is when I saw the toughest phase of my life. I was tested positive for Covid during the festive season*, which kept me away from the Organization for three weeks. I was reunited with my team on the prosperous day of Diwali when I was filled with joy as I could see that, in spite of my absence, my team was able to keep the performance graph of the company going high. This is when I realized the true potential of my team and the wonders they can achieve even without my guidance. The beginning of new year, filled us with positive energy and pushed us to close the transactions with Engineering and Technology Services Organisations where we assisted them to move to better locations with wide range space of 40,000 Sq.ft (Hosur Road) in the silicon valley , Another 25,000 Sq.ft (Electronic City –Phase 1) for US based Engineering Services Company meanwhile while closing the 1st quarter of 2021 our transaction team completed with the finishing move of 15,000 Sq.ft space (Old Airport Road) for leading Artificial Intellegence(AI) Company  and in the end of maiden quarter year majorly we closed with 2,50,000 Sq.ft Space(Hosur Road) for the giant pharma company by getting the project approved. During the Unlock series of the Pandemic projects including ABVP, MyString and Sowparnika were launched.</h6>
          
           <img alt=${blog24_5} src=${blog24_5} id="img_section" />
           <h6 id="font_description">I was overwhelmed when I was awarded the prestigious Nadaprabhu Kempegowda Award on September 10th 2020, for our contribution to the society during the Covid Times. This award is wholly dedicated to my team for the efforts and hardwork they’ve put in to make all our initiatives successful and empowering.I am happy to be a part of such a dynamic team and how we’ve been able to overcome all the hurdles and rise to the top even during such tough times and promise to never stop hustling and working hard. Tough times may come and go but tough people stay forever. We do not have control on what life throws at us, but what we can control are the decisions and steps we take during the tough times and that is what makes us different from others. Facing such difficulties will only make us come back stronger and tougher making us ready for the next challenge.</h6>
          
           <h6 id="font_description">People begin to become successful the minute they decide to be – Harvey Mackay</h6>
          
        `
    },
    {   
        id:25,
        img:blog25,
        written_by:"Achuth Gowda MRICS",
        designation:'Founder and Managing Director Fidelitus Corp',
        written_image:achuth_gowda,
        read:"3 min Read",
        category:"Real Estate",
        tags:'BENGALURU COMMERCIAL REAL ESTATE, COMMERCIAL OFFICE SPACE, FIDELITUS CORP, OFFICE SPACE, REAL ESTATE, REAL ESTATE IN BENGALURU, RESIDENTIAL SPACE, RETAIL SPACE, TRANSACTION EXPERTS',
        color:"3474eb",
        date:"26-11-2021",
        conclusion:"",
        heading:'Commercial real estate to rebound in 2021',
        short_description:'The real estate sector is going through a difficult phase since past many months, but the commercial segment is still faring well and also receiving the maximum responses of investors. In fact, 2020 witnessed a whole PE influx of $970 million into Indian market, and commercial realty enticed the highest private equity funds, adding approximately $3 billion in the first three quarters.',
        published:"",
        data:`

         
           <h6 id="font_description">The real estate sector is going through a difficult phase since past many months, but the commercial segment is still faring well and also receiving the maximum responses of investors. In fact, 2020 witnessed a whole PE influx of $970 million into Indian market, and commercial realty enticed the highest private equity funds, adding approximately $3 billion in the first three quarters.</h6>
           <h6 id="font_description">In the retail sector, opening levels have dipped low to 14 per cent in 2020. Around 7 million square feet of retail sector is anticipated to arrived the marketplace in the year 2020, thereby subsidising to the overall healthy progress of the real estate space in Ind</h6>
           <h6 id="font_description">When we equate the venture in commercial with residential, then it can be said that revenues from commercial are better. An augmenting amount of private equity funds exhibited interest in the commercial office space in 2019, which was followed by the same in 2020. The style of commercial office space having a greater role in real estate properties will stay. There will likely be additional infusion of liquidity in saleable asset and inventors will come up with more projects in this fragment.</h6>
           <h6 id="font_description">This sector, which comprises of industrial, retail and frontier areas such as co-living, is flourishing as a result of worthy revenues in the short and long term. The stakeholders with real estate know-how diverted their contemplation towards the lucrative real estate. With the government initiating steps needed for boosting the economy, the sector is expected to bounce back and carry on with its upright run.</h6>
           
           
        `
    },
    
]

export const clientArr = [
     Image1,
Image2,
Image3,
Image4,
Image5,
Image6,
Image7,
Image8,
Image9,
Image10,
Image11,
Image12,
Image13,
Image14,
Image15,
Image16,
Image17,
Image18,
Image19,
Image20,
Image21,
Image22,
Image23,
Image24,
Image25,
Image26,
Image27,
Image28,
Image29,
Image30,
Image31,
Image32,
Image33,
Image34,
Image35,
Image36,
Image37,
Image38,
Image39,
Image40,
Image41,
Image42,
Image43,
Image44,
Image45,
Image46,
Image47,
Image48,
Image49,
Image50,
Image51,
Image52,
Image53,
Image54,
Image55,
Image56,
Image57,
Image58,
Image59,
Image60,
Image61,
Image62,
Image63,
Image64,
Image65,
Image66,
Image67,
Image68,
Image69,
Image70,
Image71,
Image72,
Image73,
Image74,
Image75,
Image76,
Image77,
Image78,
Image79,
Image80,
Image81,
Image82,
Image83,
Image84,
Image85,
Image86,
Image87,
Image88,
Image89,
Image90,
Image91,
Image92,
Image93,
Image94,
Image95,
Image96,
Image97,
Image98,
Image99,
Image100,
Image101,
Image102,
Image103,
Image104,
Image105,
Image106,
Image107,
Image108,
Image109,
Image110,
Image111,
Image112,
Image113,
Image114,
Image115,
Image116,
Image117,
Image118,
Image119,
Image120
]

export const succeedArr = [
    {
        img:understand,
        value:'Understand Client Requirment'
    },
    {
        img:readystock,
        value:'Check Property Availability'
    },
    {
        img:tasklist,
        value:'List Avaialble Option'
    },
    {
        img:shortlist,
        value:'Property Listing By Client'
    },
    {
        img:map,
        value:'Conduct Multiple Site Visit'
    },
    {
        img:contractnegotiation,
        value:'Negotiate Preffered Option'
    },
    {
        img:end,
        value:'Conclude'
    },
]

export const bannerArr = [banner5,banner6,banner7,banner8]

export const bannerDescriptionArr = [
    {   
        id:1,
        heading:'Corporate Real Estate',
        description:'We ensure your real estate assets align with your business goals, driving operational efficiency and maximizing value. Trust us for expert management and optimization of your corporate real estate needs.',
        tag:'Fidelitus Projects',
        path:'/services/corporate-real-estate',
        color:'white',
    },
    {
        id:2,
        heading:'Resedential Leasing / Rent / Sale',
        description:' Our team is dedicated to helping you find the perfect residential property that suits your preferences and budget. From searching for available listings to negotiating lease terms or purchase agreements, we ensure a smooth and hassle-free experience.',
        tag:'Achitecture & Design',
        path:'/services/resedentail-leasing-rent-sale',
        color:'white',
    },
    {
        id:3,
        heading:'Land Investment',
        description:'We offer strategic land investment opportunities tailored to maximize returns and capitalize on potential growth, providing a solid foundation for long-term financial security.',
        tag:'Quantification & Budgeting',
        path:'/services/land-investment',
        color:'white'
    },
    {
        id:4,
        heading:'Industrial Warehousing',
        description:'Our tailored services optimize storage, logistics, and distribution, ensuring seamless operations for businesses of all scales. ',
        tag:'Interior Design',
        path:'/services/industrial-warehousing',
        color:'white'
    }
]

export const service_section11 = ser_sect_11
export const service_section12 = ser_sect_12
export const service_section13 = ser_sect_13
export const service_section14 = ser_sect_14
export const service_section15 = ser_sect_15
export const service_section16 = ser_sect_16


export const service_section21 = ser_sect_21
export const service_section22 = ser_sect_22
export const service_section23 = ser_sect_23
export const service_section24 = ser_sect_24
export const service_section25 = ser_sect_25
export const service_section26 = ser_sect_26

export const service_section31 = ser_sect_31
export const service_section32 = ser_sect_32
export const service_section33 = ser_sect_33
export const service_section34 = ser_sect_34
export const service_section35 = ser_sect_35

export const service_section41 = ser_sect_41
export const service_section42 = ser_sect_42
export const service_section43 = ser_sect_43
export const service_section44 = ser_sect_44
export const service_section45 = ser_sect_45

export const gallerylogo = gallery_logo1

export const base_url = 'https://fidelitustech.com/api/'
export const image_base_url = 'https://fidelitustech.com/'

export const transaction_website_url = "https://fidelitustransactions.web.app"
export const project_website_url = "https://fidelitusprojects.web.app/"
export const fms_website_url = "https://fidelitusfms.web.app/"
export const hrlabs_website_url = "https://fidelitushrlabs.com/"
export const fidelitus_gallery_website_url = "https://fidelitusgallery.com/"


export const villaPlotsBanner = villa_plots_banner
export const kamadhenuBanner = kamadhenu_banner
export const tnrdrivetechBanner = tnr_drive_tech_park_banner
export const primeLandOption = primae_land_option_for_sale_banner
export const industrialBanner = industrial_banner
export const trifectaBanner = trifectaadatto
export const landforSaleBanner = land_for_sale
export const builtToSuitBanner = built_to_suit
export const primecoTowerBanner = primeco_tower
export const ajmeraNucleusBanner = ajmera_nucleus
export const ozoneChamberBanner = ozone_chambers
export const QubeSoftwareParkBanner = qube_software_park
export const BangloreLifeSciencePark = banglore_life_science_park
export const PrimeCoUnionCity = primeco_union_city
export const ShilpaAnanyaBanner = shilpa_ananaya
export const PrimeCoLandOptionBanner  =  primeco_land_option
export const NMRGreenNestBanner = nmr_green_nest
export const GuruKrupaBanner = gurukrupa


export const AccurateValuation = accurate_valuation
export const ComplianceManagement = compliance_management
export const DueDeliguence = due_deliguence
export const EndToEndSupport = end_to_end_support
export const ExpertNegotiation = expert_negotiation
export const FlexSpaceValuation = flex_space_valuation
export const IndustrialSpaces = industrial_spaces
export const LeaseNegotiate = lease_negotiate
export const ManagedSpaceAdvisory = managed_space_advisory
export const MarketResearch = market_research
export const OfficeSpaces = office_spaces
export const PortofolioOptimization = portofolio_optimization
export const RetailSpaces = retail_spaces
export const SpaceOptimization = space_optimization
export const StrategicPropertyInvestment = strategic_property_investment
export const WarehouseSpace = warehouse_space
export const WorkPlaceStrategy = work_place_strategy