import React from 'react'
import {project_website_url, servicesArr } from '../../folder/constants/Appconstants'
import { useNavigate } from 'react-router-dom'

function Projects() {

  const navigate = useNavigate()

  return (
    <div className='w-[100%]'>
       <div className='relative flex items-center justify-center bg-projects_banner_bg bg-bottom bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10 absolute w-full h-full flex flex-col  items-center justify-center'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>Projects</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Projects </h6>
              <h6 className='text-[13px] 2xl:text-[16px] mt-1  text-white w-[80%] mx-auto text-center font_normal opacity-80'>We are leading Design-Build Commercial and industrial infrastructure provider based in Bengaluru!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        <div className='w-[90%] md:w-[80%] mx-auto py-[5%]'>
        <h6 data-aos="fade-right" className='font_bold text-[20px] 2xl:text-[23px]'>Fidelitus Projects</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>Fidelitus Projects is a leading Design-Build Commercial and industrial infrastructure provider based in Bengaluru. Our team of experienced industry professionals helps you grab every growth development opportunity in Turnkey commercial interior & industrial infrastructure. Also as a leading interior designing firm in Bangalore, we showcase our expertise not just in the fields of Architecture, Design, and 3-D Rendering, but also in Project Management, Quantification, and Budgeting, Procurement, Turnkey Projects, HR Management, Facility Management Services, Real Estate Transactions.</h6>


        <h6 data-aos="fade-right" className='font_bold text-[20px] 2xl:text-[23px]'>Why we standOut</h6>
       
        <h6 data-aos="fade-left" className='font_thicker text-[14px] 2xl:text-[17px] my-4'>Industry Experts</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>Our firm in Bangalore comprises experts from each field of work who are always ready to go the extra mile to reach your goal. With an experienced and highly skilled team of in-house architects, interior designers, MEP & civil engineers & also key project/account managers, we can ensure every project progresses smoothly in various locations in PAN India.</h6>
        
        <h6 data-aos="fade-left" className='font_thicker  text-[14px] 2xl:text-[17px] my-4'>Dedicated Manager</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>For every client of ours, we provide a dedicated team of in-house technical and civil engineers, key project managers, architects and interior designers in Bangalore, so that all our projects flow smoothly under trusted guidance.</h6>

        <h6 data-aos="fade-left" className='font_thicker  text-[14px] 2xl:text-[17px] my-4'>Timely Action</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>Time and money are important and we help you save both. To be one of India’s reputed IPC, we ensure settling all your projects on time and give you the best return on investment is what we believe in.</h6>



        <h6 data-aos="fade-right" className='font_bold text-center text-[20px] 2xl:text-[23px]'>Services We Do</h6>


        <div className='grid grid-cols-2 mt-10 gap-4 md:grid-cols-4'> 

{servicesArr?.map((o)=>(
    <div data-aos="fade-bottom" data-aos-delay="300" className='p-2 md:p-5 shadow mt-2'>
         <img src={o?.image} className='w-[100%] h-[60px] object-contain' />
         <h6 className='font_thicker text-center text-[13px] 2xl:text-[16px] mt-2'>{o?.label}</h6>
         {/* <h6 className='text-[13px] text-center'>{o?.description}</h6> */}
    </div>    
))}

</div> 
      
        <h6 data-aos="fade-bottom" className='text-[13px] 2xl:text-[16px] mt-10 font_normal'>If you would like to get more information about the Fidelitus Projects visit us <a href={project_website_url} target='_blank' className='underline cursor-pointer font_bold text-[#027d9b]'>www.fidelitusprojects.com</a></h6>

        </div>

    </div>
  )
}

export default Projects