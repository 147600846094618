import React from 'react'
import Typist from 'react-typist';

//why we succeed
import cloud from '../folder/images/services/we_are_best/cloud-database.png'
import conversation from '../folder/images/services/we_are_best/conversation.png'
import dedication from '../folder/images/services/we_are_best/dedication.png'
import on_time from '../folder/images/services/we_are_best/on-time.png'


function WhyUs() {

  const we_are_best = [
    {
      img:on_time,
      name:'On Time Process',
      val:'Our industry knowledge about Real Estate transactions helps to find and deliver your requirement on time.'
    },
    {
      img:dedication,
      name:'Dedicated Manager',
      val:'We will allot a dedicated expert who will be responsible to help and guide you with your requirement.'
    },
    {
      img:cloud,
      name:'Unmatched Database',
      val:'Our updated database will ensure that your requirements and needs are met to the highest standards.'
    },
    {
      img:conversation,
      name:'Proper Communications',
      val:'We believe in transparency, and our team will ensure that there is proper communication made to you in the process.'
    }
  ]

  return (
    <div className="w-full h-full overflow-hidden">
        <div className='h-[260px] w-screen overflow-hidden relative md:h-[400px] grid place-items-center  bg-whyus_bg bg-no-repeat bg-cover md:bg-cover bg-right-top  '>
            <div className="absolute left-5 md:left-[5%] top-5 md:top-24 w-[60%]  md:w-[40%]  border-l-4 border-l-[#4190ac] pl-3">
              {/* <Typist stdTypingDelay={15} avgTypingDelay={15} > */}
              <h1 className="text-[21px]  font-[700] font_bold  z-100 !important text-[#e58c3d] ">Why Us</h1>
              <h6 className="text-[14px] w-[100%] md:text-[15px] text-[#fff] font_normal ">We are hiring!, If you're intrested in joining martian fidelitus army we are looking a people like you. </h6>
              {/* </Typist> */}
            </div> 
        </div>

        <div className='py-20 bg-gray-50  items-center bg-home_trans_bg bg-no-repeat bg-right-bottom justify-between w-[100%] mx-auto'>

      


        <div className='w-[90%]  md:w-[80%] mx-auto'>

        <h6 className='text-[13px] 2xl:text-[16px] font_normal ml-6 text-gray-400 mb-2'>We belive in hard work. </h6>
        <h6 className='font_bold text-[25px] 2xl:text-[28px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>We Belive</h6>
        <h6 className='font_normal px-8 text-[14px] 2xl:text-[17px] mb-20'>Our success depends on having a thorough understanding of the Indian real estate market before applying international standards to each client’s project. We create cutting-edge company space solutions and maintain a database of floors that are prepared for our clients to new ventures. Along with assisting clients with relocation, we also aid them in expanding the number of offices they require. For clients to benefit the most from them, each of our office locations has been carefully chosen. With cutting-edge conveniences and customized interiors, we turn bare floors into workspaces. Our knowledgeable crew assists you with all your needs because we know them all.</h6>


        <h6 className='text-[13px] 2xl:text-[16px] font_normal ml-6 text-gray-400 mb-2'>We have strong and skilled team who makes our clients satisfy. </h6>
        <h6 className='font_bold text-[25px] 2xl:text-[28px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>Why we are best</h6>
        <h6 className='font_normal text-[14px] 2xl:text-[17px] ml-3  pl-4'>Fidelitus Corp with its aggressive expansion plans have recruited more professionals and upgraded to larger premises with larger accounts. All this is attained through process-driven methodologies</h6>

        <div className='grid md:grid-cols-4 gap-2 mt-5'>
          {we_are_best?.map((s,i)=>(
            <div key={i} className='group bg-white p-5 flex flex-col items-center justify-center cursor-pointer relative'>
                 <img src={s?.img} className='h-[80px] object-contain' />
                  <h6 className='font_bold text-[14px] 2xl:text-[17px] mt-2'>{s?.name}</h6>
                  <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mt-1'>{s?.val}</h6>
            </div>  
          ))}

        </div>

        </div>
      </div>

    </div>
  )
}

export default WhyUs