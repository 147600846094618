import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS-1.png'
import banner2 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS.png'


import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import dimension1 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_DESIGN.png'
import dimension2 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_DESIGN-1.png'
import dimension3 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_DESIGN-2.png'


import layout_plan from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_LAYOUT-PLAN.jpg'

import basement from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans.png'
import ground_floor from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-1.png'
import first_floor from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-2.png'
import typical_floor from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-3.png'

import basement1 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-B.png'
import ground_floor1 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-B-1.png'
import first_floor1 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-B-2.png'
import typical_floor1 from '../../folder/images/exclusive/ozone_chambers/OZONE-CHAMBERS_Floor-Plans-B-3.png'
import GoBack from '../../folder/components/GoBack';


function OzononeChambers() {

  const bannerArr = [banner1,banner2]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold 2xl:text-[15px] text-[#f6821e]'>OZONE CHAMBERS COMMERCIAL SPACE</h6>
        </div>
        <h6 className='font_bold text-[15px] 2xl:text-[28px] text-center md:text-[25px] mt-2 uppercase'>SILK BOARD JUNCTION, BANGALORE</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>NEW DIMENSIONAL DESIGN</h6>
        
        <div className='w-[90%] md:w-[70%] mx-auto'>
            <div className='md:flex flex-row my-2 md:my-5'>
            <img src={dimension1} className='md:w-[50%] h-[240px] object-contain' />
            <h6 className='md:w-[50%] font_normal md:ml-10 text-[14px] 2xl:text-[17px] 2xl:text-[17px] leading-[25px] '>Exploring the concepts of Biophilic, making this a spectacular New generation  building and minimizing the threat on ecological environment. These concepts  have been used to make use of the resources efficiently while producing <span className='font_bold'>less waste</span>.</h6>
            </div>

            <div className='flex-reverse flex-col md:flex md:flex-row my-2 md:my-5'>
            <h6 className='md:w-[50%] font_normal md:mr-10 text-[14px] 2xl:text-[17px] 2xl:text-[17px] leading-[25px] '>A building designed to be part of our environment, part of our context. A  new generation office building away from the concept of fixed design with  the ability to adopt to times in terms of technology.<span className='font_bold'> Aesthetic, habitable,  environmentally responsible and resilient spaces</span>.</h6>
            <img src={dimension2} className='md:w-[50%] h-[240px] object-contain' />
            </div>

            <div className=' md:flex flex-row my-2 md:my-5'>

            <img src={dimension3} className='md:w-[50%] h-[240px] object-contain' />
            <h6 className='font_normal md:w-[50%] md:ml-10 text-[14px] 2xl:text-[17px] 2xl:text-[17px] leading-[25px] '>A state of the art commercial building, redefining the design of  commercial spaces. A building designed inside out starting with the  customer needs of culture, efficiency and much more.</h6>
            </div>
        </div>


      
        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>LAYOUT PLAN</h6>

        <img src={layout_plan} className='w-[80%] mx-auto' />


        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Project Highlights - <span className='text-black'> OZONE CHAMBERS - TOWER 'A'</span></h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px] 2xl:text-[17px]'>TOTAL LEASABLE AREA</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>9,21,365 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px] 2xl:text-[17px]'>Floor plate area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>43,209 Sq.Ft. To 52,306 Sq.Ft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px] 2xl:text-[17px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>3 Basement + Ground + 16 Floor</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px] 2xl:text-[17px]'>Development area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px] 2xl:text-[17px]'>Possession</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ready for Fit-outs</h6>
            </div>

        </div>


        <div className='grid grid-cols-1 py-10 md:grid-cols-4 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>BASEMENT</h6>
            <img src={basement} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>GROUND FLOOR</h6>
            <img src={ground_floor} className='w-[100%] h-[82%] object-cover' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>1ST FLOOR</h6>
            <img src={first_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>TYPICAL FLOOR</h6>
            <img src={typical_floor} />
        </div>

       
        </div>


        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Project Highlights - <span className='text-black'> OZONE CHAMBERS - TOWER 'B'</span></h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>TOTAL LEASABLE AREA</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>7,50,778 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Floor plate area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>43,053 sqft to 46,897 sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>3 Basement + Ground + 18 Floors</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Development area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Possession</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ready for Fit-outs</h6>
            </div>

        </div>


        <div className='grid grid-cols-1 py-10 md:grid-cols-4 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>BASEMENT</h6>
            <img src={basement1} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>GROUND FLOOR</h6>
            <img src={ground_floor1} className='w-[100%] h-[82%] object-cover' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>1ST FLOOR</h6>
            <img src={first_floor1} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>TYPICAL FLOOR</h6>
            <img src={typical_floor1} />
        </div>

       
        </div>

      

        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Location</h6>

        <div className='w-[80%] md:flex items-start  mx-auto '>
            {/* <img src={location_1} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d497771.68560045835!2d77.62642!3d12.91792!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU1JzA0LjUiTiA3N8KwMzcnMzUuMSJF!5e0!3m2!1sen!2sus!4v1720677645163!5m2!1sen!2sus"  className='w-full h-full'    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold 2xl:text-[18px] text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Railway Station – Bangalore Central 14 KM.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Bangalore Airport – 43 KM.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Forum Mall – 4 KM.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Proposed Metro Station – 400 Meters.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Nearest Hotel – Treebo King’s Suites 1.9 KM.</h6>

                 <div>
                    <h6 className='text-[11.5px] 2xl:text-[15.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: Ramdas Rai</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845046730 / Ramdas.rai@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default OzononeChambers