import React from 'react'
import { fidelitus_gallery_website_url, gallerylogo, } from '../../folder/constants/Appconstants'
import { useNavigate } from 'react-router-dom'

function FidelitusGallery() {
  const navigate = useNavigate()

  return (
    <div className='w-[100%]'>
       <div className='relative flex items-center justify-center bg-gallery_banner_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10 absolute w-full h-full flex flex-col  items-center justify-center'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>Fidelitus Gallery</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Fidelitus Gallery </h6>
              <h6 className='text-[13px] 2xl:text-[16px] mt-1 w-[90%] md:w-[50%]  text-white text-center font_normal opacity-80 w-[80%] mx-auto'>The Gallery contributes towards enlightening and enriching society through the arts. It promotes various art forms and showcases impactful paintings. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        <div className='w-[90%] md:w-[80%] mx-auto py-[5%]'>

        <img src={gallerylogo} alt="gallerylogo" className='w-[140px] mb-4' />  
        {/* <h6 data-aos="fade-right" className='font_bold text-[20px]'>Fidelitus Gallery</h6> */}
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>Fidelitus Art Gallery Established in 2014, contributes in a unique way to the field of art. Galleries are the place for artists to experiment and play with their imagination. Fidelitus Art Gallery is the place to find a fresh perspective and original ideas for your concept home interior. Our gallery specialists focus on meeting your unique gallery needs like paintings, fine art, sculptures etc</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>With the intention of making your event more exclusive our gallery spaces offer the perfect venue to suit your need. In a prestigious setting formed by paintings, sculptures and other works of art, you can use the space according to your needs. There is nothing better than inviting your guests to an elegant atmosphere of art exhibitions.</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] 2xl:text-[16px] my-4'>Fidelitus Art Gallery contributes towards enlightening and enriching society through the medium of arts. It promotes various art forms and showcases paintings that leave an impact on the minds of visitors. We offer collector’s high-quality, affordable works from Realistic to Modern, and Contemporary to Abstract. Gallery also contributes 25% of its sales to Shilpa Foundation which works for the welfare of downtrodden children and educating underprivileged children.</h6>


      
      
        <h6 data-aos="fade-bottom" className='text-[13px] 2xl:text-[16px] mt-10 font_normal'>If you would like to get more information about the Fidelitus Gallery visit us <a href={fidelitus_gallery_website_url} target='_blank' className='underline cursor-pointer font_bold text-[#027d9b]'>www.fidelitusgallery.com</a></h6>

        </div>

    </div>
  )
}

export default FidelitusGallery