import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IndustrialSpaces, OfficeSpaces, RetailSpaces, service_section11, service_section12, service_section13, service_section14, service_section15, service_section16, WarehouseSpace } from '../../folder/constants/Appconstants'

function CommercialLeasingAndSales() {
  const navigate = useNavigate()

  const datas = [
          {
            img:OfficeSpaces,
            heading:'Office Spaces',
            description:"We help businesses find office spaces tailored to their unique needs, ensuring functionality and convenience. Our solutions focus on creating productive, well-aligned work environments. Whether for startups or established enterprises, we provide spaces that foster growth and success."
          },
          {
            img:RetailSpaces,
            heading:'Retail Spaces',
            description:"We offer comprehensive retail solutions in prime high-street locations and bustling malls, designed to maximize visibility and attract more foot traffic. Our services are tailored to create engaging customer experiences that drive store visits and boost sales. Whether you're launching a new store or expanding, we help businesses thrive in competitive retail environments."
          },
          {
            img:IndustrialSpaces,
            heading:'Industrial Spaces',
            description:"We specialize in sourcing strategically located industrial facilities tailored for manufacturing and operational needs. Our expertise ensures access to well-connected sites that optimize logistics and efficiency. From small-scale operations to large industries, we provide spaces that support seamless workflows and growth."
          },
          {
            img:WarehouseSpace,
            heading:'Warehouses',
            description:"We provide strategically located warehouses designed to meet diverse business needs for storage and logistics. Our solutions focus on optimizing space utilization, enhancing supply chain efficiency, and ensuring easy accessibility. Whether for short-term inventory or long-term storage, our warehouses support seamless operations and growth."
          },
          // {
          //   img:service_section15,
          //   heading:'Exclusive Marketing ',
          //   description:"Unlock exclusive marketing strategies tailored for corporate real estate with Fidelitus Corp. Our bespoke approach leverages targeted campaigns, industry insights a network of qualified buyers and tenants to showcase your properties to the right audience, ensuring maximise exposure and optimal results."
          // },
          // {
          //   img:service_section16,
          //   heading:'Built to Suit',
          //   description:"We assist you in setting up tailored commercial spaces that fit your business requirements, to ensure you can maximise the operational space. We assist you in expanding your new facility to meet your corporate goals."
          // }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-resedential-banner_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>COMMERCIAL LEASING & SALES</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]  2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Commercial Leasing & Sales </h6>
              <h6 className='text-[13px]  2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        
        <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>

           <h6 className='text-[14px] 2xl:text-[17px] font_normal'>Your business deserves a space that works as hard as you do. At Fidelitus Transaction Services, we go beyond transactions to deliver tailored solutions in office spaces, retail outlets, industrial facilities, and warehouses. Whether you're looking to lease or buy, our expert team combines deep market insights with a hands-on approach to secure the ideal property for your needs.</h6>
           <h6 className='text-[14px] 2xl:text-[17px] font_normal'>With a robust network and a proven track record, we make every deal seamless and rewarding, so you can focus on what matters most—growing your business.</h6>

          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
           {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] 2xl:text-[18px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] mt-3 2xl:text-[17px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default CommercialLeasingAndSales