import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexSpaceValuation, ManagedSpaceAdvisory, service_section11, service_section12, service_section13, service_section14, service_section15, service_section16 } from '../../folder/constants/Appconstants'

function PropertyValuationAdvisory() {
  const navigate = useNavigate()

  const datas = [
          {
            img:FlexSpaceValuation,
            heading:'Flex Space Valuation',
            description:"We offer precise valuations for flexible workspaces, enabling you to evaluate their profitability and potential returns. Our expertise helps align these spaces with current market trends, ensuring they meet demand and maximize value. By providing comprehensive insights, we empower you to make informed decisions that optimize your workspace investments."
          },
          {
            img:ManagedSpaceAdvisory,
            heading:'Managed Space Advisory',
            description:"Our expert insights into managed spaces help you optimize operational efficiency by streamlining processes and enhancing resource utilization. We provide strategies to maximize revenue potential through smart space management and market positioning. With our guidance, you can achieve higher profitability and improved performance across your managed properties."
          },
          // {
          //   img:service_section13,
          //   heading:'Lease Structuring',
          //   description:"Strategic leasing advice for your property; we deliver tailor-made solutions for your corporate needs including lease management, optimising the tenant mix that adds to your bottom line, improving the rental profile and much more. "
          // },
          // {
          //   img:service_section14,
          //   heading:'Lease Renewal and Rent Review ',
          //   description:"To ensure transparency, both parties are encouraged to review the rent/ lease terms with our expertise we ensure a smooth hustle hassle-free transaction."
          // },
          // {
          //   img:service_section15,
          //   heading:'Exclusive Marketing ',
          //   description:"Unlock exclusive marketing strategies tailored for corporate real estate with Fidelitus Corp. Our bespoke approach leverages targeted campaigns, industry insights a network of qualified buyers and tenants to showcase your properties to the right audience, ensuring maximise exposure and optimal results."
          // },
          // {
          //   img:service_section16,
          //   heading:'Built to Suit',
          //   description:"We assist you in setting up tailored commercial spaces that fit your business requirements, to ensure you can maximise the operational space. We assist you in expanding your new facility to meet your corporate goals."
          // }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-property_valuation_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>PROPERTY VALUATION AND ADVISORY </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]  2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Property Valuation & Advisory              </h6>
              <h6 className='text-[13px]  2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        
        <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
          
          <h6 className='text-[14px] 2xl:text-[17px] font_normal'>Navigate the evolving real estate landscape with confidence. Our valuation and advisory services cover everything from flex spaces to managed spaces, providing precise insights that empower smarter decision-making and unlock greater potential for your assets.</h6>

          {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] 2xl:text-[18px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] mt-3 2xl:text-[17px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default PropertyValuationAdvisory