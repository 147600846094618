import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PortofolioOptimization, service_section11, service_section12, service_section13, service_section14, service_section15, service_section16, StrategicPropertyInvestment } from '../../folder/constants/Appconstants'

function InvestmentAdvisory() {
  const navigate = useNavigate()

  const datas = [
          {
            img:StrategicPropertyInvestment,
            heading:'Strategic Property Investments',
            description:"We help clients identify and secure lucrative property investments that align with their financial goals and aspirations. Through meticulous research and a strategic approach, we ensure each investment is not only secure but also primed for sustainable growth. Whether for personal wealth building or portfolio expansion, our solutions deliver long-term value and success."
          },
          {
            img:PortofolioOptimization,
            heading:'Portfolio Optimization',
            description:"Our team excels in optimizing real estate portfolios to achieve maximum returns while effectively mitigating risks. Through detailed analysis of asset performance and market trends, we craft data-driven strategies that enhance efficiency and profitability. Whether restructuring or expanding, we focus on improving portfolio value and securing long-term financial success."
          },
          // {
          //   img:service_section13,
          //   heading:'Lease Structuring',
          //   description:"Strategic leasing advice for your property; we deliver tailor-made solutions for your corporate needs including lease management, optimising the tenant mix that adds to your bottom line, improving the rental profile and much more. "
          // },
          // {
          //   img:service_section14,
          //   heading:'Lease Renewal and Rent Review ',
          //   description:"To ensure transparency, both parties are encouraged to review the rent/ lease terms with our expertise we ensure a smooth hustle hassle-free transaction."
          // },
          // {
          //   img:service_section15,
          //   heading:'Exclusive Marketing ',
          //   description:"Unlock exclusive marketing strategies tailored for corporate real estate with Fidelitus Corp. Our bespoke approach leverages targeted campaigns, industry insights a network of qualified buyers and tenants to showcase your properties to the right audience, ensuring maximise exposure and optimal results."
          // },
          // {
          //   img:service_section16,
          //   heading:'Built to Suit',
          //   description:"We assist you in setting up tailored commercial spaces that fit your business requirements, to ensure you can maximise the operational space. We assist you in expanding your new facility to meet your corporate goals."
          // }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-investment_advisory_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>INVESTMENT ADVISORY</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]  2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Investment Advisory </h6>
              <h6 className='text-[13px]  2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        
        <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>

          <h6 className='text-[14px] 2xl:text-[17px] font_normal'>Smart investments start with informed decisions. Fidelitus Transaction Services provides strategic property investment solutions that align with your financial goals. From portfolio optimization to identifying high-potential opportunities, our expert team ensures every investment is a step towards long-term growth and profitability.</h6>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
           {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] 2xl:text-[18px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] mt-3 2xl:text-[17px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default InvestmentAdvisory