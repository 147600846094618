import React,{useEffect, useState} from 'react'

import banner1 from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE-3.png'
import banner2 from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE.jpg'
import banner3 from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE-2.jpg'

import img1 from '../../folder/images/exclusive/nmr_tech_park/1.jpg'
import img2 from '../../folder/images/exclusive/nmr_tech_park/2.jpg'
import img4 from '../../folder/images/exclusive/nmr_tech_park/4.jpg'
import img5 from '../../folder/images/exclusive/nmr_tech_park/5.jpg'


import video from '../../folder/images/exclusive/nmr_tech_park/NMR+Drone.mp4'

import manufacturing from '../../folder/images/exclusive/nmr_tech_park/manufacturing.jpg'
import energy_infrastructure from '../../folder/images/exclusive/nmr_tech_park/energy_infrastructure.jpg'
import technology from '../../folder/images/exclusive/nmr_tech_park/technology.jpg'

import kiadb_layout from '../../folder/images/exclusive/nmr_tech_park/NMR-GREEN-Layout.jpg'
import catchment_layout from '../../folder/images/exclusive/nmr_tech_park/NMR-GREEN-NEST-Layout.jpg'
import master_layout from '../../folder/images/exclusive/nmr_tech_park/NMR-Green-Nest-MASTER-PLAN.png'
import typical_floor_layout from '../../folder/images/exclusive/nmr_tech_park/NMR-Green-Nest-TYPICAL-FLOOR-PLAN.png'
import ground_floor_layout from '../../folder/images/exclusive/nmr_tech_park/NMR-Green-Nest-GROUND-FLOOR-PLAN.png'
import map from '../../folder/images/exclusive/nmr_tech_park/WhatsApp-Image-2022-06-25-at-12.08.02-PM.jpeg'


import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'
import GoBack from '../../folder/components/GoBack'

function NMR() {

  const bannerArr = [banner1,banner2,banner3]  

  let stringArr = ['The Future of Work Spaces is Here','NMR Tech Park, just 11 kilometers from the International Airport','Major companies like SAP, Boeing, Amazon, Shell , Autoliv, and Foxcon all with spaces ready for business.']
  const [index,setindex] = useState(0)


  useEffect(()=>{
     setInterval(() => {
      if(index < 2){
        setindex(index + 1)
      }else{
        setindex(0)
      }
     }, 2000);
  },[index])

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold 2xl:text-[15px] text-[#f6821e]'>NMR GREEN NEST</h6>
        </div>
        <h6 className='font_bold text-[15px] 2xl:text-[28px] text-center md:text-[25px] mt-2 uppercase'>KIADB AEROSPACE -HARDWARE PARK</h6>
         


      

        <div className='relative md:h-[100vh]'>

        <div class="absolute bg-black/70 w-[45%] md:w-[30%] right-[10%] md:right-[5%] h-[100%] md:h-[100vh] top-0 z-10 text-white px-5">
            <div class="flex flex-col h-[100%] items-center justify-center">
            <h6 data-aos="fade-right" id="animatedSubHead" class="font_bold text-[18px] 2xl:text-[21px] text-[#33d458]  md:text-[45px]">NMR GREENNEST </h6>
            <h6 data-aos="fade-left" data-aos-delay="300" class="font_normal text-[13px] 2xl:text-[28px] md:text-[25px] text-white">{stringArr[index]}</h6>
            </div>
         </div>
        <video className='w-[100%] object-cover h-[100%] my-10 md:w-[100%] mx-auto' src={video} autoPlay loop>

        </video>
        </div>


            <div className='py-10'>
                <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] mt-10'>Overview</h6>
                
                <div className='w-[90%] md:w-[80%] mx-auto'>
                <h6 className='font_normal  mt-5 text-[13.5px] 2xl:text-[16.5px] text-[#000]'><span className='font_bold'>“GREEN NEST”</span> - which is just 11 kms away from the International Airport, would be presented to the Elite Clients as a most promising opportunity for the relevant sectors to opt for.</h6>
                <h6 className='font_normal  mt-5 text-[13.5px] 2xl:text-[16.5px] text-[#000]'><span className='font_bold'>NMR Tech Park,</span> - being advantageously located in the Aerospace-Hardware-IT Park, on a 3 Acre land, has been conceptualized to provide flexibility to be utilized for any type of industry.</h6>
                
                <ul className='md:ml-5 mt-5 list-disc '>
                      <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>NMR is a fully compliant building not only with the National Building Code laws, the building has incorporated the vital elements of IGBC council as well.</li>
                      <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>LEED Certified Gold rated Green Building benefits - environmental.</li>
                      <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Efficiencies are designed on HVAC, Energy, Water and other facilities and amenities.</li>
                      <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Ample benefits on environmentally friendly initiatives.</li>
                      <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Growth of similar sectors in this park, strategically positioned for the growth of the Industry.</li>
                      <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Most economically priced commercials for a long term lease benefits.</li>
                 </ul>


                 <div className='grid my-5 grid-cols-2  md:grid-cols-4 gap-5'>
                  <img src={img1} />
                  <img src={img2} />
                  <img src={img5} />
                  <img src={img4} />

                 </div>
                 </div>

           </div>
      
      <div className='bg-slate-100 py-10'>
          <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] mt-10'>Targeted Sectors</h6>
          <h6 className='font_normal text-[13px] 2xl:text-[16px] text-center text-[#000]'>The current sectors who are intereseted and ready to occupy the space in <span className='font_bold'>NMR GREENNEST</span></h6>
          <div className='grid md:grid-cols-3 gap-5 py-10 w-[70%] mx-auto'>
          <div>
            <div class="h-[200px] overflow-hidden ">
            <img src={manufacturing} class="w-[100%] h-[200px] object-cover hover:scale-125 duration-300 "/>
            </div>
            <h6 className='font_bold mt-2 text-[14px] 2xl:text-[17px]'>Manufacturing</h6>
            <h6 className='font_normal text-[13px] 2xl:text-[16px] mt-[5px]'>Aerospace, Automotive, Machine tools & Heavy Engineering, Pharmaceuticals, Electronics</h6>
          </div>

          <div>
            <div class="h-[200px] overflow-hidden ">
            <img src={technology} class="w-[100%] h-[200px] object-cover hover:scale-125 duration-300 " />
            </div>
            <h6 className='font_bold mt-2 text-[14px] 2xl:text-[17px]'>Technology</h6>
            <h6 className='font_normal text-[13px] 2xl:text-[16px] mt-[5px]'>Information Technology, Bio Technology, Audio Visual, Gaming</h6>
          </div>

          <div>
            <div class="h-[200px] overflow-hidden ">
            <img src={energy_infrastructure} class="w-[100%] h-[200px] object-cover hover:scale-125 duration-300 " />
            </div>
            <h6 className='font_bold mt-2 text-[14px] 2xl:text-[17px]'>Energy & Infrastructure</h6>
            <h6 className='font_normal text-[13px] 2xl:text-[16px] mt-[5px]'>Conventional Energy, Non-conventional Energy, Transportation Infrastructure, Urban Infrastructure, Industrial Infrastructure, Logistics Infrastructure</h6>
          </div>
          </div>
      </div>


        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>6,23,189.83 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>2 Basement + Ground + 8 Floors</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>54,000 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Development area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Possession</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>

        <div className='grid grid-cols-2 py-10 md:grid-cols-5  w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[15px] font_bold p-[4px]'>KIADB LAYOUT PLAN</h6>
            <img src={kiadb_layout} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[15px] font_bold p-[4px]'>CATCHMENT</h6>
            <img src={catchment_layout} className='w-[100%] h-[82%] object-cover' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[15px] font_bold p-[4px]'>MASTER PLAN</h6>
            <img src={master_layout} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[15px] font_bold p-[4px]'>TYPICAL FLOOR PLAN</h6>
            <img src={typical_floor_layout} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[15px] font_bold p-[4px]'>GROUND FLOOR PLAN</h6>
            <img src={ground_floor_layout} />
        </div>

       
        </div>


        <h6 className='font_normal mx-auto w-[90%] mb-10 md:w-[80%] text-center text-[13px] 2xl:text-[15px]'>To get more information about the NMR GREENNEST feel free to download our broucher which is just designed for you , Just click on the below button to download the broucher</h6>
      


        <div className='w-[90%] md:w-[80%] md:flex items-start  mx-auto '>
            <img src={location_1} />
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold 2xl:text-[18px] text-[#4190ac] mb-2'>Near By Occupants</h6>
                 <div className='flex'>
                 <ul className='md:ml-5 mt-5 list-disc '>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>SHELL INDIA MARKETS.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>CRICKET STADIUM.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>BOEING INTERNATIONAL.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>FFG.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>WHITE OAKS – 62.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>CELLCOMM SOLUTIONS LTD.</li>
                 </ul>

                 <ul className='ml-10 md:ml-10 mt-5 list-disc '>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>BIOZEEN ENGINEERING.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>AUTOLIV.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>BECKMAN COULTER.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>SISA SYNERGISTIC.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>ESTUATE SOFTWARE.</li>
                      <li className='font_normal text-[13px] 2xl:text-[15px] mb-1'>OLEO INDIA.</li>
                 </ul>
                 </div>

                 
            </div>
            </div>

            <div className='w-[90%] md:w-[80%] md:flex items-start  my-5 mx-auto '>
            <a className='md:w-[46%] md:-ml-[2px]' href="https://www.google.com/maps/place/NMR+GreenNest/@13.1588432,77.7100079,2983m/data=!3m1!1e3!4m6!3m5!1s0x3bae1d60e9cbe287:0x39a53d11447428c7!8m2!3d13.1587648!4d77.7120056!16s%2Fg%2F11lt58f6vg?entry=ttu" target="_blank">
               <img src={map} />
            </a>
            <div className=' mt-10 md:mt-0 md:ml-10' >
                    <h6 className='text-[11.5px] 2xl:text-[14.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: Mrs. Mohana Laxmi</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845038018 / m.laxmi@fidelituscorp.com </h6>
                    </div>
                 </div>

        </div>
    </div>
    </div>
  )
}

export default NMR