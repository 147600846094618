import React,{useState} from 'react'



import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import banner1 from '../../folder/images/exclusive/cube_software/QUBE-SOFTWARE-PARK-1.png'
import banner2 from '../../folder/images/exclusive/cube_software/QUBE-SOFTWARE-PARK.png'

import location1 from '../../folder/images/exclusive/cube_software/QUBE-SOFTWARE-PARK-RING-ROAD.jpg'


import qube_lower1 from '../../folder/images/exclusive/cube_software/QUBE-SOFTWARE-PARK-LOWER-UPPER-BASEMENT-PLAN.png'
import qube_lower2 from '../../folder/images/exclusive/cube_software/QUBE-SOFTWARE-PARK-GROUND-FLOOR-PLAN.png'
import GoBack from '../../folder/components/GoBack';


function QubeSoftwarePark() {

  const bannerArr = [banner1,banner2]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold 2xl:text-[15px] text-[#f6821e]'>QUBE SOFTWARE PARK</h6>
        </div>
        <h6 className='font_bold text-[15px] 2xl:text-[28px] text-center md:text-[25px] mt-2 uppercase'>BHOGANAHALI, OUTER RING ROAD, BANGALORE</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>3,18,569  Sqft</h6>
            </div>
           
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>2B + Ground + 8 Floors</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Per Floor Area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>38,000 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Development area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Possession</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ready for Fit-outs</h6>
            </div>

        </div>

        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>LOCATION : OUTER RING ROAD ( SARJAPUR – MARATHAHALLI)</h6>

        <div className='w-[90%] md:w-[80%] md:flex mx-auto'>
            <img src={location1} className='md:w-[50%]' />   
            <div className='md:w-[50%] md:ml-10'>
               <h6 className='font_bold text-[14px] 2xl:text-[17px] text-[#000] mt-10 mb-5'>TECH PARKS WITHIN 5 KM RADIUS</h6>
               <ul className='ml-10 list-disc '>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Intel Headquarters</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>RMZ Eco Space</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Vrindavan Tech village</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Prestige Cessna Business Park</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Wipro Corporate Office</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Salarpuria Supreme</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Salarpuria Soft zone</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Salarpuria Hallmark</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Salarpuria Touchstone</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Prestige Tech Park</li>
                 <li className='font_normal text-[13px] 2xl:text-[16px] mb-1'>Embassy Tech Square</li>
               </ul>
            </div>
        </div>



        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Floor Plans</h6>
        <div className='grid w-[90%] md:w-[80%] mx-auto gap-5 md:grid-cols-2'>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>QUBE – LOWER & UPPER BASEMENT PLAN</h6>
            <img src={qube_lower1} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>QUBE – GROUND FLOOR PLAN</h6>
            <img src={qube_lower2} />
        </div>

        </div>
       



      

        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Location</h6>

        <div className='w-[80%] md:flex items-start  mx-auto '>
            {/* <img src={''} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d497740.49447674624!2d77.693448!3d12.933564!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13abb0f20af7%3A0x5b3f16be7f48ee12!2sQube%20Software%20Park%2C%20Bhoganhalli%2C%20Bengaluru%2C%20Karnataka%20560103%2C%20India!5e0!3m2!1sen!2sus!4v1720677567825!5m2!1sen!2sus"  className='w-full h-full'    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold 2xl:text-[18px]  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>15 Kms from MG Road.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>09 Kms from Koramangala.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>12 Kms from ITPL.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>08 Kms from Silk Board.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>18 Kms from Electronic City.</h6>

                 <div>
                    <h6 className='text-[11.5px] 2xl:text-[14.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: Mr. Shreeshail M Kotagi</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845048470 / shreeshail.kotagi@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default QubeSoftwarePark