import React from 'react'
import { useNavigate } from 'react-router-dom'
import { service_section21, service_section22, service_section23, service_section24, service_section25, service_section26 } from '../../folder/constants/Appconstants'

function LandInvestment() {
  const navigate = useNavigate()

  const datas = [
          {
            img:service_section21,
            heading:'Land Transactions',
            description:"Our comprehensive services cover every aspect, from acquisition to disposition, ensuring smooth and successful transactions. Trust us to navigate the complexities of land investment with precision, unlocking opportunities and maximizing value at every step."
          },
          {
            img:service_section22,
            heading:'Development and disposal of Land',
            description:"We specialise in maximizing value through strategic development and efficient disposal strategies. Trust us to guide you through every stage, from initial planning to final transaction, ensuring optimal returns on your land investments. "
          },
          {
            img:service_section23,
            heading:'Structuring Joint Ventures',
            description:"Our approach combines industry expertise with strategic matchmaking, facilitating mutually beneficial collaborations that leverage resources and expertise for maximum returns. Trust us to navigate joint ventures with precision, unlocking new opportunities and driving success in land investment ventures."
          },
          {
            img:service_section24,
            heading:'Land Portfolio Management',
            description:"Our tailored approach encompasses strategic planning, risk assessment, and optimization strategies to ensure your land portfolio aligns with your investment goals. Trust us to navigate the complexities of land investment, unlocking opportunities for growth and success."
          },
          {
            img:service_section25,
            heading:'Commercial Property Portfolio Management',
            description:"Our tailored solutions for land investment encompass strategic planning, asset analysis, and risk mitigation, ensuring maximum returns and long-term value appreciation. Trust us to oversee your commercial property portfolio with precision, unlocking the full potential of your land investments."
          },
          {
            img:service_section26,
            heading:'Pre leased Investments',
            description:"Our specialized services offer meticulously vetted properties with existing leases, providing stable returns and hassle-free ownership. Trust us to guide you through the process, unlocking the potential of pre-leased land investments for long-term financial growth and security."
          },
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-land-investment-banner_bg bg-bottom bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
          <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[5px] font_thicker opacity-80'>LAND INVESTMENT </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]  2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Land Investment </h6>
              <h6 className='text-[13px]  2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        
         <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
           {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] 2xl:text-[18px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] 2xl:text-[17px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default LandInvestment