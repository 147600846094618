import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ComplianceManagement, EndToEndSupport, ExpertNegotiation, service_section11, service_section12, service_section13, service_section14, service_section15, service_section16 } from '../../folder/constants/Appconstants'

function TransactionManagement() {
  const navigate = useNavigate()

  const datas = [
          {
            img:EndToEndSupport,
            heading:'End-to-End Support',
            description:"We provide end-to-end management for your transactions, overseeing every stage from initial planning to successful closing. Our expert team ensures seamless coordination, precise execution, and attention to detail at every step. With a commitment to excellence, we deliver a hassle-free experience, saving you time and ensuring optimal outcomes."
          },
          {
            img:ExpertNegotiation,
            heading:'Expert Negotiation',
            description:"Tap into our deep market knowledge and expert negotiation skills to secure the most favorable deals for your business. We tailor each agreement to align with your specific goals, ensuring the best possible terms and conditions. Our approach focuses on maximizing value while meeting your unique objectives and requirements."
          },
          {
            img:ComplianceManagement,
            heading:'Compliance Management',
            description:"With our meticulous approach to legal and regulatory compliance, we ensure that every aspect of your transaction is fully protected. Our team navigates complex legal landscapes, safeguarding your interests and minimizing potential risks. You can stay confident knowing that all compliance requirements are thoroughly addressed throughout the process."
          },
          // {
          //   img:service_section14,
          //   heading:'Lease Renewal and Rent Review ',
          //   description:"To ensure transparency, both parties are encouraged to review the rent/ lease terms with our expertise we ensure a smooth hustle hassle-free transaction."
          // },
          // {
          //   img:service_section15,
          //   heading:'Exclusive Marketing ',
          //   description:"Unlock exclusive marketing strategies tailored for corporate real estate with Fidelitus Corp. Our bespoke approach leverages targeted campaigns, industry insights a network of qualified buyers and tenants to showcase your properties to the right audience, ensuring maximise exposure and optimal results."
          // },
          // {
          //   img:service_section16,
          //   heading:'Built to Suit',
          //   description:"We assist you in setting up tailored commercial spaces that fit your business requirements, to ensure you can maximise the operational space. We assist you in expanding your new facility to meet your corporate goals."
          // }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-transaction_management_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>TRANSACTION MANGEMENT</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]  2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Transaction Management </h6>
              <h6 className='text-[13px]  2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        
        <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>

          <h6 className='text-[14px] 2xl:text-[17px] font_normal'>Simplify the complexities of real estate transactions with Fidelitus. From end-to-end support to expert negotiation and compliance management, we handle every detail, ensuring a seamless and stress-free process. With us, your focus remains on your business while we deliver results.</h6>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
          {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] 2xl:text-[18px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] mt-3 2xl:text-[17px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default TransactionManagement