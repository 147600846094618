import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LeaseNegotiate, MarketResearch, service_section11, service_section12, service_section13, service_section14, service_section15, service_section16, SpaceOptimization } from '../../folder/constants/Appconstants'

function TenantRepresentation() {
  const navigate = useNavigate()

  const datas = [
          {
            img:LeaseNegotiate,
            heading:'Lease Negotiation',
            description:"We use our in-depth knowledge of market dynamics and lease negotiations to secure favorable terms tailored to your business needs. Our team carefully evaluates every aspect of the lease agreement to ensure optimal financial and operational outcomes. By leveraging our expertise, we help you secure the best deal, enhancing your business's long-term success."
          },
          {
            img:SpaceOptimization,
            heading:'Space Optimization',
            description:"Our team works closely with you to ensure that the chosen property perfectly matches your operational requirements, optimizing both functionality and efficiency. We assess factors such as layout, accessibility, and space utilization to create an ideal work environment. By aligning the property with your business goals, we help streamline operations and support long-term success."
          },
          {
            img:MarketResearch,
            heading:'Market Research',
            description:"Through thorough market analysis, we pinpoint properties that precisely align with your business needs, ensuring a perfect fit. Our approach saves you time by filtering out unsuitable options and focuses only on high-potential properties. By leveraging our expertise, we help you make informed decisions, maximizing both time and resource efficiency."
          },
          // {
          //   img:service_section14,
          //   heading:'Lease Renewal and Rent Review ',
          //   description:"To ensure transparency, both parties are encouraged to review the rent/ lease terms with our expertise we ensure a smooth hustle hassle-free transaction."
          // },
          // {
          //   img:service_section15,
          //   heading:'Exclusive Marketing ',
          //   description:"Unlock exclusive marketing strategies tailored for corporate real estate with Fidelitus Corp. Our bespoke approach leverages targeted campaigns, industry insights a network of qualified buyers and tenants to showcase your properties to the right audience, ensuring maximise exposure and optimal results."
          // },
          // {
          //   img:service_section16,
          //   heading:'Built to Suit',
          //   description:"We assist you in setting up tailored commercial spaces that fit your business requirements, to ensure you can maximise the operational space. We assist you in expanding your new facility to meet your corporate goals."
          // }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-tenant_representation_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[5px] font_thicker opacity-80'>TENANT REPRESENTATION </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]  2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Tenant Representation </h6>
              <h6 className='text-[13px]  2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>
        
        
        <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>

          <h6 className='text-[14px] 2xl:text-[17px] font_normal'>Finding the perfect space begins with the right representation. At Fidelitus, we specialize in lease negotiation, space optimization, and in-depth market research to secure properties that meet your unique needs. We advocate for you every step of the way, ensuring optimal terms and maximum value.
          </h6>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
           {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] 2xl:text-[18px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] mt-3 2xl:text-[17px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default TenantRepresentation