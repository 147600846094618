

import {BiLogoLinkedin} from 'react-icons/bi'
import {IoLogoWhatsapp} from 'react-icons/io'
import {IoMdCall} from 'react-icons/io'
import {AiOutlineMail} from 'react-icons/ai'
import CountUp from 'react-countup';


// import about_img from '../folder/images/aboutimage.avif'
import about_img from '../folder/images/brigadepark.jpeg'

// import ValueImg from '../../folder/images/value.png' 
// import ExperitseImg from '../../folder/images/expertise.png' 
// import VisitorImg from '../../folder/images/placeholder.png' 
// import SelectedImg from '../../folder/images/guest-list.png' 
import Typist from 'react-typist';


// import where_we_Add_value1 from '../../folder/images/WHERE-WE-ADD-VALUE1.png'
// import where_we_Add_value2 from '../../folder/images/WHERE-WE-ADD-VALUE2.png'
// import where_we_Add_value3 from '../../folder/images/WHERE-WE-ADD-VALUE3.png'


import sandeep from '../folder/images/teams/sandeep.png'
import pramod from '../folder/images/teams/pramod.png'
import ramdas from '../folder/images/teams/ramdas1.png'
import deepa from '../folder/images/teams/deepa.png'
import shreeshail from '../folder/images/teams/shreeshail1.png'
import abhith from '../folder/images/teams/abhith.png'
import { useNavigate } from 'react-router-dom'

//why we succeed
import cloud from '../folder/images/services/we_are_best/cloud-database.png'
import conversation from '../folder/images/services/we_are_best/conversation.png'
import dedication from '../folder/images/services/we_are_best/dedication.png'
import on_time from '../folder/images/services/we_are_best/on-time.png'


import expertise1 from '../folder/images/services/we_are_best/11.png'
import expertise2 from '../folder/images/services/we_are_best/22.png'
import expertise3 from '../folder/images/services/we_are_best/33.png'
import expertise4 from '../folder/images/services/we_are_best/44.png'
import expertise5 from '../folder/images/services/we_are_best/55.png'
import expertise6 from '../folder/images/services/we_are_best/66.png'
import expertise7 from '../folder/images/services/we_are_best/77.png'

import { achuthGowda } from '../folder/constants/Appconstants';



function About() {

  const navigate = useNavigate()

  const employee = [
    // {
    //   name:'Achuth Gowda',
    //   des:'Founder & Managing Director',
    //   linkedIn:'https://in.linkedin.com/in/achuth-gowda-mrics-88b9a26',
    //   img:achuthGowda,
    //   whatsapp:'https://api.whatsapp.com/send?phone=919845083654&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
    //   gmail:'mailto:achuth.gowda@fidelituscorp.com',
    //   phone:'tel:+91 9845083654'
    // },
    {
      name:'PRAMOD  GP',
      des:'Director - Transaction Services',
      linkedIn:'https://www.linkedin.com/in/pramod-gp-3805a592/',
      img:pramod,
      whatsapp:'https://api.whatsapp.com/send?phone=919845083654&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
      gmail:'mailto:pramod.gp@fidelituscorp.com',
      phone:'tel:+91 9845083654'
    },
        {
          name:'SANDEEP JAYARAJ',
          des:'Director - Transaction Services',
          linkedIn:'https://www.linkedin.com/in/sandeep-jayaraj-7bba1b1b1/',
          img:sandeep,
          whatsapp:'https://api.whatsapp.com/send?phone=919845036943&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:sandeep.jayraj@fidelituscorp.com',
          phone:'tel:+91 9845036943',
        },
       
        {
          name:'DEEPA KRISHNAREDDY',
          des:'VP – Transaction Services',
          linkedIn:'https://www.linkedin.com/in/deepa-krishnareddy-9870aa180/',
          img:deepa,
          whatsapp:'https://api.whatsapp.com/send?phone=919591847914&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:deepa.k@fidelituscorp.com',
          phone:'tel:+91 9591847914'
        },
        {
          name:'SHREESHAIL M KOTAGI',
          des:'VP - Transaction Services',
          linkedIn:'https://www.linkedin.com/in/shreeshail-kotagi-35980511a/',
          img:shreeshail,
          whatsapp:'https://api.whatsapp.com/send?phone=919591847914&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:shreeshail.kotagi@fidelituscorp.com',
          phone:'tel:+91 9591847914'
        },
        {
          name:'RAMDAS RAI',
          des:'VP -Transaction Services',
          linkedIn:'https://www.linkedin.com/in/ramdas-rai-708928136/',
          img:ramdas,
          whatsapp:'https://api.whatsapp.com/send?phone=919845090850&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:ramdas.rai@fidelituscorp.com',
          phone:'tel:+91 9845090850'
        },
        {
          name:'ABHITH KUMAR M P',
          des:'VP – Transactions Services',
          linkedIn:'https://www.linkedin.com/in/abhith-kumar-m-p-b543787a/',
          img:abhith,
          whatsapp:'https://api.whatsapp.com/send?phone=919591847914&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:abhith.kumar@fidelituscorp.com',
          phone:'tel:+91 9591847914'
        }
  ]

  




  ///// pending icon 



  const we_are_best = [
    {
      img:expertise1,
      name:'Commercial Leasing & Sales'
    },
    {
      img:expertise2,
      name:'Investment Advisory '
    },
    {
      img:expertise3,
      name:'Transaction Management'
    },
    {
      img:expertise4,
      name:'Tenant Representation'
    },
    {
      img:expertise5,
      name:'Land Lease & Built-To-Suit Sales'
    },
    {
      img:expertise6,
      name:'Property Valuation & Advisory'
    },
    {
      img:expertise7,
      name:'Corporate Real Estate Solutions'
    }
  ]


 


  return (
    <div className="w-full h-full overflow-x-hidden relative z-0">

     

      <div className='relative flex items-center justify-center bg-about_bg bg-top bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
        <div className='z-10'>
          <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[7px] font_thicker opacity-80'>ABOUT US </h6>
          <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / About Us </h6>
          <h6 className='text-[13px] 2xl:text-[16px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>Delivering tailored real estate solutions, faster and smarter. </h6>
        </div>
        <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
        </div> 
      </div>


      <div className="w-[90%] bg-home_trans_bg bg-no-repeat bg-right-bottom md:w-[80vw] md:flex mx-auto pt-20 ">
          <div className='md:w-[50%] '>
            <img   width={500} height={500} src={about_img} />
          </div>
          <div className='mt-10 md:w-[50%] md:mt-0 '>
          <h6 data-aos="fade-left" className="text-left  font-[400] font_bold text-[24px] 2xl:text-[27px]">About  <span className='text-[#4190ac]'>Us</span></h6>
          <h6 data-aos="fade-left" className='text-[14px] 2xl:text-[17px] text-left  mt-5 font_normal '>Fidelitus Transaction Services is redefining the way businesses navigate commercial real estate. From leasing and acquisitions to investment strategy, we provide cutting-edge, client-focused solutions that streamline transactions. Our expert team leverages deep industry insights to deliver a customised, data-driven approach, ensuring seamless experiences and maximised returns. Built on a foundation of transparency and innovation, Fidelitus Transaction Services is your trusted partner, committed to transforming real estate challenges into growth opportunities with a forward-thinking approach.</h6>
          </div>  
      </div>
      <div className='border-t  border-gray-200 mt-5   md:pb-0'>
          <div className='w-[90%] md:w-[70%]  grid grid-cols-2 md:grid-cols-4 mx-auto'>
          <div className='p-2 flex flex-col items-center justify-center w-full py-5 bg-white  border-r border-gray-100'>
               <h6 className='text-center text-[#4190ac] font_bold  text-[30px] 2xl:text-[33px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={2.5} end={20} />M+</h6>
               <h6 className='text-[12px] 2xl:text-[15px] text-center font-[500] font_normal text-slate-700 uppercase'>Total Sq.Ft areas</h6>
             </div>

             <div className='p-2 flex flex-col items-center justify-center w-full py-5 bg-white sm:border-none md:border-r border-gray-100 '>
               <h6 className='text-center  text-[#4190ac] font_bold text-[30px] 2xl:text-[33px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={2.5} end={1000} />+</h6>
               <h6 className='text-[12px] 2xl:text-[15px] text-center font-[500] font_normal text-slate-700 uppercase'>Clients Served</h6>
             </div>

             <div className='p-2 flex flex-col border-t items-center justify-center w-full py-5 bg-white md:border-l border-r border-gray-100'>
               <h6 className='text-center text-[#4190ac] font_bold text-[30px] 2xl:text-[33px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={4} end={390} />+</h6>
               <h6 className='text-[12px] 2xl:text-[15px] text-center font-[500] font_normal text-slate-700 uppercase'>Property Sold</h6>
             </div>

             <div className='p-2 flex flex-col border-t items-center justify-center w-full py-5 bg-white  border-gray-100'>
               <h6 className='text-center text-[#4190ac] font_bold text-[30px] 2xl:text-[33px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={5} separator="," end={11} />+</h6>
               <h6 className='text-[12px] 2xl:text-[15px] text-center font-[500] font_normal text-slate-700 uppercase'>Years In Industry</h6>
             </div>
          </div>
       </div> 


      <div className='bg-slate-100 md:px-[11.65%] mt-0'>
          <div className="w-[90%] md:w-[80vw] md:flex justify-between mx-auto ">
              <div data-aos="fade-right"  className='w-full md:w-[48%] md:border-r border-b md:border-b-0 pr-[5%] py-10 md:py-10'>
                  <h1 className='text-[16px] 2xl:text-[19px] border-l-4 border-[#e58c3d] font_bold pl-2'>Our Vision</h1>
                  <h6 className='text-[13px] 2xl:text-[16px] mt-5 pl-2 text-black font_normal'>To be a leader in creating meaningful connections across diverse business sectors, uniting under one brand to drive positive impact. Through innovation, collaboration, and social responsibility, Fidelitus Transaction Services aims to leave a legacy of excellence and purpose in every community it serves.                  </h6>
              </div>

              <div data-aos="fade-left" className='w-full md:mt-0 md:w-[48%] py-10 md:py-10'>
                  <h1 className='text-[16px] 2xl:text-[19px] border-l-4 border-[#e58c3d] font_bold pl-2'>Our Mission</h1>
                  <h6 className='text-[13px] 2xl:text-[16px] mt-5 pl-2 text-black font_normal'>At Fidelitus Transaction Services, we aim to empower businesses and communities with comprehensive, end-to-end solutions. Driven by a commitment to quality, we design solutions that enhance functionality, comfort, and safety. Our goal is to enrich spaces where individuals and organizations can thrive.                  </h6>
              </div>
          </div>
        
      </div>

      <div >
        <h6 data-aos="fade-left" className='text-center pt-10 font_bold text-[16px] 2xl:text-[19px]'>Bringing Value Through Quality, Expertise, and Access</h6>

        <h6 data-aos="fade-left" className='text-[13px] 2xl:text-[16px] w-[90%] md:w-[90vw] mx-auto font_normal text-center mt-5  text-slate-700' >At <span className='text-[#4190ac] font_bold'>Fidelitus Real Estate Transactions</span>, we take pride in enriching your association with us with our unwavering commitment to quality, strong expertise, and access to industry insights. Here&apos;s how we stand out</h6>

        <div className='py-20 border-b border-slate-100 items-center bg-home_trans_bg bg-no-repeat bg-right-bottom justify-between w-[100%] mx-auto'>

      


        <div className='w-[100%]  md:w-[80%] mx-auto'>

          {/* <h6 data-aos="fade-right" className='text-[13px] font_normal ml-6 text-gray-400 mb-2'>We believe in hard work. </h6> */}
          <h6 data-aos="fade-right" className='font_bold text-[25px] 2xl:text-[28px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>We Believe</h6>
          <h6 data-aos="fade-up" className='font_normal px-8 text-[14px] 2xl:text-[17px] mb-20'>Our expertise in the Indian real estate market allows us to seamlessly integrate global standards into every client project. We specialize in crafting advanced workspace solutions and maintaining a database of prime office floors ready for new ventures. From assisting with office relocations to managing expansions, we ensure that each office location is strategically selected for maximum benefit. 
          </h6>

          <h6 data-aos="fade-up" className='font_normal px-8 text-[14px] 2xl:text-[17px] mb-20'>With state-of-the-art facilities and tailored interiors, we transform empty spaces into productive work environments. Our skilled team is equipped to handle every requirement, ensuring a smooth and efficient experience for our clients.

          </h6>


          <h6 data-aos="fade-right" className='text-[13px] 2xl:text-[16px] font_normal ml-6 md:ml-6 text-gray-400 mb-2'>We have strong and skilled team that ensures client satisfaction. </h6>
          <h6 data-aos="fade-right" className='font_bold text-[25px] 2xl:text-[28px] border-l-[5px] border-l-[#4190ac] ml-5 md:ml-5 mb-3 pl-4 '>Our Expertise           </h6>
          <h6 data-aos="fade-up" className='font_normal text-[14px] 2xl:text-[17px] ml-5 md:ml-3 w-[90%] md:pl-4'>We provide a full suite of specialized services to meet the dynamic needs of businesses across industries. Whether you’re looking to lease, buy, or sell commercial properties, our expertise ensures seamless transactions that drive value and success. We focus on delivering customized, industry-specific strategies designed to maximize returns and optimize your real estate investments.</h6>

          <div className='w-[80%] md:w-[100%] mx-auto grid grid-cols-2 md:grid-cols-5 gap-5 mt-10 md:mt-5'>
            {we_are_best?.map((s,i)=>(
              <div  data-aos="fade-up" key={i} className='group shadow-lg mix-blend-multiply bg-white p-5 flex flex-col items-center justify-center cursor-pointer relative'>
                  <img src={s?.img} className='h-[80px] object-contain mix-blend-multiply' />
                    <h6 className='font_bold text-[13px] 2xl:text-[16px] text-center mt-2'>{s?.name}</h6>
                    {/* <h6 className='font_normal text-[12.5px] mt-1'>{s?.val}</h6> */}
              </div>  
            ))}

          </div>

        </div>
        </div>
        
      </div>



      <div className='md:flex items-center border-b border-slate-100 justify-center md:w-[100vw] px-[5%] md:px-[10%] py-[5%] mx-auto mt-10'>
        <div className='md:w-[50%] h-[110%]'>
        <img alt="achuthGowda" src={achuthGowda} className='overflow-hidden  w-[100%] h-[120%] object-cover mx-auto  mt-4 transition duration-500 cursor-pointer  mix-blend-multiply' />
        </div>
        <div className='md:w-[70%] mt-10 md:ml-10'>
            <h6 className="text-[17px] 2xl:text-[20px] font_bold">Achuth Gowda</h6>
            <h6 className="text-[10px] 2xl:text-[13px] text-slate-400 font_bold">Founder and Managing Director</h6>
            {/* <h6 data-aos="fade-up" className="text-[14px] mt-4 font_normal">Professional Member of The Royal Institution of Chartered Surveyors (MRICS) has a vision to make Fidelitus Corp a leading service provider in corporate real estate industry across verticals. He provides leadership and direction to ensure the company achieves its strategic objectives. </h6> */}
            <h6 data-aos="fade-up" className="text-[14px] 2xl:text-[17px] mt-4 font_normal">With nearly two decades of experience in corporate real estate, Achuth Gowda is at the helm of Fidelitus, driving its strategic vision and growth. Since starting his real estate career in 2004, he has developed expertise across diverse asset classes and is a proponent of leveraging real estate technology to stay ahead of industry trends.             </h6>

            <h6 data-aos="fade-up" className="text-[14px] 2xl:text-[17px] mt-4 font_normal">Achuth has played a pivotal role in completing transactions spanning millions of square feet and has successfully overseen over 35+ land development projects. His experience includes collaborating with investors, landlords, and tenants across various regions, helping navigate the complexities of commercial real estate.
            </h6>
        </div>
      </div>

      {/* bg-slate-100  */}

      <div className="w-[100%] md:w-[100vw] px-[5%] md:px-[10%] py-[5%] mx-auto mt-10 ">
          <h6 data-aos="fade-left" className="text-center  font-[400] font_bold text-[24px]">Meet Our <span className='text-[#4190ac]'>Expertise</span></h6>
          <h6 data-aos="fade-left" className="text-[14px]  text-center my-5 text-slate-700 font_normal">We believe our strength is in our people. We have assembled the best people in the Real Estate service industry, who all share a common passion for delivering excellent service to our colleagues and clients. We all believe that national Real Estate service should be easy.</h6>
          
          <div className="grid grid-cols-2  gap-y-2 md:gap-x-5 md:grid-cols-3 md:py-10 md:px-[10%]">
          
          {employee?.map((e,i)=>(
            <div data-aos="fade-up" className='bg-white border border-[#f3f3f3] md:mb-2 p-2 relative'>
               {/* bg-[#d8d8d8] */}
            <div  key={i} className='  w-[100%]   grid border-[#f3f3f3] place-items-center relative overflow-hidden   md:mx-0 pb-2'> 
              <img alt={e?.name} src={e?.img} className='overflow-hidden border rounded-full w-[150px] h-[150px] object-cover mx-auto   transition duration-500 cursor-pointer  mix-blend-multiply' />
            </div>  
            {/* backdrop-filter  bg-white/60 backdrop-blur-sm p-1  */}
            <div className='md:p-2 w-[90%] mx-auto '>
                      <h6 className='text-[11px] md:text-[12px] 2xl:text-[15px] text-[#000] text-center capitlaize  mt-0 font_bold font-[700]'>{e?.name}</h6>
                      <h6 className='text-[11px] md:text-[12px] 2xl:text-[16px] text-center text-[#000] font_normal  font-[500]'>{e?.des}</h6>

                     
              </div>  
              </div>
          ))}


 {/* <div className='border-t mt-2 text-black min-w-[120px] w-[100%]'>
                      <span className='flex justify-center mt-2 w-[80%] mx-auto text-slate-400'>
                          <a className='h-full' href={e?.linkedIn} target='_blank'>
                          <BiLogoLinkedin size={20} className='px-[2px] mx-[10px] text-black hover:text-white p-[4px] hover:bg-[#4190ac] cursor-pointer' />
                          </a>
                          <a className='h-full' href={e?.gmail} target='_blank'>
                          <AiOutlineMail size={20} className='px-[2px] mx-[10px] text-black hover:text-white p-[4px] hover:bg-[#4190ac] cursor-pointer' />
                          </a>
                          <a className='h-full' href={e?.phone} target='_blank'>
                          <IoMdCall className='px-[2px] cursor-pointer' />
                          </a>
                          <a className='h-full' href={e.whatsapp} target='_blank'>
                          <IoLogoWhatsapp className='px-[2px] cursor-pointer' />
                          </a>

                      </span>
                      </div>  */}

          </div>

        {/* <h6 data-aos="fade-left" className='my-10 font_bold md:mt-0 text-[14px]  text-center w-[90%] md:w-[90%] md:w-[100%] mx-auto  ' >&quot; Team Leaders who motivate there team even in their bad time are the true leaders but we are fortunate to have many people like them. &quot;</h6> */}

      </div>





    
   


    

    </div>
  )
}

export default About