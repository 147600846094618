import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/villa_plots/banner1.jpg'
import banner2 from '../../folder/images/exclusive/villa_plots/banner2.jpg'
import banner3 from '../../folder/images/exclusive/villa_plots/banner3.jpg'

// import location_1 from '../../folder/images/exclusive/villa_plots/Location1.jpg'

import amenities1 from '../../folder/images/exclusive/villa_plots/amenities1.png'
import amenities2 from '../../folder/images/exclusive/villa_plots/amenities2.png'
import amenities3 from '../../folder/images/exclusive/villa_plots/amenities3.png'
import amenities4 from '../../folder/images/exclusive/villa_plots/amenities4.png'
import amenities5 from '../../folder/images/exclusive/villa_plots/amenities5.png'
import amenities6 from '../../folder/images/exclusive/villa_plots/amenities6.png'
import amenities7 from '../../folder/images/exclusive/villa_plots/amenities7.png'
import amenities8 from '../../folder/images/exclusive/villa_plots/amenities8.png'


import educational_institutional_catchment from '../../folder/images/exclusive/villa_plots/educational_institutional_catchment.jpg'
import near_by_hospital from '../../folder/images/exclusive/villa_plots/near_by_hospital.jpg'

import pickleball_court from '../../folder/images/exclusive/villa_plots/pickleball_court.jpg'
import basketball_court from '../../folder/images/exclusive/villa_plots/basketball_court.jpg'
import club_house from '../../folder/images/exclusive/villa_plots/club_house.jpg'



import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack'

function VillaPlots() {

  const bannerArr = [banner1,banner2,banner3]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack />  
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold 2xl:text-[15px] text-[#f6821e]'>VILLA PLOTS</h6>
        </div>
        <h6 className='font_bold text-[15px] 2xl:text-[28px] text-center md:text-[25px] mt-2'>AT BANNERGHATTA ROAD, BENGALURU</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-left text-[#4190ac] px-[10%] my-10'>DREAMCATCHERS - YOUR OASIS ON BANNERGHATTA ROAD</h6>
        <h6 className='font_normal text-[14px] 2xl:text-[17px] mt-[-25px] w-[100%] px-[10%] text-left'>Escape the city chaos and embrace tranquility at Dreamcatchers. Nestled in the heart of Bannerghatta Road, our exclusive villa plots offer a perfect blend of luxury and sophistication across 3 acres of mesmerizing landscape within the city.</h6>



        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>ELEVATE YOUR LIFESTYLE</h6>
        <h6 className='font_normal text-[14px] 2xl:text-[17px] mt-[-25px] w-[100%] text-center'>Indulge in an unparalleled lifestyle with a plethora of thoughtfully crafted amenities</h6>

        <div className='grid grid-cols-2 gap-4 py-10 md:grid-cols-3 w-[90%] md:w-[80%] mx-auto md:gap-5'>
            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px] uppercase'>Pickleball Court</h6>
                <img src={pickleball_court} />
            </div>

            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Basketball Court</h6>
                <img src={basketball_court} />
            </div>

            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Club House</h6>
                <img src={club_house} />
            </div>
        </div>
       
        <h6 className='text-center font_bold text-[18px] 2xl:text-[21px] text-[#4190ac] my-10'>AMENITIES</h6>
        
        <div className='grid grid-cols-2 gap-4 pb-10 md:grid-cols-6 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px] uppercase'>Rooftop Swimming Pool</h6>
            <img src={amenities1} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Game Room</h6>
            <img src={amenities2} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Gym</h6>
            <img src={amenities3} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Café</h6>
            <img src={amenities4} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Pickleball Court</h6>
            <img src={amenities5} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Basketball Court</h6>
            <img src={amenities6} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Play Area</h6>
            <img src={amenities7} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>Open-air Gym /Callisthenic</h6>
            <img src={amenities8} />
        </div>
        </div>

        <div className='grid grid-cols-2 pb-10 md:grid-cols-2 w-[90%] md:w-[80%] mx-auto md:gap-5'>
            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px] uppercase'>EDUCATIONAL INSTITUTIONS CATCHMENT</h6>
                <img src={educational_institutional_catchment} />
            </div>
            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px] uppercase'>NEAR BY HOSPITALS</h6>
                <img src={near_by_hospital} />
            </div>
         </div>   


        <div className='w-[80%] md:flex items-start  mx-auto '>
            <div className='md:w-[50%]  h-[400px]'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d497908.60141653306!2d77.582972!3d12.849028!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDUwJzU2LjUiTiA3N8KwMzQnNTguNyJF!5e0!3m2!1sen!2sus!4v1720680072732!5m2!1sen!2sus"  className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold 2xl:text-[18px] text-[#4190ac] mb-2'>NEARBY CATCHMENTS</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Schools – Candor International School Greenwood High</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Hospitals – Fortis Hospital, Apollo Hospital</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Malls – Stayfit HUB Fitness Centre</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Fitness Hubs – Royal Meenakshi Mall Forum mall</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Supermarket – Dmart</h6>

                 <div>
                    <h6 className='text-[11.5px] 2xl:text-[14.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: Abhith MP</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845095202 / abhith.kumar@fidelituscorp.com </h6>
                    </div>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: Bhavya B</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845042589 / bhavya.b@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>

  )
}

export default VillaPlots