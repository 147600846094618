import React,{useState,useEffect} from 'react'
import { blogsArr } from '../folder/constants/Appconstants'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GetBlogServices } from '../folder/services/BlogServices'
import { Pagination } from 'antd'


function Blogs() {

  const navigate = useNavigate()  

  const [datas,setdatas] = useState({datas:[]})
  const [page,setpage] = useState(1)

  useEffect(()=>{
    getData()
  },[page])

  async function getData(){
    const response = await GetBlogServices(page,'')
    setdatas(response?.data)
  }


  async function openDetail(v){
    navigate(`/blogs/detail/${v?._id}`)
  }


  return (
    <div className="w-full h-full overflow-hidden">
        <div className='relative flex items-center justify-center bg-blogs-banner_bg bg-bottom bg-center h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[7px] font_thicker opacity-80'>OUR BLOGS </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Our Blogs </h6>
              <h6 className='text-[13px] 2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>Read our success stories that inspires you!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>

         <div className='grid grid-cols-1  mx-[5%] md:mx-[20%] my-[5%] md:grid-cols-3 md:gap-5'>
            {/* {blogsArr?.map((b,i)=>(
                <div className='mb-5 md:mb-0' data-aos="fade-up" onClick={()=>openDetail(b)}>
                   <img src={b?.img} className='h-[170px] w-[100%]' />
                   <h6 className='font_bold text-[13px] mt-3'>{b?.heading}</h6>
                   <h6 className='truncate font_normal text-gray-500 text-[12px] mt-1'>{b?.short_description}</h6>
                   <div className='flex items-start mt-1 border-t pt-1.5 border-gray-100'>
                    {(b?.written_image !== null && b?.written_image !== undefined) &&
                    <img src={b?.written_image} className='w-[30px] h-[30px] object-contain bg-slate-100 rounded-full' />}
                    <div className='ml-2 -mt-1'>
                    {(b?.written_image !== null && b?.written_image !== undefined) && <h6 className='font_bold  text-[#4190ac] text-[12px] mt-1'>{b?.written_by}</h6>}
                    <div className='flex items-center -mt-1'>
                        <h6 className='font_normal  text-[10px] mt-1'>{moment(b?.date)?.format('LL')}</h6>
                        <h6 className=' ml-2 font_bold  text-[10px] mt-1'>{b?.read}</h6>
                    </div>
                    </div>    

                   </div> 
                </div>    
            ))} */}

            {datas?.datas?.map((b,i)=>(
              <div className='mb-5 md:mb-0' data-aos="fade-up" onClick={()=>openDetail(b)}>
                  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='h-[170px] w-[100%]' />
                  <h6 className='font_bold text-[13px] 2xl:text-[16px] mt-3'>{b?.title}</h6>
                  <h6 className='truncate font_normal text-gray-500 text-[12px] 2xl:text-[15px] mt-1'>{b?.motive}</h6>
                  <div className='flex items-start mt-1 border-t pt-1.5 border-gray-100'>
                  {(b?.written_by?.profile_photo !== null && b?.written_by?.profile_photo !== undefined) ?
                  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.written_by?.profile_photo}`} className='w-[30px] h-[30px] object-contain bg-slate-100 rounded-full' />
                  :
                  <h6 className='w-[24px] h-[24px] flex items-center justify-center text-[11px] font_bold text-white rounded-full' style={{background:b?.color === undefined ? '#fafafa' : b?.color}}>{b?.written_by?.name?.slice(0,1)}</h6>
                  }
                  <div className='ml-2 -mt-1'>
                  {(b?.written_by !== null && b?.written_by !== undefined) && <h6 className='font_bold  text-[#4190ac] text-[12px] mt-1'>{b?.written_by?.name}</h6>}
                  <div className='flex items-center -mt-1'>
                      <h6 className='font_normal   text-[10px] 2xl:text-[13px] mt-1'>{moment(b?.date)?.format('LL')}</h6>
                      <h6 className=' ml-2 font_bold  text-[10px] 2xl:text-[13px] mt-1'>{b?.min_read}</h6>
                  </div>
                  </div>    

                  </div> 
              </div>    
            ))}

         </div>

         {(datas?.pagination?.total > datas?.pagination?.limit) &&
          <div className='mx-auto flex items-center justify-center my-[5%] w-full'>
          <Pagination size='small' onChange={(v)=>setpage(v)} total={datas?.pagination?.total} pageSize={datas?.pagination?.limit} />
          </div>}


         
    </div>
  )
}

export default Blogs