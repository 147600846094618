import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE-3.png'
import banner2 from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE.jpg'
import banner3 from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_IMAGE-2.jpg'


import entry_lobby from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_ENTRANCE-LOBBY.png'
import visitor_lounge from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_VISITIORS.png'
import office_floor_lift from '../../folder/images/exclusive/ajmera_nucleus//AJMERA-NUCLEUS_OFFICE-FLOOR-LIFT.png'
import master_plan from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_MASTER-PLAN.png'
import typical_floor from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_TYPICAL-FLOOR.png'
import first_floor from '../../folder/images/exclusive/ajmera_nucleus/AJMERA-NUCLEUS_FIRST-FLOOR.png'


import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player/youtube'
import GoBack from '../../folder/components/GoBack'

function AjmeraNucleus() {

  const bannerArr = [banner1,banner2,banner3]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold 2xl:text-[15px] text-[#f6821e]'>AJMERA NUCLEUS</h6>
        </div>
        <h6 className='font_bold text-[15px] 2xl:text-[28px] text-center md:text-[25px] mt-2 uppercase'>COMMERCIAL SPACE FOR RENT, AT ELECTRONIC CITY PHASE II</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

      


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>1,30,000 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ground + 8 Floors</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>14,148 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Development area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Possession</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>

        <div className='grid grid-cols-2 py-10 md:grid-cols-6  w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>ENTRANCE LOBBY VIEW</h6>
            <img src={entry_lobby} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>VISITORS LOUNGE</h6>
            <img src={visitor_lounge} className='w-[100%] h-[82%] object-cover' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>OFFICE FLOOR LIFT LOBBY</h6>
            <img src={office_floor_lift} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>MASTER PLAN OF OFFICE CAMPUS</h6>
            <img src={master_plan} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>TYPICAL FLOOR PLAN – 14,148 SQFT</h6>
            <img src={typical_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>FIRST FLOOR PLAN – 12,280 SQFT</h6>
            <img src={first_floor} />
        </div>
        </div>


      


        <div className='w-[80%] md:flex items-start  mx-auto '>
            {/* <img src={location_1} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d497905.90155500756!2d77.67997!3d12.85039!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDUxJzAxLjQiTiA3N8KwNDAnNDcuOSJF!5e0!3m2!1sen!2sus!4v1720677747024!5m2!1sen!2sus"  className='w-full h-full'    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Just 1km(s) from the upcoming Phase II Metro station (Currently Under Construction).</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Just 700 mtrs away from the Hosurroad flyover Toll Booth.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Just 6 minutes away from the HeliTaxiistation.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Just 7 minutes away from the Infosys E-city Phase I Campus.</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>Just 18 minutes away from the Silk board junction.</h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: DEEPA K</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845048969 / deepa.k@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default AjmeraNucleus