
import {useState} from 'react'
import {AiOutlineMail} from 'react-icons/ai'
import {IoMdCall} from 'react-icons/io'
import {IoLocationOutline} from 'react-icons/io5'

import { address, base_url, department, email, mobile } from '../folder/constants/Appconstants'
import ErrorComponent from '../folder/constants/ErrorComponent'
import axios from 'axios'
import {message} from 'antd'
import { useNavigate } from 'react-router-dom'


function Contact() {

  const navigate = useNavigate()

  const [data,setdata] = useState({name:'',mobile:'',email:'',requirment:'',department_website:''})
  const [error,seterror] = useState({name:'',mobile:'',email:'',requirment:'',department_website:''})

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  } 

  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.requirment){
      seterror({...error,requirment:'This Field is required *'})
    }else{
     
      const sendData = {...data}
      sendData['department_website'] = department
      
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          message.success('Form Submitted Successfully')
          setdata({name:'',mobile:'',email:'',requirment:'',department_website:''})
          seterror({name:'',mobile:'',email:'',requirment:'',department_website:''})
        }
      }).catch((err)=>{      
      }) 
    }
  }

  return (
    <div className="w-full h-full overflow-x-hidden">


      {/* <div className='h-[250px] w-screen overflow-hidden relative md:h-[400px] grid place-items-center  bg-contact_bg bg-no-repeat bg-cover bg-right-top  '>
        <div className="">
          <div className="absolute left-5 md:left-[5%] top-5 md:top-24 w-[60%]  md:w-[40%]  border-l-4 border-l-[#4190ac] pl-3">
            <Typist stdTypingDelay={15} avgTypingDelay={15} >
            <h1 className="text-[21px]  font-[700] font_bold  z-100 !important text-[#e58c3d] ">Contact Us</h1>
            <h6 className="text-[14px] w-[70%] md:text-[15px] text-[#fff] font_normal ">We&apos;re fastest-growing HR Solutions Provider. We provide inovative people solutions while keeping the business requirement in mind. </h6>
            </Typist>
          </div> 
        </div> 
      </div> */}

      <div className='relative flex items-center justify-center bg-contact_bg bg-cover  bg-center h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] 2xl:text-[33px] text-white text-center tracking-[7px] font_thicker opacity-80 uppercase'>Contact US</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[17px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Contact Us </h6>
              <h6 className='text-[13px] 2xl:text-[16px] mt-1  text-white text-center font_normal opacity-80'>Navigating Real Estate with Confidence – Get in Touch!.</h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>

        <div className='bg-no-repeat bg-right-top'>
        <div className='w-[90%] md:w-[80vw] mx-auto py-10'>
        <h1 data-aos="fade-left" className='text-center font_bold text-[14px] 2xl:text-[17px] mb-2'>Looking for Best Real Estate Transaction Experts?</h1>
        <h6 data-aos="fade-left" className='text-center font_normal text-slate-700 text-[15px] 2xl:text-[18px]'>Contact us and you&apos;ll receive expert advice in under 24 hours</h6>


        <div className='mt-20 md:flex'>

            
            <div data-aos="fade-right" className='w-full md:w-[50%] mb-20 md:mb-0'>


          

            <div className='mt-2 md:w-[100%]'>
                  <h6 className='font_bold text-[17px] 2xl:text-[20px] mb-10 md:mb-0'>Enquiry <span className='text-[#4190ac]'>Form</span></h6>
                  <h6 className='font_normal text-[15px] 2xl:text-[18px] mb-10 md:mb-0'>Please fill the below form so we can get to know you well.</h6>

                  <div className='md:w-[80%] items-center'>
                  <div className='mr-5 md:mt-2 w-full'>
                    <h6 className='font_bold mb-1 text-[12px] 2xl:text-[15px]'>Name</h6>
                    <input type='text' name="name" value={data?.name} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 2xl:p-2 text-[13px]' />
                  </div>

                  <ErrorComponent error={error?.name} />

                  <div className='mr-5 md:mt-2 w-full'>
                    <h6 className='font_bold mb-1 text-[12px]  2xl:text-[15px]'>Mobile</h6>
                    <input type='text' name="mobile" value={data?.mobile} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 2xl:p-2 text-[13px]' />
                  </div>

                  <ErrorComponent error={error?.mobile} />

                
                  <div className='mt-2 md:mt-2 mr-5 w-full'>
                    <h6 className='font_bold mb-1 text-[12px]  2xl:text-[15px]'>Email</h6>
                    <input type='text' name="email" value={data?.email} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 2xl:p-2 text-[13px]' />
                  </div>
                  <ErrorComponent error={error?.email} />


                  <div className='mt-2 md:mt-2 mr-5 w-full'>
                    <h6 className='font_bold mb-1 text-[12px]  2xl:text-[15px]'>Requirment</h6>
                    <textarea type='text' name="requirment" value={data?.requirment} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 2xl:p-2 text-[13px]' />
                  </div>
                  <ErrorComponent error={error?.requirment} />
                
                  <h6 className='font_normal mb-1 text-[11px]  2xl:text-[14px] mt-2'>By submitting the form I agree to receive communication from Fidelitus Transaction. I understand that I can opt out at any time.</h6>

                  <button onClick={submitForm} className='text-[12px]  2xl:text-[15px] font_normal flex mt-2 items-end justify-end bg-black text-white p-2'>
                    SUBMIT
                  </button>
                  </div>



              </div>

            </div>
            <div data-aos="fade-left" className='w-full md:w-[50%]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.201402509898!2d77.5725512!3d12.9205498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14293cbbedf9%3A0x4f12794ce5a209a4!2sFidelitus%20Corp!5e0!3m2!1sen!2sin!4v1691664501989!5m2!1sen!2sin" className='w-full' height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>


        <div>

           
           <div data-aos="fade-left" className='flex-row-reverse md:flex-row md:flex border-t border-gray-100  mt-10'>
            
            <div>
            <h6 className='pt-8 text-[16px]  2xl:text-[19px] text-center font_bold w-[100%] md:w-[100%] '>To know better about us, Please Visit us at <span className='text-[#4190ac]'>Fidelitus  </span> </h6>  
            <h6 className='text-[12px]  2xl:text-[15px] text-center font_normal font-[600] text-slate-500 mt-0 py-4'>HEAD OFFICE</h6>
           
            <div className='md:flex w-[90%] mx-auto justify-between'>
                  <div className='flex md:w-[33%] border-b md:border-b-0 md:border-r p-4 border-slate-200 items-center justify-center mt-4'>
                  <IoLocationOutline color='#4190ac' size={40} />
                  <h6 className='text-[13px] 2xl:text-[16px] font_normal cursor-pointer pl-3'>{address}</h6>
                </div>

                <div className='flex md:w-[33%] border-b md:border-b-0 p-4 border-slate-200 items-center justify-center mt-4'>
                  <IoMdCall color='#4190ac' size={15} />
                  <h6 className='text-[13px] 2xl:text-[16px] font_normal  cursor-pointer  pl-2'>{mobile}</h6>
                  </div>

                  <div className='flex md:w-[33%] md:border-l p-4 items-center justify-center mt-4'>
                  <AiOutlineMail color='#4190ac' size={15}/>
                  <h6 className='ml-1 text-[13px] 2xl:text-[16px] font_normal  cursor-pointer  pl-2'>{email}</h6>
                  </div>
              </div>
            </div>

             
           </div>

        </div>


       









        <div>

        </div>
        </div>
        </div>

        {/* <h1 className='text-center text-[26px]  mt-20'>View Our  <span className='text-[#4190ac] font_medium'>Other</span>  Branches?</h1> */}


      {/* <div className='grid grid-cols-3 px-10 place-self-center place-content-center md:flex items-center  justify-center mt-10 mb-20'>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2 border-l-2 md:border-l-0'>BANGALORE</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2'>HYDERABAD</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2'>CHENNAI</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2 border-l-2 md:border-l-0'>MUMBAI</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2'>DELHI</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2'>AHMEDABAD</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2 border-l-2 md:border-l-0'>KOLKATA</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2'>PUNE</span>
        <span className='px-2 text-[13px] text-center mb-4 md:mb-0 text-slate-600 font_medium cursor-pointer border-r-2 md:border-none'>KOCHI</span>
        
      </div> */}


      
    </div>
  )
}

export default Contact