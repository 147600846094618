import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/kamadhenu/banner1.jpg'
import banner2 from '../../folder/images/exclusive/kamadhenu/banner2.jpg'
import banner3 from '../../folder/images/exclusive/kamadhenu/banner3.jpg'
import first_floor from '../../folder/images/exclusive/kamadhenu/1st-Floor.jpg'
import second_floor from '../../folder/images/exclusive/kamadhenu/2nd-Floor.jpg'
import third_floor from '../../folder/images/exclusive/kamadhenu/3rd-Floor.jpg'
import basement from '../../folder/images/exclusive/kamadhenu/Basement.jpg'
import ground from '../../folder/images/exclusive/kamadhenu/Ground.jpg'
import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'
import sectional_plan from '../../folder/images/exclusive/kamadhenu/Sectional-Plan.jpg'
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack'

function Kamadhenu() {

  const bannerArr = [banner1,banner2,banner3]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack />  
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold 2xl:text-[15px] text-[#f6821e]'>KAMADHENU</h6>
        </div>
        <h6 className='font_bold text-[15px] 2xl:text-[28px] text-center md:text-[25px] mt-2'>COMMERCIAL OFFICE SPACE FOR RENT At ADUGODI, BANGALORE</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


        <h6 className='font_bold text-[15px] 2xl:text-[18px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-6 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>44,600 Sq.Ft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Basement + Ground + 3 floors + Terrace</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>8,500 Sq.Ft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Power and backup</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>1KVA for every 100 Sqft & 100% Backup</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Development area</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Possession</h6>
                <h6 className='font_normal text-[12px] 2xl:text-[15px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>

        <h6 className='text-center font_bold text-[18px] 2xl:text-[21px] text-[#4190ac] my-10'>Floor Plans</h6>
        
        <div className='grid grid-cols-2 pb-10 md:grid-cols-6 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>BASEMENT FLOOR PLAN</h6>
            <img src={basement} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>GROUND FLOOR PLAN</h6>
            <img src={ground} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>1st FLOOR PLAN</h6>
            <img src={first_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>2nd FLOOR PLAN</h6>
            <img src={second_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>3rd FLOOR PLAN</h6>
            <img src={third_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] 2xl:text-[16px] font_bold p-[4px]'>SECTIONAL PLAN</h6>
            <img src={sectional_plan} />
        </div>
        </div>


        <div className='w-[80%] md:flex items-start  mx-auto '>
            <a target='_blank' href="https://www.google.com/maps/place/12%C2%B056'40.5%22N+77%C2%B036'10.5%22E/@12.9445833,77.6029167,746m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d12.9445833!4d77.6029167?entry=ttu" >
            <img src={location_1} />
            </a>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold 2xl:text-[18px] text-[#4190ac] mb-2'>NEARBY CATCHMENTS</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>HOSUR ROAD – 1.3 KM</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>MG ROAD – 4.5 KM</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>KEMPEGOWDA AIRPORT – 38.4 KM</h6>
                 <h6 className='font_normal text-[12.5px] 2xl:text-[15.5px] mb-[5px]'>DAIRY CIRCLE UPCOMING METRO – 1 KM</h6>

                 <div>
                    <h6 className='text-[11.5px] 2xl:text-[14.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: DEEPA K</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845048969 / deepa.k@fidelituscorp.com </h6>
                    </div>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] 2xl:text-[16px] font_bold'>Name: Chethan M S</h6>
                        <h6 className='font_normal text-[12px] 2xl:text-[15px]'>+91 9845071631 / chethan.ms@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>

  )
}

export default Kamadhenu