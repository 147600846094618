import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate,useParams } from 'react-router-dom'
import DOMPurify from "dompurify";
import { IoIosArrowRoundBack } from "react-icons/io";
import moment from 'moment';
import { base_url, blogsArr,department,supportUs } from '../folder/constants/Appconstants';
import axios from 'axios';
import {message} from 'antd'
import toast from 'react-hot-toast';
import { GetBlogDetailServices, GetBlogServices } from '../folder/services/BlogServices';

function BlogDetail() {

  const navigate = useNavigate()
 
  const {state} = useLocation()
  const {id} = useParams()


  const [data,setdata] = useState({})
  const [loader,setloader] = useState(true)
  const [similarBlogs,setsimilarBlogs] = useState([])

  const [sendData,setsendData] = useState({})


  useEffect(()=>{
    getdata()
  },[id])


  async function getdata(){

    const response1 = await GetBlogServices(1,'')
    // setdatas(response1?.data)

    let d = response1?.data?.datas

    let newItems = []
    for (var i = 0; i < 3; i++) {
      var idx = Math.floor(Math.random() * d.length);
      newItems.push(d[idx]);
      d.splice(idx, 1);
    }
    setsimilarBlogs(newItems)


    const data =  await GetBlogDetailServices(id)
    setloader(false)

    if(data?.data?.datas?.length > 0){
      setdata(data?.data?.datas[0])
    }else{
      toast.error("We couldn't find the blog based on your search")
      setTimeout(() => {
         navigate('/')
      }, 2000);
    }
  }

  async function openDetail(v){
    navigate(`/blogs/detail/${v?._id}`)
  }

  async function submitForm(){
    
    if(sendData?.email !== ''){ 
      const sendDataItem = {...sendData}
      sendData['department_website'] = department
      await axios.post(`${base_url}clients/create`,sendDataItem)
      .then((res)=>{
        if(res?.status === 201){
          message.success('Blog Subscribed Successfully')
          setsendData({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
        }
      }).catch((err)=>{      
      }) 
    }
  }

  return (
    <div>
      <div className='flex bg-[#def9ff] px-[5%] py-[14px]'>
        <div className='flex items-center cursor-pointer' onClick={()=>navigate(-1)}>
        <IoIosArrowRoundBack color='#027d9b' />
        <h6 className='text-[11px] 2xl:text-[14px] ml-2 text-[#027d9b] font_bold'>Back to blogs</h6>
        </div>
      </div>

      {!loader &&
      <>
      <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
      <div className='flex items-center justify-center my-2'>
          <h6 className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${data?.color}`}}>{data?.tags?.map((t)=>t?.name)}</h6>
          <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${data?.color}`}}></h6>
          <h6 className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${data?.color}`}}>{data?.min_read}</h6>
      </div>

      <h6 className='font_bold text-[24px] text-center md:text-[32px] 2xl:text-[35px] mt-2'>{data?.title}</h6>

      <div className='flex items-center justify-center '>
          <div className='flex items-center my-2'>
          {(data?.written_by?.profile_photo !== null && data?.written_by?.profile_photo !== undefined) ?
            <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.written_by?.profile_photo}`} className='w-[30px] h-[30px] object-contain bg-slate-100 rounded-full' />
            :
            <h6 className='w-[24px] h-[24px] flex items-center justify-center text-[11px] 2xl:text-[14px] font_bold text-white rounded-full' style={{background:data?.color === undefined ? '#fafafa' : data?.color}}>{data?.written_by?.name?.slice(0,1)}</h6>
            }
          <h6 className='font_bold text-[11px] 2xl:text-[14px] ml-2'>{data?.written_by?.name}</h6>
          </div>
          <h6 className='font_bold ml-4 text-gray-400 text-[11px] 2xl:text-[14px]'>{moment(data?.date)?.format('LL')}</h6>
        </div>

      </div>

      <div className='w-[100%] md:w-[100%] mx-auto'>
      <div className='md:w-[100%] my-[5%] mb-[10%]'>
      <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.blog_image}`} className='w-[100%] h-[240px] md:h-full md:object-contain' />

      <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
        <h6 className='font_normal text-[14px] 2xl:text-[17px] my-2 mb-5'>{data?.motive}</h6>


        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.content)}}>
        </div>
      </div>
      </div>
      </div>

      <div className='border-t w-[80%] mx-auto border-slate-200 mt-2 pt-10'>
      
      {similarBlogs?.length > 0 && <>

      <h6 data-aos="fade-left" className='text-center text-[20px]  font_bold'>Similar BLogs</h6>
      <div className='flex justify-center items-center'>
        <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
        <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] uppercase mx-2'>check out</h6>
        <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
      </div>
      <h6 data-aos="fade-right" className='text-[14px] 2xl:text-[17px] w-[90%] mx-auto font_normal text-center'>We've got the related blogs based on your selection </h6>


      <div className='grid  w-[100%] md:w-[80%] mx-auto grid-cols-1 md:grid-cols-3 gap-5 mt-5 md:mt-14'>
      {similarBlogs?.map((b)=>(
      <div className='mb-5 md:mb-0' data-aos="fade-up" onClick={()=>openDetail(b)}>
      <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='h-[170px] w-[100%]' />
      <h6 className='font_bold text-[13px] 2xl:text-[16px] mt-3'>{b?.title}</h6>
      <h6 className='truncate font_normal text-gray-500 text-[12px] 2xl:text-[15px] mt-1'>{b?.motive}</h6>
      <div className='flex items-start mt-1 border-t pt-1.5 border-gray-100'>
        {(b?.written_by?.profile_photo !== null && b?.written_by?.profile_photo !== undefined) ?
        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.written_by?.profile_photo}`} className='w-[30px] h-[30px] object-contain bg-slate-100 rounded-full' />
        :
        <h6 className='w-[24px] h-[24px] flex items-center justify-center text-[11px] font_bold text-white rounded-full' style={{background:b?.color === undefined ? '#fafafa' : b?.color}}>{b?.written_by?.name?.slice(0,1)}</h6>
        }
        <div className='ml-2 -mt-1'>
        {(b?.written_by !== null && b?.written_by !== undefined) && <h6 className='font_bold  text-[#4190ac] text-[12px] mt-1'>{b?.written_by?.name}</h6>}
        <div className='flex items-center -mt-1'>
            <h6 className='font_normal  text-[10px] 2xl:text-[13px] mt-1'>{moment(b?.date)?.format('LL')}</h6>
            <h6 className=' ml-2 font_bold  text-[10px] 2xl:text-[13px] mt-1'>{b?.min_read}</h6>
        </div>
        </div>    

      </div> 
    </div>   
      ))}
      </div>
      </>}


      <div className='bg-[#def9ff] md:flex items-center px-5 md:px-20 py-4 my-[5%] rounded-[10px]'>

      <div className='md:w-[60%]'>
      <h6 className='text-[#027d9b] w-[70%] font_bold text-[19px] 2xl:text-[22px]'>If you would love to hear about our latest blogs and updates</h6>
      <h6 className='text-gray-400 font_bold text-[13px] 2xl:text-[16px]'>Get blogs and our latest updates by just subscribe</h6>

      <div className='mt-2'>
        <input  type="email" value={sendData?.email} onChange={(e)=>setsendData({...sendData,email:e.target.value})} className='rounded focus:outline-none pl-4 text-[14px] p-[5px] placeholder:text-[12px]'  placeholder='Enter your mail id' />
        <button onClick={submitForm} className='ml-2 bg-[#027d9b] text-white rounded text-[13px] 2xl:text-[16px] px-3 py-[5px]'>submit</button>
      </div>
      </div> 
      <div className='mt-10 md:mt-0 w-[40%]'>
      <img src={supportUs} className='animate-bounce w-[100%] h-[200px] object-contain'  />
      </div>
      </div>

      </div>
       </>}




    </div>
  )
}

export default BlogDetail